import axios, {AxiosInstance} from 'axios'
import browserStorage, {BrowserStorageKeys} from './CoomeetChat/BrowserStorage.class'
import config from './../../config.json'
import {ref} from 'vue'
import ErrorLoggerInstance from './ErrorLogger.class'

export interface ApplicationConfig {
  version: string,
  build: string,
  hash: string,
  timestamp: string | number,
  updatesList: string[],
  appVersion: string,
  development: boolean,
  debugWebRTC: boolean,
  api : {
    url: string,
    v: string
  },
  webApps: {
    male: string,
    female: string
  },
  errorLogger: {
    trackjs: {
      token: string,
      application: string
    }
  }
}

class Application {
  private static _instance: Application

  private _axios: AxiosInstance
  private _appConfig!: ApplicationConfig

  public android = false
  public ios = false

  public readonly videoDevicesReceived = ref(false)

  private _devicesUpdatedOnGetUserMedia = false
  private _videoDevicesList: MediaDeviceInfo[] = []
  private _audioDevicesList: MediaDeviceInfo[] = []
  public supportedMediaTrackConstraints: MediaTrackSupportedConstraints = {}

  private _referralUserId: number | null = null
  private _partnerId: string | null = null
  private _partnerChannel: string | null = null
  private _restore: string | null = null
  private _hash: string | null = null

  private _debugTurn = false

  // private _disallowedImageExtensions = ['webp']
  private _allowedImageFormats = [
    {
      mime: 'image/jpeg',
      extensions: ['jpg', 'jpeg', 'jfif']
    },
    {
      mime: 'image/png',
      extensions: ['png', 'apng']
    },
    {
      mime: 'image/bmp',
      extensions: ['bmp']
    },
    {
      mime: 'image/gif',
      extensions: ['gif']
    }
  ]

  public get debugTurn() {
    return this._debugTurn && this._appConfig.development
  }

  public get videoDevices () {
    return this._videoDevicesList
  }

  public get audioDevices () {
    return this._audioDevicesList
  }

  public get hasVideoDevices() {
    return this._videoDevicesList.length > 0
  }

  public get fullscreenApiSupported() {
    const doc = window.document
    const docEl = doc.documentElement

    //@ts-ignore
    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen

    return requestFullScreen
  }

  public get imagesMimeTypes() : string[] {
    return this._allowedImageFormats.map((item) => item.mime)
  }

  public get referralUserId() : number | null {
    return this._referralUserId
  }

  public get partnerId() : string | null {
    return this._partnerId
  }

  public get partnerChannel() : string | null {
    return this._partnerChannel
  }

  public get restore() : string | null {
    return this._restore
  }

  public get mobile() : boolean {
    return this.android || this.ios
  }

  public get config() : ApplicationConfig {
    return this._appConfig
  }

  public get hash() : string {
    if (this._hash) return this._hash

    const newVersionHash = browserStorage.getItem(BrowserStorageKeys.UserHash)??''
    if (newVersionHash) return newVersionHash

    return ''
  }

  public set hash(value: string) {
    browserStorage.setItem(BrowserStorageKeys.UserHash, value)
  }

  public clearHash() : void {
    browserStorage.setItem(BrowserStorageKeys.UserHash, '')
  }

  public static GetInstance() : Application {
    if (!this._instance) this._instance = new Application()
    return this._instance
  }

  constructor() {
    this._axios = axios.create({
      baseURL: '/'
    })

    this._updateReferralUserId()

    this._updateDevicesList()
    navigator.mediaDevices.addEventListener('devicechange', this._updateDevicesList)
  }

  private _updateEnvironment() {
    const host = window.location.host

    if (this.config.development && host.indexOf('syscoo.com') >= 0) {
      const subdomain = host.split('.')[0]
      if (subdomain.indexOf('-') >= 0) {
        const alias = subdomain.split('-')[1]
        this.config.webApps.male = 'https://man-' + alias + '.syscoo.com'
        this.config.webApps.female = 'https://woman-' + alias + '.syscoo.com'
      }
    }
  }

  public init() {
    this._appConfig = config

    this._updateEnvironment()

    console.log(`Coomeet application.`)
    console.log(`version: ${this._appConfig.version}(${this._appConfig.build})`)
    console.log(`hash: ${this._appConfig.hash}`)
  }

  public updateDevicesOnGetUserMedia() {
    if (!this._devicesUpdatedOnGetUserMedia) {
      this._devicesUpdatedOnGetUserMedia = true
      this._updateDevicesList()
    }
  }

  private _updateDevicesList = () : void => {
    navigator.mediaDevices.enumerateDevices().then((devicesList) => {
      this._videoDevicesList = devicesList.filter((deviceItem) => deviceItem.kind === 'videoinput')
      this._audioDevicesList = devicesList.filter((deviceItem) => deviceItem.kind === 'audioinput')

      this._devicesUpdatedOnGetUserMedia = this._videoDevicesList.filter((item) => item.deviceId === '').length === 0

      if (!this._devicesUpdatedOnGetUserMedia && this._videoDevicesList.length === 0) {
        ErrorLoggerInstance.error('No video devices')
      }

      if (!this.videoDevicesReceived.value) {
        this.videoDevicesReceived.value = true
      }
    })

    this.supportedMediaTrackConstraints = navigator.mediaDevices.getSupportedConstraints()
  }

  private _updateReferralUserId() : void {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search)
      const id = params.get('id')
      const channel = params.get('p')
      const restore = params.get('restore')
      const hash = params.get('hash')
      const debugTurn = !!params.get('debug_turn')

      this._partnerId = id
      this._partnerChannel = channel
      this._restore = restore
      this._hash = hash
      this._debugTurn = debugTurn
    }

    if (!this._referralUserId && window.location.pathname.indexOf('/id') >= 0) {
      const strId =  window.location.pathname.replaceAll('/id', '')
      this._referralUserId = parseInt(strId)
    }
  }

  public isImageFileDisallowed(filename: string) : boolean {
    const splitted = filename.split('.')
    const ext = splitted[splitted.length - 1]

    const foundedInAllowedList = this._allowedImageFormats
      .filter((data) => data.extensions.indexOf(ext) >= 0)
      .length > 0

    return !foundedInAllowedList
  }

  // public async getConfig() : Promise<ApplicationConfig> {
  //   const response = await this._axios.get('application/config.json', {
  //     headers: {
  //       'Cache-Control': 'no-cache',
  //       'Pragma': 'no-cache',
  //       'Expires': '0',
  //     }
  //   })
  //
  //   this._appConfig = response.data as ApplicationConfig
  //   console.log(`Coomeet application.`)
  //   console.log(`version: ${this._appConfig.version}(${this._appConfig.build})`)
  //   console.log(`hash: ${this._appConfig.hash}`)
  //
  //   return this._appConfig
  // }

  public setReferralUserId(id: number) : void {
    this._referralUserId = id
  }

  public toggleFullScreen() : void {
    const doc = window.document
    const docEl = doc.documentElement

    //@ts-ignore
    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
    //@ts-ignore
    const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

    //@ts-ignore
    if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  }

  public replaceLocation(url: string) {
    const link = document.createElement('a')
    link.href = url
    link.click()
  }
}

export default Application.GetInstance()


