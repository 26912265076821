import { TrackJS } from 'trackjs'
import Application from '../Application.class'
import UserModel from '../CoomeetChat/models/UserModel.class'

export default class TrackJsWrapper {
  private _trackJS: typeof TrackJS

  public constructor() {
    const installed= TrackJS.install({
      token: Application.config.errorLogger.trackjs.token,
      application: Application.config.errorLogger.trackjs.application,
      version: `${Application.config.version}(${Application.config.build})`,
      onError: (payload, error) : boolean => {
        const disabled = payload.entry === 'promise' &&
          payload.message === '{"command":null,"response":null}'
        return !disabled
      },
      forwardingDomain: 'trck.coomeet.com'
    })

    this._trackJS = TrackJS
    this._trackJS.addMetadata('development_mode', Application.config.development ? 'true' : 'false')
    this._trackJS.addMetadata('hash', Application.config.hash)
  }

  public setUser(user: UserModel) : void {
    this._trackJS.configure({
      userId: user.id.toString(),
      sessionId: user.hash
    })

    this._trackJS.addMetadata('user_name', user.username)
  }

  public log(...args: any[]) : void {
    this._trackJS.console.log(...args)
  }

  public debug(...args: any[]) : void {
    this._trackJS.console.debug(...args)
  }

  public info(...args: any[]) : void {
    this._trackJS.console.info(...args)
  }

  public warn(...args: any[]) : void {
    this._trackJS.console.warn(...args)
  }

  public error(...args: any[]) : void {
    this._trackJS.console.error(...args)
  }
}
