//import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

class Fingerprint {
  private _config = {
    token: 'xWueI8UWMg0D77jQaFRv',
    storageKey: 'coomeet-fpi',
    endpoint: 'https://fp.coomeet.com',
    region: 'eu',
    timeout: 20000,
  }

  public async getToken() : Promise<string> {
    const config = this._config

    try {
      // @ts-expect-error
      return await import(/* webpackIgnore: true */ 'https://iframe.coomeet.com/v3/xWueI8UWMg0D77jQaFRv')
        .catch(() => {
          throw new Error('Failed to load the JS script of the agent')
        })
        .then((FingerprintJS) => FingerprintJS.load({
          token: config.token,
          storageKey: config.storageKey,
          endpoint: [config.endpoint, FingerprintJS.defaultEndpoint],
          region: (config.region as 'eu' | 'us' | undefined),
        }))
        .then(async (agent) => (await agent.get({ timeout: config.timeout })).visitorId)
        .catch((err) => {
          throw err
        })
    } catch (error) {
      throw error
    }
  }
}


const FingerPrintInstance = new Fingerprint()

export default FingerPrintInstance
