<template lang="pug">
.accept-invite
  .accept-invite-form(v-if="!hasPromoTime")
    .accept-invite-form__date {{lastMessageTime}}
    .accept-invite-form__avatar
      UiUserAvatar(:model="dialog",
        :size="192",
        :showStory="showStory",
        :showPhotoByClick="true")
      .gift-image(v-if="dialog.lastMessage.gift")
        img(:src="dialog.lastMessage.gift.image")
    template(v-if="!hasPromoTime")
      .accept-invite-form__username {{dialog.username}}
      .accept-invite-form__label {{t('SYS_MSG_6_IN')}}
      .accept-invite-form__actions
        UiSimpleButton.color-green.size-46(@click="onAcceptInviteClick") {{t('A_ACCEPT')}}
        UiSimpleButton.color-white-2.size-46(@click="onCancelInviteClick") {{t('A_REJECT')}}
  PromoForm(v-else)

  .accept-invite__top-icon(@click="onComplainClick")
    LampIcon
</template>
<script lang="ts" setup>
import UiUserAvatar from '../../../../../components/ui/UiUserAvatar.vue'
import UiSimpleButton from '../../../../../components/ui/Buttons/UiSimpleButton.vue'
import LampIcon from '../../../../../assets/images/icons/lamp-size-28.svg'
import PromoForm from './AcceptInvite/PromoForm.vue'
import CoomeetChatInstance, {VideoDialogState} from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import DialogModel from '../../../../../common/classes/CoomeetChat/models/DialogModel.class'
import {computed} from 'vue'
import moment from 'moment'
import {usePopupOverlayStore, Popups} from '../../../../../stores/popupOverlay'
import {useRouter} from 'vue-router'
import {useI18n} from 'vue-i18n'

const router = useRouter()

const {t, d} = useI18n()
const popupsStore = usePopupOverlayStore()
const dialog = CoomeetChatInstance.currentDialog
const user = CoomeetChatInstance.user
const showStory = computed(() => CoomeetChatInstance.videoDialogState.value === VideoDialogState.Empty)

const lastMessageTime = computed(() => {
  if (dialog.value && dialog.value!.lastMessage) {
    const nowDate = d(new Date(), 'date-with-weekday')
    const dialogDate = d(new Date(dialog.value!.lastMessage.created * 1000), 'date-with-weekday')
    const nowYear = new Date().getFullYear()
    const dialogYear = new Date(dialog.value!.lastMessage.created * 1000).getFullYear()

    if (nowDate === dialogDate) {
      return t('S_TODAY')
    } else if (nowYear === dialogYear) {
      return d(new Date(dialog.value!.lastMessage.created * 1000), 'date-with-weekday-without-year')
    } else {
      return d(new Date(dialog.value!.lastMessage.created * 1000), 'date-with-weekday')
    }
  }
  return ''
})

const hasPromoTime = computed(() => {
  if (user.value.hasPremiumOffers || !user.value.access) {
    return (dialog.value?.willDeleted?? 0) > 0
  }
  return false
})

const onAcceptInviteClick = () => {
  if (user.value.isGuest) {
    popupsStore.showPopup(Popups.LoginRegistration, 'registration')
  } else if (user.value.access === 0) {
    popupsStore.showPopup(Popups.BuyPremium)
  } else {
    CoomeetChatInstance.inviteAccept(dialog.value as DialogModel)?.then(({response}) => {
      if (response) popupsStore.showPopup(Popups.EditUser, false)
    })
  }
}

const onCancelInviteClick = () => {
  if (user.value.isGuest) {
    popupsStore.showPopup(Popups.LoginRegistration, 'registration')
  } else if (user.value.access === 0) {
    popupsStore.showPopup(Popups.BuyPremium)
  } else {
    CoomeetChatInstance.inviteDecline(dialog.value as DialogModel)

    router.replace('/dialog').then(() => {
      CoomeetChatInstance.setCurrentDialog(null)
    })
  }
}

const onComplainClick = () => {
  popupsStore.showPopup(Popups.Complain, dialog.value)
}
</script>
