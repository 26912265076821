<template lang="pug">
.system-message(:class="color")
  .system-message__content
    .system-message__content--text
      span.icon(v-if="iconClass", :class="iconClass")
      span.text {{messageText}}
    .system-message__content--time {{messageTime}}
</template>
<script lang="ts" setup>
import {computed} from 'vue'
import type {DialogModelMessage} from '../../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {DialogModelStatus} from '../../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {useI18n} from 'vue-i18n'
import CoomeetChatInstance from '../../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import moment from 'moment'

const props = defineProps<{
  message: DialogModelMessage
}>()

const {t, d} = useI18n()
const dialog = CoomeetChatInstance.currentDialog

const iconClass = computed(() => {
  switch (true) {
    case props.message.msg === 'LOGGER_CALL':
      return 'call'
      break
    case props.message.msg === 'SYS_MSG_2':
      return 'deleted'
      break
  }

  return ''
})

const color = computed(() => {
  switch (true) {
    case props.message.msg === 'LOGGER_CALL':
    case props.message.msg === 'SYS_MSG_8':
    case props.message.msg === 'SYS_MSG_2':
      return 'color-transparent'
      break
  }

  return null
})
const messageTime = computed(() => {
  // return moment(props.message.creationTime).format('HH:mm')
  return d(new Date(props.message.creationTime), 'time')
})

const messageText = computed(() => {
  let text = ''
  const message = props.message

  switch (message.msg as string) {
    case 'SYS_MSG_21':
      text = t('SYS_MSG_21', {points: message.ball})
      break

    default:
      text = message.viewText ? t(message.viewText) : ''
      break
  }

  return text
})
</script>
