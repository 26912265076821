<template lang="pug">
.send-invite
  .send-invite-form
    .send-invite-form__avatar
      UiUserAvatar(
        :model="dialog",
        :size="192",
        :gender="isSelf ? 'male': 'female'",
        :showStory="showStory",
        :showPhotoByClick="true")

    .send-invite-form__username {{dialog.username}}

    .send-invite-form__self(v-if="isSelf") {{t('S_ITS_YOU')}}

    .send-invite-form__deleted-account(v-else-if="dialog.accountDeleted") {{t('APP_ACCOUNT_DELETED')}}

    .send-invite-form__actions
      template(v-if="dialog.accountDeleted")
        UiSimpleButton.color-white.size-46(@click="onCancelInviteClick") {{t('APP_HIDE')}}

      template(v-else)
        UiSimpleButton.color-blue.size-46(@click="onAcceptInviteClick") {{t('A_WRITE')}}
        UiSimpleButton.color-white-2.size-46(@click="onCancelInviteClick") {{t('A_DELETE')}}

  .send-invite__top-icon(@click="onComplainClick" v-if="!isSelf")
    LampIcon
</template>

<script lang="ts" setup>
import UiUserAvatar from '../../../../../components/ui/UiUserAvatar.vue'
import UiSimpleButton from '../../../../../components/ui/Buttons/UiSimpleButton.vue'
import LampIcon from '../../../../../assets/images/icons/lamp-size-28.svg'
import CoomeetChatInstance, {VideoDialogState} from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import DialogModel from '../../../../../common/classes/CoomeetChat/models/DialogModel.class'
import {computed} from 'vue'
import moment from 'moment'
import {useRouter} from 'vue-router'
import {Popups, usePopupOverlayStore} from '../../../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const router = useRouter()
const popupsStore = usePopupOverlayStore()
const showStory = computed(() => CoomeetChatInstance.videoDialogState.value === VideoDialogState.Empty)

const dialog = computed(() => {
  if (CoomeetChatInstance.currentDialog.value?.id === CoomeetChatInstance.user.value.id) {
    return CoomeetChatInstance.user.value
  }

  return CoomeetChatInstance.currentDialog.value
})

const isSelf = computed(() => {
  return CoomeetChatInstance.user.value.id === dialog.value?.id
})

const onAcceptInviteClick = () => {
  if (!isSelf.value) {
    if (CoomeetChatInstance.user.value.access === 0) {
      popupsStore.showPopup(Popups.BuyPremium)
    } else {
      CoomeetChatInstance.invite(dialog.value as DialogModel)
    }
  }
}

const onCancelInviteClick = () => {
  if (isSelf.value) {
    CoomeetChatInstance.removeDialogFromAllLists(dialog.value as DialogModel)
    CoomeetChatInstance.dialogsList.removeModel(dialog.value as DialogModel)
    router.replace('/dialog')
  } else if (dialog.value) {
    const currentDialog = dialog.value! as DialogModel
    router.replace('/dialog').then(() => {
      if (currentDialog) CoomeetChatInstance.removeDialogFromAllLists(currentDialog)
    })
  }
}

const onComplainClick = () => {
  popupsStore.showPopup(Popups.Complain, dialog.value)
}
</script>
