import iframeMessenger, {IFrameCommand} from '../IframeMessenger.class'

export const BrowserStorageKeys = {
  CoomeetLanguage:            'coomeet-locale',
  VideoDeviceId:              'coomeet-video-device-id',
  AudioDeviceId:              'coomeet-audio-device-id',
  MicrophoneMuted:            'coomeet-microphone-muted',
  FaceMode:                   'coomeet-face-mode',
  UserHash:                   'coomeet-user-hash',
  VideoSoundEnabled:          'coomeet-video-sound-enabled',
  CoomeetAppCacheMessages:    'coomeet-app-cache-m',
  CoomeetAppCacheDialogs:     'coomeet-app-cache-d',
  CoomeetAppCacheOptions:     'coomeet-app-cache-o'
}

const storageEmulatorMap = new Map<string, string | null>()

class BrowserStorage {
  constructor() {

  }

  public setItem(key: string, value: string, updateParentWindowStorage = true) : void {
    !iframeMessenger.active ? localStorage.setItem(key, value) : storageEmulatorMap.set(key, value)

    if (updateParentWindowStorage) {
      iframeMessenger.sendMessage({
        cmd: IFrameCommand.StorageData,
        data: {
          key: key,
          value: value
        }
      })
    }
  }

  public getItem(key: string) : string | null {
    return !iframeMessenger.active ? localStorage.getItem(key) : storageEmulatorMap.get(key)??null
  }

  getStorageKeys () : string [] {
    return Object.keys(BrowserStorageKeys).map((key) => (BrowserStorageKeys as {[i: string]: string})[key])
  }
}

const browserStorage = new BrowserStorage()

export default browserStorage
