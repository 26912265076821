import Application from '../classes/Application.class'
import {detect} from 'detect-browser'

const browserInfo = detect()

let frameRate : any = { min: 23, ideal: 25, max: 30 }
// let frameRate : any = { min: 15, ideal: 25, max: 30 }

if (browserInfo?.os && browserInfo.name && browserInfo!.name!.toLowerCase().indexOf('firefox') >= 0) {
  frameRate = 25
}

export const getWebRtcConstrainsSync = ({faceCamera, echoCancellation, audioDeviceId, videoDeviceId} : {
  faceCamera: boolean,
  echoCancellation?: boolean,
  audioDeviceId: string,
  videoDeviceId: string
}) => {
  const videoDevicesList = Application.videoDevices
  const audioDevicesList = Application.audioDevices

  if (videoDevicesList.filter((deviceItem) => deviceItem.deviceId === videoDeviceId).length === 0) {
    videoDeviceId = videoDevicesList[0].deviceId
  }

  if (audioDevicesList.filter((deviceItem) => deviceItem.deviceId === audioDeviceId).length === 0) {
    audioDeviceId = 'default'
  }

  const supportedConstraints = Application.supportedMediaTrackConstraints

  return  {
    video: Application.mobile ? {
      width: supportedConstraints.width ? { min: 320, ideal: 640, max: 800 } : undefined,
      height: supportedConstraints.height ? { min: 240, ideal: 480, max: 600 } : undefined,
      frameRate: supportedConstraints.frameRate ? frameRate : undefined,
      noiseSuppression: supportedConstraints.noiseSuppression ? true : undefined,
      facingMode: supportedConstraints.facingMode ? faceCamera ? 'user' : 'environment' : undefined
    } : {
      deviceId: {exact: videoDeviceId},
      width: supportedConstraints.width ? { min: 320, ideal: 640, max: 800 } : undefined,
      height: supportedConstraints.height ? { min: 240, ideal: 480, max: 600 } : undefined,
      noiseSuppression: supportedConstraints.noiseSuppression ? true : undefined,
      frameRate: supportedConstraints.frameRate ? frameRate : undefined,
    },
    audio: {
      deviceId: audioDeviceId,
      echoCancellation: echoCancellation && supportedConstraints.echoCancellation ? undefined : true
    }
  }
}
