<template lang="pug">
.sticker-message(:class="{inbox: !props.message.inbox}")
  .sticker-message__content
    .image
      img(:src="props.message.sticker?.image")
    .info
      .info__sending(v-if="props.message.scopeData && !props.message.markedForResend")
      template(v-else)
        .info__time(:class="{resend: props.message.markedForResend}") {{messageTime}}
        .info__status(v-if="!props.message.scopeData", :class="{read: props.message.read}")
        .info__status(v-if="props.message.markedForResend").bad
    .corner
      CornerIcon
</template>
<script lang="ts" setup>
import CornerIcon from '@/assets/images/icons/corner-left.svg'
import type {DialogModelMessage} from '../../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {computed, ref} from 'vue'
import moment from 'moment'
import {useI18n} from 'vue-i18n'

const props = defineProps<{
  message: DialogModelMessage
}>()

const {d} = useI18n()

const messageTime = computed(() => {
  //return moment(props.message.creationTime).format('HH:mm')
  return d(new Date(props.message.creationTime), 'time')
})
</script>
