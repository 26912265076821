<template lang="pug">
.gift-message(:class="{inbox: !props.message.inbox}")
  .gift-message__content
    .gift-message__content--image
      img(:src="props.message.gift?.image")
    .gift-info
      .gift-info__title {{t(props.message.gift?.title)}}
      .gift-info__cost {{t('C_GIFT_PRICE')}} {{t('APP_PL_MINUTE_', {n: costMinutes})}}
    .info
      .info__sending(v-if="props.message.scopeData")
      template(v-else)
        .info__time(:class="{resend: props.message.markedForResend}") {{messageTime}}
        .info__status(:class="{read: props.message.read}")
    .corner
      CornerIcon
</template>
<script lang="ts" setup>
import CornerIcon from '@/assets/images/icons/corner-left.svg'
import type {DialogModelMessage} from '../../../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {computed, ref} from 'vue'
import moment from 'moment'
import {useI18n} from 'vue-i18n'

const {t, d} = useI18n()

const props = defineProps<{
  message: DialogModelMessage
}>()

const messageTime = computed(() => {
  // return moment(props.message.created * 1000).format('HH:mm')
  return d(new Date(props.message.creationTime), 'time')
})
const costMinutes = ref<number>(Math.floor(props.message.gift!.minutes / 60))
</script>
