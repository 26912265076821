import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import Application from './common/classes/Application.class'
import TooltipDirective from './directives/tooltip'
import OnlyNumberDirective from './directives/only-number'
import LocalizationInstance from './common/classes/Localization.class'

import './assets/scss/main.scss'
import iframeMessenger from './common/classes/IframeMessenger.class'
import ErrorLoggerInstance from './common/classes/ErrorLogger.class'

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (search, replace) {
    //@ts-ignore
    return this.replace(new RegExp(search, 'g'), replace)
  }
}

Application.init()
ErrorLoggerInstance.init()

createApp(App)
  .use(LocalizationInstance.i18n)
  .use(createPinia())
  .use(router)
  .directive('tooltip', TooltipDirective)
  .directive('only-number', OnlyNumberDirective)
  .mount('#app')
