<template lang="pug">
.messages-list
  MessagesListHeader(v-if="currentDialog && (currentDialog.isFriend || currentDialog.accountDeleted)")

  .chat-identifier(v-if="moderator")
    span id:&nbsp;
    |{{currentDialog.id}}

  UiScroll(ref="uiScroll",
    v-if="(!messagesListReloading && !inviteFormVisible && supportMenuAvailable)",
    :nailToBottom="true",
    @scroll:move="onScrollMove",
    @scroll:reached-top="onScrollReachedTop",
    @scroll:reached-bottom="onScrollReachedBottom")
    .scroll-content
      Messages(ref="messages", :style="{opacity: currentDialog?.messagesList?.length??0 > 0}")
      BannedActions(v-if="!currentDialog?.accountDeleted", :deleted="wasDeleted", :blocked="userBlocked")
      SupportMenu(v-if="currentDialog?.isSupport", @message:sent="onMessageSent")

  .ui-scroll(v-else)

  .messages-list__hidden-ui
    .messages-list__hidden-ui--scroll-to-bottom(
      v-if="messagesLoaded && !scrollOnBottomPosition",
      :class="{visible: ((currentDialog?.newMessage??0) > 0 || (scrollToBottomButtonVisible && !scrollingToBottom)) && (firstMessagesReceived && !markAsReadRequestInProcess)}",
      @click="onScrollToDownClick")
      .messages-list__hidden-ui--scroll-to-bottom__button
        ScrollDownArrow
      .messages-list__hidden-ui--scroll-to-bottom__unread-messages-count(v-show="(currentDialog?.newMessage??0) > 0") {{currentDialog?.newMessage}}

  MessageForm(v-if="messagesListFormVisible", @message:sent="onMessageSent")

  InviteForm(v-if="inviteFormVisible")

  SupportAlert(v-if="supportAlertVisible", :dialog="currentDialog")
</template>

<script lang="ts" setup>
import type {UiScrollInterface} from '../../ui/Scroll/UiScroll.vue'
import type {DialogModelMessage} from '../../../common/classes/CoomeetChat/models/DialogModel/interfaces'

import {computed, nextTick, onBeforeUnmount, onMounted, ref, shallowRef, watchEffect} from 'vue'
import CoomeetChatInstance, {ConnectionState} from '../../../common/classes/CoomeetChat/CoomeetChat.class'
import {DialogModelDelete, DialogModelStatus} from '../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {usePopupOverlayStore} from '../../../stores/popupOverlay'
import ScopeMessengerCommands from '../../../common/classes/CoomeetChat/WebSocketApi/scopes/ScopeMessenger/commands'
import {UserModelRole} from "../../../common/classes/CoomeetChat/models/UserModel/interfaces";

import DialogModel from '../../../common/classes/CoomeetChat/models/DialogModel.class'

import ScrollDownArrow from '@/assets/images/icons/arrow-scroll-down-size-24.svg'
import MessagesListHeader from './MessagesList/MessagesListHeader.vue'
import Messages from './MessagesList/Messages.vue'
import MessageForm from './MessagesList/MessageForm.vue'
import InviteForm from './MessagesList/InviteForm.vue'
import BannedActions from './MessagesList/BannedActions.vue'
import SupportMenu from './MessagesList/SupportMenu.vue'
import SupportAlert from './MessagesList/SupportAlert.vue'
import UiScroll from '../../ui/Scroll/UiScroll.vue'
import WindowEvent from "../../../common/events/window";

const popupStore = usePopupOverlayStore()
const currentDialog = CoomeetChatInstance.currentDialog
const messagesListReloading = ref(false)

const uiScroll = ref<UiScrollInterface>()
const messages = ref<{ $el: HTMLElement, getVisibleUnreadMessages: () => DialogModelMessage[] } | null>(null)
const lastDialog = shallowRef<DialogModel | null>(null)
const user = CoomeetChatInstance.user
const scrollOnBottomPosition = ref(true)
const scrollingToBottom = ref(false)
const blockScrollToBottom = ref(false)
const scrollToBottomButtonVisible = ref(false)
const markAsReadRequestInProcess = ref(false)
const windowHasFocus = ref(document.hasFocus())

let lastMessagesCount = currentDialog.value?.messagesList.length??0
let firstMessagesReceived = ref(false)

const moderator = computed(() => CoomeetChatInstance.user.value.role === UserModelRole.Moderator)

const supportMenuAvailable = computed(() => {
  if (!currentDialog.value?.isSupport) return true
  return !!currentDialog.value?.botStatus
})

const messagesLoaded = computed(() => {
  return (currentDialog.value?.messagesListOffset.skip??0) > 0
})

const supportAlertVisible = computed(() => {
  currentDialog.value?.supportSystemInfoMessage && !currentDialog.value?.supportSystemInfoMessageHidden
})

const userBlocked = computed(() => {
  return currentDialog.value ? currentDialog.value!.delete === DialogModelDelete.IamBlocked : false
})

const wasDeleted = computed(() => {
  return (
    currentDialog.value &&
    currentDialog.value.delete === DialogModelDelete.MeDeleted &&
    currentDialog.value.status !== DialogModelStatus.IamSendInvite
  )
})

const messagesListFormVisible = computed(() => {
  return (
    !userBlocked.value &&
    !wasDeleted.value &&
    currentDialog.value &&
    currentDialog.value!.status! > 0 &&
    !currentDialog.value?.accountDeleted
  )
})

const inviteFormVisible = computed(() => {
  return (
    currentDialog.value &&
    !wasDeleted.value &&
    (
      (
        currentDialog.value!.status! <= 0 ||
        currentDialog.value!.notInContacts
      ) ||
      currentDialog.value.id === CoomeetChatInstance.user.value.id
    )
  ) || hasPromoTime.value
})

const hasPromoTime = computed(() => (
  currentDialog.value?.willDeleted??0) > 0 &&
  !user.value.access &&
  currentDialog.value?.status === DialogModelStatus.SendForMeInvite
)

const onScrollToDownClick = () => {
  if (uiScroll.value) {
    scrollingToBottom.value = true
    uiScroll.value!.animateToBottom()
  }
}

const onMessageSent = () => {
  if (uiScroll.value) {
    scrollingToBottom.value = true
    nextTick(() => {
      uiScroll.value!.animateToBottom()
    })
  }
}

const markVisibleMessagesAsReaded = () => {
  if (windowHasFocus.value && messages.value && currentDialog.value?.initialDataReceived) {
    const unreadMessages = messages.value!.getVisibleUnreadMessages()
    unreadMessages.forEach((messageItem) => messageItem.read = 1)

    if (unreadMessages.length > 0) {
      CoomeetChatInstance.markDialogAsRead(currentDialog.value!)
    }
  }
}

const onScrollMove = ({positionPercents, animationEvent, scrollPosition} :
                      {positionPercents: number, animationEvent: boolean, scrollPosition: number}) => {
  if (messages.value && currentDialog.value?.initialDataReceived) {
    markVisibleMessagesAsReaded()
    scrollToBottomButtonVisible.value = scrollPosition > 800
  }

  scrollOnBottomPosition.value = positionPercents === 1
  scrollingToBottom.value = animationEvent
}

const onScrollReachedTop = () => {
  if (currentDialog.value && !currentDialog.value?.messagesLoading && !currentDialog.value?.allMessagesLoaded) {
    blockScrollToBottom.value = true
    CoomeetChatInstance.getDialogMessages(currentDialog.value!)
  }
}

const onScrollReachedBottom = () => {
  scrollOnBottomPosition.value = true
  scrollingToBottom.value = false
}

const markDialogAsRead = () => {
  if (!currentDialog.value?.promo) {
    if ((currentDialog.value?.newMessage ?? 0) > 0 && !hasPromoTime && !markAsReadRequestInProcess.value) {
      markAsReadRequestInProcess.value = true

      CoomeetChatInstance.markDialogAsRead(currentDialog.value!)?.finally(() => {
        markAsReadRequestInProcess.value = false
      })
    }
  }
}

const onlineMessageSignalSubscriber = CoomeetChatInstance.subscribeSocketSignal({
  signal: ScopeMessengerCommands.OnlineMessage,
  callback: ((scopeData) => {
    if (currentDialog.value?.id === scopeData?.data.id && scrollOnBottomPosition.value) {
      if (uiScroll.value) {
        scrollingToBottom.value = true
        nextTick(() => {
           uiScroll.value!.animateToBottom()
        })
      }
    }
  })
})

const sendCompletedSignalSubscriber = CoomeetChatInstance.subscribeSocketSignal({
  signal: ScopeMessengerCommands.SendCompleted,
  callback: ((scopeData) => {
    if (currentDialog.value?.id === scopeData?.data.id) {
      if (uiScroll.value) {
        scrollingToBottom.value = true
        nextTick(() => {
          uiScroll.value!.animateToBottom()
        })
      }
    }
  })
})

const onWindowFocusChange = () => {
  windowHasFocus.value = document.hasFocus()
  markVisibleMessagesAsReaded()
}

onMounted(() => {
  window.addEventListener('focus', onWindowFocusChange)
  window.addEventListener('blur', onWindowFocusChange)
  window.addEventListener(WindowEvent.ClickOnDialogItemFromLeftMenu, onScrollToDownClick)
})

onBeforeUnmount(() => {
  if (onlineMessageSignalSubscriber) CoomeetChatInstance.unsubscribeSocketSignal(onlineMessageSignalSubscriber)
  if (sendCompletedSignalSubscriber) CoomeetChatInstance.unsubscribeSocketSignal(sendCompletedSignalSubscriber)

  window.removeEventListener('focus', onWindowFocusChange)
  window.removeEventListener('blur', onWindowFocusChange)
  window.removeEventListener(WindowEvent.ClickOnDialogItemFromLeftMenu, onScrollToDownClick)
})

watchEffect(() => {
  if (lastDialog.value?.id !== currentDialog.value?.id) {
    if (!messagesListReloading.value) {
      messagesListReloading.value = true
      scrollOnBottomPosition.value = true
      scrollingToBottom.value = false
      scrollToBottomButtonVisible.value = false

      setTimeout(() => {
        messagesListReloading.value = false
      }, 10)
    }
  }

  if (currentDialog.value && lastDialog.value?.id !== currentDialog.value?.id) {
    lastDialog.value = currentDialog.value
    firstMessagesReceived.value = false
    scrollToBottomButtonVisible.value = false
    lastMessagesCount = 0

    if (currentDialog.value!.isSupport) {
      CoomeetChatInstance.supportAlert(currentDialog.value!)
    }
  }

  if (lastMessagesCount !== currentDialog.value?.messagesList.length??0) {
    lastMessagesCount = currentDialog.value?.messagesList.length??0

    if (lastMessagesCount > 1 &&
        (scrollOnBottomPosition.value || !currentDialog.value?.lastMessage?.inbox) &&
        firstMessagesReceived.value) {
      if (!blockScrollToBottom.value) {
        if (uiScroll.value){
          scrollingToBottom.value = true
          uiScroll.value!.animateToBottom()
        }
      }
      blockScrollToBottom.value = false
    }

    if (lastMessagesCount > 0) {
      nextTick(() => {
        firstMessagesReceived.value = true
      })
    }
  }
})

watchEffect(() => {
  if (currentDialog.value && currentDialog.value!.messagesList.length === 0) {
    CoomeetChatInstance.getDialogMessages(currentDialog.value)
  }
})

watchEffect(() => {
  if (
    currentDialog.value &&
    !currentDialog.value!.initialDataReceived &&
    !currentDialog.value!.messagesLoading &&
    CoomeetChatInstance.connectionState.value === ConnectionState.Connected
  ) {
    currentDialog.value!.resetInitialState()
    currentDialog.value!.initialDataReceived = true
    CoomeetChatInstance.getDialogMessages(currentDialog.value!)
  }
})
</script>
