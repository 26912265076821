<template lang="pug">
.profile-settings(:class="{visible: settingsOpened}")
  .profile-settings__bg(@click="onBgClick")
  .profile-settings__menu
    .profile-settings__menu--head
      .toggle-visibility-button(@click="onToggleSettingsVisibilityClick")
        .toggle-visibility-button__bg
        .toggle-visibility-button__icon
          ArrowDown(v-if="settingsOpened")
          SettingsIcon(v-else)
    .profile-settings__menu--content
      .actions-menu
        .actions-list
          .action-item(@click="onTogglePrivacyFormClick", :class="{opened: privacyFormOpened && settingsOpened}")
            .action-item__icon
              CheckListIcon
            .action-item__label {{t('C_PROFILE_PERSONAL')}}
            .action-item__arrow
              SelectArrow

          .action-item.invoice.gray(v-if="privacyFormOpened && settingsOpened", @click="onPaymentHistoryClick")
            .action-item__icon
              InvoiceIcon
            .action-item__label {{t('C_PROFILE_INVOICE')}}
        DotsMenu(v-if="privacyFormOpened")

      .privacy-data-form(:class="{opened: privacyFormOpened && settingsOpened}")
        UiIconInput(
          v-model="email",
          type="email",
          :class="{'change-email': currentUser.changeEmail}",
          :placeholder="t('H_EMAIL')",
          :maxlength="254",
          @keydown="onKeyDown",
          @input="onEmailInput")
          template(v-slot:icon)
            ShieldIcon(v-tooltip:top="t('C_AUTH_EMAIL_VERIFY')", v-if="user.needConfirmEmail")
          template(v-slot:error, v-if="!emailValid") {{t('ERROR_67')}}
          template(v-slot:error, v-else-if="emailError") {{emailError}}
          template(v-slot:error, v-else-if="currentUser.changeEmail")
            .confirm-email {{t('C_PROFILE_NEW_EMAIL_CONFIRM')}}:&nbsp;
              span.bold {{currentUser.changeEmail}}
              span.link(@click="onCancelEmailConfirmClick") {{t('A_CANCEL')}}
        UiSelect(:placeholder="t('H_AGE')", v-model="selectedAge", :options="ageSelectOptions")

      .actions-menu
        .actions-list
          .action-item(:class="{opened: passwordFormOpened}", @click="onTogglePasswordFormClick")
            .action-item__icon
              FingerPrintIcon
            .action-item__label {{t('C_PROFILE_PASSWORD_CHANGE')}}
            .action-item__arrow
              SelectArrow

      .password-data-form(:class="{opened: passwordFormOpened && settingsOpened}")
        UiIconInput(:placeholder="t('APP_NEW_PASSWORD')",
          :maxlength="50",
          autocomplete="off",
          v-model="password",
          type="password",
          @keydown="onKeyDown"
          @input="onPasswordInput")
          template(v-slot:error, v-if="passwordError") {{passwordError}}
        UiIconInput(:placeholder="t('APP_REPEAT_PASSWORD')",
          autocomplete="off",
          :maxlength="50",
          @keydown="onKeyDown"
          v-model="repassword",
          type="password")
          template(v-slot:error, v-if="passwordError")

      .settings-actions
        template(v-if="settingsActionsVisible")
          UiSimpleButton.size-46.color-blue(@click="onSaveClick") {{t('A_SAVE')}}
          UiSimpleButton.size-46.color-white(@click="onCancelClick") {{t('APP_CANCELLATION')}}
</template>
<script lang="ts" setup>
import ShieldIcon from '@/assets/images/icons/shield-filled-size-20.svg'
import CheckListIcon from '@/assets/images/icons/check-list-size-24.svg'
import SelectArrow from '@/assets/images/icons/select-arrow.svg'
import ArrowDown from '@/assets/images/icons/arrow-down-size-22.svg'
import InvoiceIcon from '@/assets/images/icons/invoice-size-24.svg'
import SettingsIcon from '@/assets/images/icons/settings-size-22.svg'
import FingerPrintIcon from '@/assets/images/icons/fingerprint-size-24.svg'
import UiIconInput from '../../../../components/ui/Inputs/UiIconInput.vue'
import UiSelect from '../../../../components/ui/Select/UiSelect.vue'
import UiSimpleButton from '../../../../components/ui/Buttons/UiSimpleButton.vue'
import DotsMenu from './ProfileSettings/DotsMenu.vue'
import type {UISelectOption} from '../../../ui/Select/UiSelect.vue'
import {ref, computed, watchEffect, onBeforeUnmount, nextTick} from 'vue'
import {usePopupOverlayStore, Popups} from '../../../../stores/popupOverlay'
import CoomeetChatInstance from '../../../../common/classes/CoomeetChat/CoomeetChat.class'
import Validators from '../../../../common/utils/Validators'
import {useI18n} from 'vue-i18n'
import LocalizationInstance from '../../../../common/classes/Localization.class'
import ScopeUserCommands from "../../../../common/classes/CoomeetChat/WebSocketApi/scopes/ScopeUser/commands";

const {t} = useI18n()
const popupsStore = usePopupOverlayStore()

const settingsOpened = ref(false)

let lastEmail = CoomeetChatInstance.user.value.email
const email = ref(CoomeetChatInstance.user.value.email)
const emailError = ref('')
const emailValid = computed(() => Validators.email(email.value))
const user = CoomeetChatInstance.user
let lastAge = ref(CoomeetChatInstance.user.value.ageRange)

const ageSelectOptions = computed<UISelectOption[]>(() => {
  if (LocalizationInstance.locale) {
    const list = [
      {
        id: 2,
        label: t('APP_FROM_TO', {from: 18, to: 24}),
      },
      {
        id: 3,
        label: t('APP_FROM_TO', {from: 25, to: 34}),
      },
      {
        id: 4,
        label: t('APP_FROM_TO', {from: 34, to: 44}),
      },
      {
        id: 5,
        label: t('APP_OVER', {over: 45})
      }
    ]

    return list
  }

  return []
})

const selectedAge = ref<UISelectOption>(ageSelectOptions.value.filter((item) => {
  return item.id === lastAge.value
})[0])

const password = ref('password')
const repassword = ref('')
const passwordError = ref('')

const privacyFormOpened = ref(false)
const passwordFormOpened = ref(false)

const currentUser = CoomeetChatInstance.user

const settingsActionsVisible = computed(() => {
  if (privacyFormOpened.value) {
    return emailValid.value && lastEmail !== email.value || selectedAge.value.id !== lastAge.value
  } else if (passwordFormOpened.value) {
    return password.value !=='' || repassword.value !==''
  }

  return false
})

const mailConfirmSignalSubscriber = CoomeetChatInstance.subscribeSocketSignal({
  signal: ScopeUserCommands.MailConfirm,
  callback: (scopeData) => {
    nextTick(() => {
      if (!scopeData?.data.needConfirmEmail) {
        email.value = (scopeData?.data.changeEmail??'') === '' ? scopeData?.data.email : scopeData?.data.changeEmail
        currentUser.value.changeEmail = ''
        lastEmail = currentUser.value.email = email.value
      }
    })
  }
})

const onBgClick = () => {
  settingsOpened.value = false
}

const onToggleSettingsVisibilityClick = () => {
  settingsOpened.value = !settingsOpened.value
}

const onPaymentHistoryClick = () => {
  popupsStore.showPopup(Popups.PaymentHistory)
}

const onTogglePrivacyFormClick = () => {
  privacyFormOpened.value = !privacyFormOpened.value
  passwordFormOpened.value = !privacyFormOpened.value
}

const onTogglePasswordFormClick = () => {
  passwordFormOpened.value = !passwordFormOpened.value
  privacyFormOpened.value = !passwordFormOpened.value
}

const onSaveClick = () => {
  if (privacyFormOpened.value) {
    CoomeetChatInstance.changePersonal(email.value, selectedAge.value.id as number)
      ?.then(({response}) => {
        // CoomeetChatInstance.user.value.isConfirmEmail = response?.data.isConfirmEmail
        // CoomeetChatInstance.user.value.needConfirmEmail = response?.data.needConfirmEmail
        // CoomeetChatInstance.user.value.ageRange = response?.data.ageRange
        // CoomeetChatInstance.user.value.email = response?.data.email
        // CoomeetChatInstance.user.value.changeEmail = response?.data.changeEmail

        lastAge.value = CoomeetChatInstance.user.value.ageRange
        email.value = response?.data.email
        if (response?.data.changeEmail) popupsStore.showPopup(Popups.ConfirmEmailByLink)
      }).catch(({response}) => {
        if (response.data.code === 412) {
          emailError.value = t('ERROR_412')
        } else if (response.data.code === 411) {
          emailError.value = t('ERROR_411')
        } else if (response.data.code === 498) {
          emailError.value = t('ERROR_498')
        } else if (response.data.code === 401) {
          emailError.value = t('V_REQUIRED_EMAIL')
        }
      })
  } else {
    if (password.value.length === 0) {
      passwordError.value = t('APP_EMPTY_PASSWORD')
    } else if (repassword.value !== password.value) {
      passwordError.value = t('ERROR_2')
    } else {
      CoomeetChatInstance.changePassword(password.value)?.then(() => {
        password.value = ''
        repassword.value = ''
      }).catch(({response}) => {
        if (response.data.code === 405) {
          passwordError.value = t('ERROR_54')
        }
      })
    }
  }
}

const onCancelClick = () => {
  if (privacyFormOpened.value) {
    selectedAge.value = ageSelectOptions.value.filter((item) => item.id === lastAge.value)[0]
    email.value = lastEmail
    emailError.value = ''
  } else {
    password.value = ''
    repassword.value = ''
    passwordError.value = ''
  }
}

const onCancelEmailConfirmClick = () => {
  CoomeetChatInstance.cancelChangeEmail()
}

const onEmailInput = () => {
  emailError.value = ''
}

const onKeyDown = (e:KeyboardEvent) => {
  if (e.code === 'Enter') {
    onSaveClick();
  }
}

const onPasswordInput = () => {
  passwordError.value = ''
}

watchEffect(() => {
  if (password.value && repassword.value) {
    passwordError.value = password.value !== repassword.value ? t('ERROR_2') : ''
  }
})

watchEffect(() => {
  selectedAge.value = ageSelectOptions.value.filter((item) => {
    return item.id === lastAge.value
  })[0]
})

watchEffect(() => {
  if (passwordFormOpened.value) {
    password.value = ''
    repassword.value = ''
  }
})

onBeforeUnmount(() => {
  if (mailConfirmSignalSubscriber) CoomeetChatInstance.unsubscribeSocketSignal(mailConfirmSignalSubscriber)
})
</script>
