import {Ref, ref} from 'vue'
import ErrorLoggerInstance from '../../ErrorLogger.class'
import Application from '../../Application.class'

export enum DevicePermissionStatus {
  Granted = 'Granted',
  Denied = 'Denied',
  Request = 'Request',
  Idle = 'Idle'
}

export interface DevicesPermissionState {
  camera: DevicePermissionStatus,
  microphone: DevicePermissionStatus
}

export default class MediaStreamRequester {

  private _lastMediaStream: MediaStream | null = null
  private _tracks: MediaStreamTrack[] = []
  private _devicesList: MediaDeviceInfo[] = []

  private _failedDevicesId : string[] = []
  private _constraints: MediaStreamConstraints | null = null

  private _requestInProcess = false

  public permissionsState: Ref<DevicesPermissionState> = ref<DevicesPermissionState>({
    camera: DevicePermissionStatus.Idle,
    microphone: DevicePermissionStatus.Idle,
  })

  constructor() {
    this._listenPermissions()
  }

  private _setDeviceStatus(device: 'camera' | 'microphone', status: DevicePermissionStatus) : void {
    if (device === 'camera' && this.permissionsState.value.camera !== status) {
      this.permissionsState.value.camera = status
    }
    if (device === 'microphone' && this.permissionsState.value.microphone !== status) {
      this.permissionsState.value.microphone = status

      if (status === DevicePermissionStatus.Granted && this._lastMediaStream) {
        if (this._lastMediaStream.getAudioTracks().length === 0) {
          this._getAudioTrack(this._constraints!).then((audioTrack) => {
            this._lastMediaStream?.addTrack(audioTrack)
            this._lastMediaStream?.dispatchEvent(new Event('streamchanged'))
          })
        }
      } else if (status === DevicePermissionStatus.Denied && this._lastMediaStream) {
        if (this._lastMediaStream.getAudioTracks().length > 0) {
          this._lastMediaStream.getAudioTracks().forEach((trackItem) => {
            trackItem.enabled = false
            trackItem.stop()
            this._lastMediaStream?.removeTrack(trackItem)
            this._lastMediaStream?.dispatchEvent(new Event('streamchanged'))
          })
        }
      }
    }
  }

  /**
   * Для браузеров, которые поддерживаю permission.query для камеры и микрофона
   * @private
   */
  private async _listenPermissions() : Promise<void> {
    const updatePermissionsStatus = (device: 'camera' | 'microphone', permissionStatus: PermissionStatus) => {
      switch (permissionStatus.state) {
        case "denied":
          this._setDeviceStatus(device, DevicePermissionStatus.Denied)
          break
        case "prompt":
          this._setDeviceStatus(device, DevicePermissionStatus.Idle)
          break
        case "granted":
          this._setDeviceStatus(device, DevicePermissionStatus.Granted)
          break
      }
    }

    try {
      //@ts-ignore
      const cameraPermissionStatus = await navigator.permissions.query({name: 'camera'})
      //@ts-ignore
      const microphonePermissionStatus = await navigator.permissions.query({name: 'microphone'})

      updatePermissionsStatus('camera', cameraPermissionStatus)
      updatePermissionsStatus('microphone', cameraPermissionStatus)

      cameraPermissionStatus.onchange = (e: Event) => {
        updatePermissionsStatus('camera', (e.target as PermissionStatus))
      }

      microphonePermissionStatus.onchange = (e: Event) => {
        updatePermissionsStatus('microphone', (e.target as PermissionStatus))
      }
    } catch (e) {

    }
  }

  private _onTrackEnded = (e: MediaStreamTrackEvent) : void => {
    const track = (e.target! as MediaStreamTrack)

    if (track.kind === 'video') {
      let videoTrack : MediaStreamTrack | null = null
      if (this._requestInProcess) {
        this._reRequestStream()
      } else {
        this._setDeviceStatus('camera', DevicePermissionStatus.Denied)
      }
      // this._setDeviceStatus('camera', DevicePermissionStatus.Denied)
    }

    if (track.kind === 'audio') {
      // this._setDeviceStatus('microphone', DevicePermissionStatus.Denied)
      if (this._requestInProcess) {
        this._reRequestStream()
      } else {
        this._setDeviceStatus('microphone', DevicePermissionStatus.Denied)
      }
    }

    if (this._lastMediaStream) {
      track.enabled = false
      this._lastMediaStream.removeTrack(track)
    }
  }

  private _reRequestStream() : void {
    let videoTrack : MediaStreamTrack | null = null
    let audioTrack : MediaStreamTrack | null = null

    this._lastMediaStream?.getTracks().forEach((trackItem) => {
      this._lastMediaStream?.removeTrack(trackItem)
      trackItem.stop()
    })

    this._getVideoTrack(this._constraints!).then((track) => {
      videoTrack = track
      this._lastMediaStream?.addTrack(videoTrack)
      this._lastMediaStream?.dispatchEvent(new Event('streamchanged'))
    }).catch(() => {
      this._setDeviceStatus('camera', DevicePermissionStatus.Denied)
    })

    this._getAudioTrack(this._constraints!).then((track) => {
      audioTrack = track
      this._lastMediaStream?.addTrack(audioTrack)
      this._lastMediaStream?.dispatchEvent(new Event('streamchanged'))
    }).catch(() => {
      this._setDeviceStatus('microphone', DevicePermissionStatus.Denied)
    })
  }

  private _updatePermissionsState() : void {
    this._setDeviceStatus('camera', this._lastMediaStream!.getVideoTracks().length > 0 ? DevicePermissionStatus.Granted : DevicePermissionStatus.Denied)
    this._setDeviceStatus('microphone', this._lastMediaStream!.getAudioTracks().length > 0 ? DevicePermissionStatus.Granted : DevicePermissionStatus.Denied)
  }

  private async _getDevicesList() : Promise<MediaDeviceInfo[]> {
    if (this._devicesList.length === 0) {
      this._devicesList = await navigator.mediaDevices.enumerateDevices()
    }

    return this._devicesList
  }

  public clearStream() : void {
    if (this._lastMediaStream) {
      this._lastMediaStream = null

      this._tracks.forEach((track) => {
        //@ts-ignore
        track.removeEventListener('ended', this._onTrackEnded)
      })

      this._tracks = []
    }
  }

  private async _getVideoTrack(constraints: MediaStreamConstraints) : Promise<MediaStreamTrack> {
    const audioDisabledConstraints = JSON.parse(JSON.stringify(constraints))
    audioDisabledConstraints.audio = false

    try {
      const stream = await navigator.mediaDevices.getUserMedia(audioDisabledConstraints)
      const tracks = stream.getVideoTracks()
      return tracks[0]
    } catch (e) {
      console.log('_getVideoTrack error', e)
      const devicesList = await this._getDevicesList()
      let availableDevices: MediaDeviceInfo[] = []

      let faceCamera = false

      if (audioDisabledConstraints.video?.facingMode) {
        faceCamera = audioDisabledConstraints.video?.facingMode === 'user'
        const labelPart = faceCamera ? 'front' : 'back'

        availableDevices = devicesList.filter((deviceInfoItem) => this._failedDevicesId.indexOf(deviceInfoItem.deviceId) < 0 &&
          deviceInfoItem.label.toLowerCase().indexOf(labelPart) >= 0)

        delete audioDisabledConstraints.video.facingMode
      } else {
        const failedDeviceId = audioDisabledConstraints.video.deviceId
        this._failedDevicesId.push(failedDeviceId)
      }

      let mediaStream: MediaStream | null = null

      if (!mediaStream) {
        const newConstraints = JSON.parse(JSON.stringify(audioDisabledConstraints))
        newConstraints.video.width = 800
        newConstraints.video.height = 600

        try {
          mediaStream = await navigator.mediaDevices.getUserMedia(newConstraints)
        } catch (e) {
          console.log('_getVideoTrack with size failed', e)

          if (!mediaStream && availableDevices.length > 0) {
            for (let i = 0; i < availableDevices.length; i++) {
              const deviceConstraints = JSON.parse(JSON.stringify(newConstraints))
              deviceConstraints.video.deviceId = availableDevices[i].deviceId

              try {
                mediaStream = await navigator.mediaDevices.getUserMedia(newConstraints)
                break
              } catch (e) {
                console.log('_getVideoTrack deviceId failed', e)
              }
            }
          }
        }
      }

      if (!mediaStream) {
        try {
          mediaStream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false
          })
        } catch (e) {
          console.log('_getVideoTrack final request error', e)
        }
      }

      if (!mediaStream) {
        throw new Error('No active devices')
      } else {
        return mediaStream.getVideoTracks()[0]
      }
    }
  }

  private async _getAudioTrack(constraints: MediaStreamConstraints) : Promise<MediaStreamTrack> {
    const videoDisabledConstraints = JSON.parse(JSON.stringify(constraints))
    videoDisabledConstraints.video = false

    try {
      const stream = await navigator.mediaDevices.getUserMedia(videoDisabledConstraints)
      const tracks = stream.getAudioTracks()
      return tracks[0]
    } catch (e) {
      console.log('_getAudioTrack', e)
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true
        })

        const tracks = stream.getAudioTracks()
        return tracks[0]
      } catch (e) {
        console.log('_getAudioTrack audio track failed', e)
        throw 'Audio track failed'
      }
    }

  }

  public async requestMediaStream(constraints: MediaStreamConstraints) : Promise<MediaStream> {
    this._requestInProcess = true
    this._constraints = constraints

    if (this.permissionsState.value.camera !== DevicePermissionStatus.Granted) {
      this._setDeviceStatus('camera', DevicePermissionStatus.Request)
    }

    if (this.permissionsState.value.microphone !== DevicePermissionStatus.Granted) {
      this._setDeviceStatus('microphone', DevicePermissionStatus.Request)
    }

    let videoTrack: MediaStreamTrack | null = null
    let audioTrack: MediaStreamTrack | null = null

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      Application.updateDevicesOnGetUserMedia()

      stream.getTracks().forEach((trackItem) => {
        if (trackItem.kind === 'audio') {
          audioTrack = trackItem
        } else {
          videoTrack = trackItem
        }
      })

    } catch (e) {
      console.log('requestMediaStream error', e)
      try {
        videoTrack = await this._getVideoTrack(constraints)
      } catch (e) {
        this._setDeviceStatus('camera', DevicePermissionStatus.Denied)
      }

      try {
        audioTrack = await this._getAudioTrack(constraints)
      } catch (e) {
        this._setDeviceStatus('microphone', DevicePermissionStatus.Denied)
      }
    }

    if (videoTrack) {
      const stream = new MediaStream()

      if (videoTrack) stream.addTrack(videoTrack)
      if (audioTrack) stream.addTrack(audioTrack)

      this._tracks.forEach((track) => {
        //@ts-ignore
        track.removeEventListener('ended', this._onTrackEnded)
      })

      this._lastMediaStream = stream
      this._tracks = stream.getTracks()
      this._tracks.forEach((track) => {
        //@ts-ignore
        track.addEventListener('ended', this._onTrackEnded)
      })

      this._updatePermissionsState()

      this._requestInProcess = false
      return stream
    } else {
      if (audioTrack) audioTrack.stop()
      this._requestInProcess = false
      throw 'Media stream request failed'
    }
  }
}
