<template lang="pug">
.message-input
  .input-field(:class="{focused}")
    textarea(ref="textarea",
      maxlength="1024",
      :placeholder="t('APP_MESSAGE_TEXT')",
      v-model="message",
      @focus="onInputFocus",
      @blur="onInputBlur",
      @keydown="onTextAreaKeydown",
      @input="onTextAreaInput")
    pre.hidden-text-view(ref="hiddenView", v-html="message.trim()")
    StickersAndGifts(@someItemSend="onStickersSomeItemSend", v-if="!currentDialog.isSupport")
  .send-button(:class="{active: message.trim().length > 0}",
    @click="onSendButtonClick")
    .send-button__icon
</template>
<script lang="ts" setup>
import {toRaw, ref, nextTick, watchEffect, onMounted, onUnmounted, computed} from 'vue'
import CoomeetChatInstance from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import StickersAndGifts from './MessageInput/StickersAndGifts.vue'
import isMobile from 'is-mobile'
import {usePopupOverlayStore, Popups} from '../../../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'
import iframeMessenger, {IFrameCommand} from '../../../../../common/classes/IframeMessenger.class'
import WindowEvent from '../../../../../common/events/window'
import DialogModel from '../../../../../common/classes/CoomeetChat/models/DialogModel.class'
import Application from '../../../../../common/classes/Application.class'

const emit = defineEmits(['message:sent', 'input:focus', 'input:blur'])
const {t} = useI18n()

const mobileDevice = navigator.maxTouchPoints > 0
const writeCommandTimeoutMilliseconds = 2000
let writeCommandTimeout: NodeJS.Timeout
const currentDialog = CoomeetChatInstance.currentDialog

const popupsStore = usePopupOverlayStore()
const message = ref('')
const focused = ref(false)

const hiddenView = ref<HTMLElement | null>(null)
const textarea = ref<HTMLTextAreaElement | null>(null)

const lastDialog = ref<DialogModel | null>(CoomeetChatInstance.currentDialog.value)

const formDisabled = computed(() => {
  if (currentDialog.value && currentDialog.value!.isSupport) {
    return !currentDialog.value!.botStatus?.operator
  }
  return false
})

const onStickersSomeItemSend = () => {
  if (!mobileDevice) textarea.value!.focus()
}

let scrollTimeout: NodeJS.Timeout | null = null
const scrollToForm = (alignToTop: boolean = false) => {
  if (scrollTimeout) {
    clearTimeout(scrollTimeout)
    scrollTimeout = null
  }

  scrollTimeout = setTimeout(() => {
    document.querySelector('#application-wrapper')!
        .scrollIntoView({
          behavior: 'auto',
          block: alignToTop ? 'start' : 'end',
          inline: 'nearest'
        })
    window.scrollTo(0,0)
  }, 300)
}

const onInputFocus = () => {
  emit('input:focus')
  focused.value = true

  /**
   * Хак для iOS, который позволяет не скроллить окно при фокусе на инпут
   */
  if (Application.ios) {
    textarea.value!.style.opacity = '0'
    setTimeout(() => {
      textarea.value!.style.opacity = '1'
    }, 100)
  }

  if (iframeMessenger.active) {
    iframeMessenger.sendMessage({
      cmd: IFrameCommand.InputFocused,
      data: null
    })
  }
}

const onInputBlur = () => {
  emit('input:blur')
  focused.value = false

  if (iframeMessenger.active) {
    iframeMessenger.sendMessage({
      cmd: IFrameCommand.InputBlured,
      data: null
    })
  }
}

const onWindowResize = () => {
  updateTextArea()
}

const onVisualViewportResize = () => {
  if (mobileDevice) scrollToForm(!focused.value)
}

const updateTextArea = () => {
  const hiddenEl = hiddenView.value
  const textareaEl = textarea.value

  if (hiddenEl && textareaEl) {
    const textAreaRect = textareaEl.getBoundingClientRect()
    hiddenEl.style.width = `${textAreaRect.width}px`

    const hiddenRect = hiddenEl.getBoundingClientRect()
    const lastSymbolEnter = message.value[message.value.length - 1] === '\n'
    const height = (hiddenRect.height < 16 ? 16 : hiddenRect.height) + (lastSymbolEnter ? 16 : 0)

    textareaEl.style.height = `${height}px`
  }
}

const sendWrite = (writing: boolean) => {
  if (lastDialog.value) {
    CoomeetChatInstance.write(writing, lastDialog.value as DialogModel)
  }
}

const onSendButtonClick = () => {
  const messageValue = message.value.trim()
  if (messageValue != '') {
    if (lastDialog.value) CoomeetChatInstance.sendMessage(message.value.trim(), lastDialog.value as DialogModel)
    emit('message:sent')
    // message.value = ''
  }

  sendWrite(false)

  setTimeout(() => {
    currentDialog.value!.draftMessageText = ''
    message.value = ''
    textarea.value!.value = ''
  }, 10)

}

const onTextAreaInput = (e: Event) => {
  message.value = (textarea.value! as HTMLTextAreaElement).value
  currentDialog.value!.draftMessageText = message.value;
}

const onTextAreaKeydown = (e: KeyboardEvent) => {
  if ((e.code === 'Enter' || e.key === 'Enter') && !e.shiftKey) {
    setTimeout(() => {
      onSendButtonClick()
    }, 5)

    e.preventDefault()
    return false
  }

  const systemKeys = ['Meta', 'Alt', 'Control', 'Shift', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp', 'Tab', 'CapsLock', 'Escape', 'Insert', 'Home', 'End', 'PageUp', 'PageDown', 'NumLock']
  if (systemKeys.findIndex((val)=>val.toUpperCase() === e.key.toUpperCase()) === -1) {
    sendWrite(true)

    if (writeCommandTimeout) clearTimeout(writeCommandTimeout)

    writeCommandTimeout = setTimeout(() => {
      sendWrite(false)
    }, writeCommandTimeoutMilliseconds)
  }

}

const onFocusTextChatInput = () => {
  if (textarea.value && !mobileDevice) textarea.value.focus()
}

watchEffect(() => {
  if (message.value.length >= 0) {
    nextTick(() => {
      updateTextArea()
    })
  }

  if (lastDialog.value?.id !== currentDialog.value?.id && currentDialog.value) {
    message.value = currentDialog.value!.draftMessageText;
    if (!mobileDevice) textarea.value!.focus()
    sendWrite(false)
    lastDialog.value = CoomeetChatInstance.dialogsList.getById(currentDialog.value!.id)
  }
})

watchEffect(() => {
  if (formDisabled.value && textarea.value) textarea.value!.blur()
})


onMounted(() => {
  if (!mobileDevice) textarea.value!.focus()
  message.value = currentDialog.value!.draftMessageText;
  addEventListener('resize', onWindowResize)
  addEventListener(WindowEvent.FocusTextChatInput, onFocusTextChatInput)
  visualViewport?.addEventListener('resize', onVisualViewportResize)
})

onUnmounted(() => {
  if (writeCommandTimeout) clearTimeout(writeCommandTimeout)
  sendWrite(false)
  removeEventListener('resize', onWindowResize)
  removeEventListener(WindowEvent.FocusTextChatInput, onFocusTextChatInput)
  visualViewport?.removeEventListener('resize', onVisualViewportResize)
})

</script>
