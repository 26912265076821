import ModelAbstract from './ModelAbstract.class'
import {UserModelAvatar} from './UserModel/interfaces'
import {
  DialogModelBotStatus,
  DialogModelCallCancelReason,
  DialogModelDelete,
  DialogModelMessage,
  DialogModelMessagesListOffset,
  DialogModelMessageType,
  DialogModelMessageVideo,
  DialogModelModerationStatus,
  DialogModelPromo,
  DialogModelStatus,
  DialogModelSystemInfoMessage,
  FriendStatus,
  SupportData,
  SupportType
} from './DialogModel/interfaces'
import GiftModel from './GiftModel.class'
import StickerModel from './StickerModel.class'
import {WebSocketApiScopeData} from '../WebSocketApi/WebSocketApi.class'
import WebRtcConnection, {TurnServerData} from './DialogModel/WebRtcConnection.class'
import applicationCache from '../../ApplicationCache.class'
import ErrorLoggerInstance from '../../ErrorLogger.class'

export default class DialogModel extends ModelAbstract {
  public static supportDataList: SupportData[] = []

  private _webRtcConnection?: WebRtcConnection
  public get webRtcConnection() {
    return this._webRtcConnection
  }

  public allMessagesLoaded = false
  public messagesLoading = false

  public lastMessageCreationTime = 0

  public abused= false
  public needVote= false

  /** Состояние бота поддержки **/
  public botStatus: DialogModelBotStatus | null = null
  public supportType: SupportType | null = null
  public supportSystemInfoMessage: DialogModelSystemInfoMessage | null = null
  public supportSystemInfoMessageHidden = false
  public get isSupport() : boolean {
    return !!this.supportType
  }

  /** Поля для истории коммуникции **/
  public duration: number = 0
  public last: number = 0
  public hasStory: number = 0

  /** Поля для модерации **/
  public moderation_status: DialogModelModerationStatus = DialogModelModerationStatus.Empty
  public moderatorGift: boolean = false
  public proxy: boolean = false

  public isVideoCall = false

  /** Основные свойства **/
  public accountDeleted: number = 0
  public username: string = ''
  public avatar: UserModelAvatar | null = null
  public online: number = 0
  public newMessage: number = 0
  public delete: DialogModelDelete | null = null
  public status: DialogModelStatus | null = null
  public favorite: number = 0
  public access: number | undefined = 0
  public getPresent: number = 0
  public contactDeleted: number = 0
  public lastMessage: DialogModelMessage | null = null
  public promo: DialogModelPromo | null = null
  public isStoryViewed : number = 0
  public willDeleted: number = 0
  public willDeletedLastValue: number = 0
  public giftBeforeApprove: number = 0

  public checkDate: number | null = null
  public videoChatGift: GiftModel | null = null // Подарок полученный по событию Gift в видеочате
  //public hidden: boolean = false

  // Подарок который не получилось отправить, и можно отправить после пополнения минут
  public notSentGift: GiftModel | null = null

  public videoChatStartTime = 0

  public lastMessageText: string = ''
  public draftMessageText: string = ''
  public messagesListOffset: DialogModelMessagesListOffset = {limit: 30, skip: 0}

  private _messagesList: DialogModelMessage[] = []
  private _cachedMessagesList: DialogModelMessage[] = []
  public firstMessagesReceived = false
  public get messagesList() : DialogModelMessage[] {
    if (this.messagesListOffset.skip === 0 && !this.firstMessagesReceived) {
      if (this._cachedMessagesList.length > 0) return this._cachedMessagesList
      if (this.lastMessage) return [this.lastMessage] as DialogModelMessage[]
      return []
    }
    // if (!this._messagesList) this._messagesList = []
    return this._messagesList
  }

  public get originalMessagesList() : DialogModelMessage[] {
    return this._messagesList ? this._messagesList : []
  }

  public lastSeen: number = 0

  public get sendingMessage () : boolean {
    return this.notDeliveredMessages.filter((messageItem) => !messageItem.markedForResend).length > 0
  }
  public typing = false
  public initialDataReceived = false

  public comment: string = ''

  public notDeliveredMessages: DialogModelMessage[] = []

  /** Поля для звонков **/
  public callCancelled = false
  public callRequested = false
  public callMaster = false
  public callCancelReason: DialogModelCallCancelReason | null = null

  public turnServedData : TurnServerData | null = null
  public rtcRemoteOffer : RTCSessionDescription | null = null
  public iceCandidates : RTCIceCandidate[] = []
  public remoteStream: MediaStream | null = null
  public rtcConnected = false
  public screenInterval = 0

  private _hidden = false

  public get hidden() : boolean {
    return this._hidden || !this.lastMessage
  }

  public set hidden(val) {
    this._hidden = val
  }

  public get hasConnection () : boolean {
    return !!this._webRtcConnection
  }

  public get rtcVideoTracks() : MediaStreamTrack[] {
    return this._webRtcConnection?.tracks??[]
  }

  // @todo удалить геттеры ниже, отсюда и до следующего коммента, в задаче https://trello.com/c/0NUKcllZ
  public get notInContacts() : boolean { return this.access === undefined }
  public get isFriend() : boolean { return this.status === DialogModelStatus.ApprovedInvite && (!this.delete || this.delete === DialogModelDelete.IamBlocked) }
  public get friendRequestSended() : boolean { return this.status === DialogModelStatus.IamSendInvite }
  public get friendRequestReceived() : boolean { return this.status === DialogModelStatus.SendForMeInvite}
  public get friendRequestRejected() : boolean { return this.status === DialogModelStatus.IamSendInvite && !!this.delete }
  // ---

  /**
   * Важно! Статус дружбы определяется на основе status (4 возможных значения) + delete (5 возможных значений).
   * Суммарно это даёт 20 возможных комбинаций, а в приложении может быть только 7 возможных статусов дружбы.
   * Плюс при определении важна последовательность проверок. Плюс статус дружбы зависит от данных клиента (_hidden).
   * Поэтому во избежание боли и страданий нужно использовать этот геттер, и не использовать status и delete отдельно
   */
  public get friendStatus(): FriendStatus {
    if (this._hidden) {
      return 'None'
    } else if (this.delete === DialogModelDelete.MeDeleted) {
      if (this.status === DialogModelStatus.IamSendInvite) {
        return 'RequestDeclined'
      } else {
        return 'DeletedByFriend'
      }
    } else if (this.delete === DialogModelDelete.IamDeleted) {
      return 'None'
    } else if (this.delete === DialogModelDelete.IamBlocked) {
      return 'BlockedFriend'
    } else if (this.status === DialogModelStatus.IamSendInvite) {
      return 'RequestSent'
    } else if (this.status === DialogModelStatus.SendForMeInvite) {
      return 'RequestReceived'
    } else if (
      this.status === DialogModelStatus.ApprovedInvite ||
      this.delete === DialogModelDelete.MeBlocked
    ) {
      return 'ActiveFriend'
    } else if (
      this.status === DialogModelStatus.HasNoInvite &&
      (
        this.delete === DialogModelDelete.NotDeleted ||
        this.delete === DialogModelDelete.NotDefined
      )
    ) {
      return 'None'
    } else {
      // Логируем что получены данные диалога которые мы не ждали
      ErrorLoggerInstance.error(`Unknown friend request status with delete: ${this.delete} and status: ${this.status}`)

      return 'None'
    }
  }

  public get json() : any {
    return {
      ID: this.id,
      duration: this.duration,
      last: this.last,
      hasStory: this.hasStory,
      isStoryViewed: this.isStoryViewed,
      username: this.username,
      avatar: this.avatar,
      online: this.online,
      newMessage: this.newMessage,
      delete: this.delete,
      status: this.status,
      favorite: this.favorite,
      access: this.access,
      getPresent: this.getPresent,
      contactDeleted: this.contactDeleted,
      promo: this.promo,
      willDeleted: this.willDeleted,
      checkDate: this.checkDate,
      giftBeforeApprove: this.giftBeforeApprove,
      lastSeen: this.lastSeen,
      accountDeleted: this.accountDeleted,
      lastMessageCreationTime: this.lastMessageCreationTime,
      lastMessage: this.lastMessage,
      lastMessageText: this.lastMessageText,
      moderation_status: this.moderation_status,
      moderatorGift: this.moderatorGift,
      proxy: this.proxy,
      supportSystemInfoMessage: this.supportSystemInfoMessage,
      botStatus: this.botStatus,
      supportType: this.supportType
    }
  }

  setData(data: any) {
    super.setData(data)

    this._updateSupportRole()

    if (data.duration !== undefined) this.duration = data.duration
    if (data.last !== undefined) this.last = data.last
    if (data.hasStory !== undefined) this.hasStory = data.hasStory??0
    if (data.isStoryViewed !== undefined) this.isStoryViewed = data.isStoryViewed??0

    if (data.username !== undefined) {
      const textarea = document.createElement('textarea')
      textarea.innerHTML = data.username
      this.username = textarea.value
      // this.username = data.username
    }
    if (data.avatar !== undefined) this.avatar = data.avatar
    if (data.online !== undefined) this.online = data.online
    if (data.newMessage !== undefined) this.newMessage = data.newMessage
    if (data.delete !== undefined) this.delete = data.delete
    if (data.status !== undefined) this.status = data.status
    if (data.favorite !== undefined) this.favorite = data.favorite
    if (data.access !== undefined) this.access = data.access
    if (data.getPresent !== undefined) this.getPresent = data.getPresent
    if (data.contactDeleted !== undefined) this.contactDeleted = data.contactDeleted
    if (data.promo !== undefined) this.promo = data.promo
    if (data.willDeleted !== undefined) this.willDeleted = data.willDeleted
    if (data.checkDate !== undefined) this.checkDate = data.checkDate
    if (data.giftBeforeApprove !== undefined) this.giftBeforeApprove = data.giftBeforeApprove
    if (data.lastSeen !== undefined) this.lastSeen = data.lastSeen
    if (data.accountDeleted !== undefined) this.accountDeleted = data.accountDeleted

    if (data.contact) {
      this.delete = data.contact.Delete
      this.status = data.contact.Status
    }

    if (data.orderBy) {
      this.lastMessageCreationTime = data.orderBy
    }

    if (data.lastMessage) {
      this.setLastMessage(data.lastMessage)
      this.addMessage(data.lastMessage)
    }

    if (data.moderation_status) this.moderation_status = data.moderation_status
    this.moderatorGift = !!data.moderatorGift
    this.proxy = !!data.proxy

    if (data.supportSystemInfoMessage !== undefined) this.supportSystemInfoMessage = data.supportSystemInfoMessage
    if (data.botStatus !== undefined) this.botStatus = data.botStatus
    if (data.supportType !== undefined) this.supportType = data.supportType

    this._cachedMessagesList = applicationCache.getDialogMessages(this).map((message) => {
      return {
        viewText: this._getMessageText(message),
        creationTime: message.creationTime ? message.creationTime : Date.now(),
        ...message
      }
    })
  }

  private _getMessageText(message: DialogModelMessage) : string {
    let text = ''
    if (message.type === DialogModelMessageType.Text && (message.msg as string).match(/(.+)#(\d+)/)) {
      message.type = DialogModelMessageType.Gift
    }

    if (message.msg === 'LOGGER_CALL') {
      text = 'LOGGER_CALL_MALE'
      message.type = DialogModelMessageType.System
    } else {
      switch (message.type) {
        case DialogModelMessageType.Text:
          text = message.msg as string
          break

        case DialogModelMessageType.Gift:
          text = 'C_INVOICE_MALE_PRESENT'
          const splitMessageText = (message.msg as string).split('#')
          const giftId = parseInt(splitMessageText[splitMessageText.length - 1])
          const giftModel = GiftModel.CreateGiftById(giftId)
          if (giftModel) message.gift = giftModel
          break

        case DialogModelMessageType.Sticker:
          text = 'APP_STICKER'
          message.sticker = new StickerModel(message.msg as string)
          break

        case DialogModelMessageType.Picture:
          text = 'S_IMAGE'
          break

        case DialogModelMessageType.Video:
          text = 'SYS_MSG_4'
          break

        case DialogModelMessageType.NewFormatVideo:
          text = 'SYS_MSG_4'
          break

        case DialogModelMessageType.System:
          switch (message.msg as string) {
            case 'SYS_MSG_1':
              text = 'SYS_MSG_1'
              break
            case 'SYS_MSG_2':
              if (!message.inbox) {
                text = 'APP_CONTACT_REMOVED'
              } else {
                text = 'SYS_MSG_2'
              }
              break
            case 'SYS_MSG_3':
              text = 'SYS_MSG_3'
              break
            case 'SYS_MSG_4':
              text = 'SYS_MSG_4'
              break
            case 'SYS_MSG_5':
              text = 'SYS_MSG_5'
              break
            case 'SYS_MSG_6':
              if (!message.inbox) {
                text = 'SYS_MSG_6_OUT'
              } else {
                text =  'APP_WANTS_TO_MAKE_FRIENDS'
              }
              break

            case 'SYS_MSG_7':
              text = 'SYS_MSG_7'
              break

            case 'SYS_MSG_8':
              text = 'APP_REJECTED_FEMALE_FRIEND_REQUEST'
              break

            case 'SYS_MSG_10':
              text = 'SYS_MSG_10'
              break

            case 'SYS_MSG_13':
              text = 'SYS_MSG_13'
              break

            case 'SYS_MSG_14':
              text = 'SYS_MSG_14'
              break

            case 'SYS_MSG_15':
              text = 'SYS_MSG_15'
              break

            case 'SYS_MSG_16':
              text = 'SYS_MSG_16'
              break

            case 'SYS_MSG_21':
              text = `${message.ball} покупка Премиум доступа`
              break

            case 'LOGGER_CALL':
              text = 'LOGGER_CALL_MALE'
              break

            case 'TIPS_LOG':
              text = 'TIPS_LOG_IN'
              break

            case 'TIPS_INMESSAGE':
              text = 'C_INVOICE_MALE_GIFT'
              break

            case 'APP_YOU_REMOVED_CONTACT':
              text = 'APP_YOU_REMOVED_CONTACT'
              break
          }
          break
      }
    }
    return text
  }

  private _updateSupportRole() : void {
    const list = DialogModel.supportDataList.filter((item) => item.id === this.id)

    if (list.length > 0) {
      this.supportType = list[0].type
    }
  }

  public addMessage(message: DialogModelMessage, scopeData?: WebSocketApiScopeData) : void {
    if (!this.hasMessage(message)) {

      message.viewText = this._getMessageText(message)
      if (!message.creationTime) message.creationTime = Date.now()

      /**
       * Если сообщение является видео и у него не обработаны файлы (mp4ready === 0, webmready === 0),
       * то заменяем данные бинарными из неоправленного сообщения
       */
      if ([DialogModelMessageType.Video, DialogModelMessageType.NewFormatVideo].indexOf(message.type) >= 0 && scopeData) {
        const notDeliveredList = this.notDeliveredMessages.filter((messageItem) => messageItem.scopeData?.event.Cid === scopeData.event.Cid)

        if (notDeliveredList.length > 0 && Object.keys(scopeData.data.messages).length > 0) {
          const notDeliveredMessage = notDeliveredList[0].msg as DialogModelMessageVideo
          const messageId = Object.keys(scopeData.data.messages)[0]
          const messageFromScope = scopeData.data.messages[messageId].msg as DialogModelMessageVideo

          const msg = message.msg as DialogModelMessageVideo

          if (notDeliveredMessage.mp4 && !messageFromScope.mp4ready) {
            msg.mp4 = notDeliveredMessage.mp4
          }

          if (notDeliveredMessage.mp4 && !messageFromScope.webmready) {
            msg.webm = notDeliveredMessage.webm
          }
        }
      }

      this._messagesList.push(message)
    }

    if (scopeData) {
      this.notDeliveredMessages = this.notDeliveredMessages
        .filter((messageItem) => messageItem.scopeData?.event.Cid !== scopeData.event.Cid)
      this._messagesList = this._messagesList
        .filter((messageItem) => messageItem.scopeData?.event.Cid !== scopeData.event.Cid)
    }

    if (message.creationTime &&
      this.lastMessageCreationTime < message.creationTime &&
      message.msg !== 'SYS_MSG_5') this.lastMessageCreationTime = message.creationTime
  }

  public markMessageForResend(scopeData: WebSocketApiScopeData) : void {
    this.notDeliveredMessages
      .filter((messageItem) => !messageItem.inbox && messageItem.scopeData?.event.Cid === scopeData.event.Cid)
      .forEach((messageItem) => {
        messageItem.markedForResend = true
    })

    this._messagesList
      .filter((messageItem) => !messageItem.inbox && messageItem.scopeData && messageItem.scopeData?.event.Cid === scopeData.event.Cid)
      .forEach((messageItem) => {
        messageItem.markedForResend = true
    })
  }

  public markVideoMessagesForResend() : void {
    this.notDeliveredMessages
      .filter((messageItem) => messageItem.id < 0 && !messageItem.inbox)
      .forEach((messageItem) => messageItem.markedForResend = true)

    this._messagesList
      .filter((messageItem) => messageItem.id < 0 && !messageItem.inbox)
      .forEach((messageItem) => messageItem.markedForResend = true)
  }

  public removeMessageWithScopeData(scopeData: WebSocketApiScopeData) {
    this.notDeliveredMessages = this.notDeliveredMessages
      .filter((messageItem) => messageItem.scopeData?.event.Cid !== scopeData.event.Cid)
    this._messagesList = this._messagesList
      .filter((messageItem) => messageItem.scopeData?.event.Cid !== scopeData.event.Cid)
  }

  public hasMessage(message: DialogModelMessage) : boolean {
    return this._messagesList.filter((messageItem) => {
      return messageItem.id === message.id
    }).length > 0
  }

  public setLastMessage(message: DialogModelMessage) : void {
    if (this.lastMessage && this.lastMessage.creationTime > message.creationTime) {
      return
    }

    this.lastMessage = message

    this.lastMessageText = this._getMessageText(this.lastMessage)
    if (this.lastMessage.type === DialogModelMessageType.Text) {
      this.lastMessageText = this.lastMessage.translate ? this.lastMessage.translate : this.lastMessage.msg as string
    }
  }

  public getMessageById (id: number) : DialogModelMessage | null {
    const list = this._messagesList.filter((messageItem) => messageItem.id === id)
    return list.length > 0 ? list[0] : null
  }

  public updateMessageWithTempId (id: number, newMessage: DialogModelMessage) : DialogModelMessage | null {
    const list = this._messagesList
      .filter((messageItem) =>
        messageItem.tempId === id &&
        messageItem.inbox === newMessage.inbox
      )

    const message = list.length > 0 ? list[0] : null

    //"ball":0,"type":0,"read":1,"inbox":0,"creationTime":1677752719624,"id":16777527196240,"created":1677752719,"msg":"Привет мир!","translate":"Hello world!"

    if (message) {
      message.id = newMessage.id
      message.read = newMessage.read
      message.type = newMessage.type
      message.ball = newMessage.ball
      message.inbox = newMessage.inbox
      message.creationTime = newMessage.creationTime
      message.created = newMessage.created
      message.msg = newMessage.msg
      message.translate = newMessage.translate
      message.viewText = this._getMessageText(message)

      delete message.tempId

      if (this.lastMessage && this.lastMessage.tempId === id) {
        this.setLastMessage(message)
      }
    }

    return message
  }

  public clearMessages() {
    this.messagesListOffset.skip = 0
    this.allMessagesLoaded = false

    this._messagesList = this._messagesList
      .filter((messageItem) => messageItem.markedForResend)

    this.newMessage = 0
  }

  public clearLastMessage() {
    this.lastMessage = null
    this.setLastMessage({
      id: -performance.now(),
      ball: 0,
      created: 1690723191,
      creationTime: 1690723191000,
      inbox: 0,
      msg: 'SYS_MSG_5',
      read: 0,
      type: DialogModelMessageType.System
    } as DialogModelMessage)
  }

  public removeTempMessages() {
    this.notDeliveredMessages = this.notDeliveredMessages.filter((message) => message.id)
    this._messagesList = this._messagesList.filter((message) => message.id)
  }

  resetInitialState() {
    this.initialDataReceived = false
    this.allMessagesLoaded = false
    this.messagesListOffset = {limit: 30, skip: 0}
  }

  public addNotDeliveredMessage(message: DialogModelMessage) : void {
    this.notDeliveredMessages.push(message)
    this.addMessage(message)
  }

  public createWebrtcConnection(localStream: MediaStream,
                                onIceCandidate: (e: RTCIceCandidate) => void,
                                closingStateCallback: (state: RTCPeerConnectionState) => void,
                                connectedCallback: () => void) : WebRtcConnection {
    this._webRtcConnection = new WebRtcConnection({
      dialog: this,
      localStream: localStream,
      server: this.turnServedData!,
      onIceCandidate: onIceCandidate,
      closingStateCallback: closingStateCallback,
      connectedCallback: connectedCallback
    })

    // if (this.id === 16748337502072) {
    //   setTimeout(() => {
    //     console.log('test close connection')
    //     this._webRtcConnection.dispose()
    //   }, 3000)
    // }

    return this._webRtcConnection
  }

  public closeWebrtcConnection() {
    if (this._webRtcConnection) {
      this._webRtcConnection.dispose()
      this._webRtcConnection = undefined

      this.remoteStream = null
      this.rtcConnected = false
      this.rtcRemoteOffer = null
    }
  }

  public setNewMediaStream(mediaStream: MediaStream) : void {
    if (this._webRtcConnection) this._webRtcConnection.setNewMediaStream(mediaStream)
  }

  public async createOffer() : Promise<RTCSessionDescriptionInit | undefined> {
    return this._webRtcConnection?.createOffer()
  }

  public async setRemoteAnswer(description: RTCSessionDescription) : Promise<RTCSessionDescriptionInit | undefined> {
    return this._webRtcConnection?.setRemoteAnswer(description)
  }

  public setRemoteOffer(description : RTCSessionDescriptionInit) : Promise<RTCSessionDescriptionInit> | undefined {
    return this._webRtcConnection?.setRemoteOffer(description)
  }

  public setBotStatus(botStatus: DialogModelBotStatus) : void {
    this.botStatus = botStatus
  }
}
