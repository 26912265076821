import Scope from './Scope.class'
import {CommandPromiseArguments, WebSocketApiScopeData} from '../WebSocketApi.class'
import ScopeCommonCommands from './ScopeCommon/commands'
import DialogModel from '../../models/DialogModel.class'

interface TypingListTimeoutItem {
  dialog: DialogModel,
  timeout?: NodeJS.Timeout
}

export default class ScopeCommon extends Scope {
  private _typingList : TypingListTimeoutItem[] = []

  private _addClearTypingTimeout(dialog: DialogModel) {
    let timeoutItem = this._getTypingTimeout(dialog)

    if (timeoutItem.timeout) {
      clearTimeout(timeoutItem.timeout)
    }

    timeoutItem.timeout = setTimeout(() => {
      dialog.typing = false
      this._removeTypingTimeout(dialog)
    }, 30000)
  }

  private _removeTypingTimeout(dialog: DialogModel) : void {

    this._typingList = this._typingList.filter((item) => {
      if (item.dialog === dialog) {
        clearTimeout(item.timeout)
      }
      return item.dialog !== dialog
    })
  }

  public _getTypingTimeout(dialog: DialogModel) : TypingListTimeoutItem {
    const list = this._typingList.filter((item) => item.dialog === dialog)
    return list.length > 0 ? list[0] : {dialog}
  }

  public processData(scopeData: WebSocketApiScopeData) : void {
    switch (scopeData.event.Cmd) {
      case ScopeCommonCommands.Write: {
        //{"event":{"Cmd":"Write","Cid":""},"data":{"id":16748337502072,"type":0}}
        const dialogId = scopeData.data.id as number
        const dialog = this._coomeetChatInstance.dialogsList.getById(dialogId)

        if (dialog) {
          dialog.typing = !!scopeData.data.type
          if (dialog.typing) {
            this._addClearTypingTimeout(dialog)
          } else {
            this._removeTypingTimeout(dialog)
          }
        }
      }
        break
    }
  }

  public async write(dialog: DialogModel, typing: boolean) : Promise<CommandPromiseArguments> {
    const user = this._coomeetChatInstance.user.value

    if (user && user.typing !== typing) {
      user.typing = typing
      return await this.sendCommand(ScopeCommonCommands.Write, {
        id: dialog.id,
        type: typing ? 1 : 0
      })
    } else {
      return {command: null, response: null}
    }
  }

  public async viewSettings() : Promise<CommandPromiseArguments> {
    const user = this._coomeetChatInstance.user

    if (!user.value.viewSettings) {
      const data = await this.sendCommand(ScopeCommonCommands.ViewSettings, {
        type: 1
      })

      user.value.viewSettings = 1

      return data
    } else {
      return {command: null, response: null}
    }
  }

  public fingerPrint(token: string) : void {
    this.sendCommand(ScopeCommonCommands.FingerPrint, {
      token
    })
  }

  public info() : void {
    if (!this._webSocketApi.connectionInfoWasSent) {
      this.sendCommand(ScopeCommonCommands.Info, {
        ConnectTime: this._webSocketApi.connectionDuration
      })

      this._webSocketApi.connectionInfoWasSent = true
    }
  }
}
