<template lang="pug">
.user-profile-wrapper(v-if="!currentUser.isGuest")
  .user-profile
    .user-profile-head
      ProfileUserName
      .logout-button(@click="onLogoutClick")
        LogoutIcon(v-tooltip:bottom="t('A_EXIT')")

    AvatarForm

    .user-profile-link
      .user-profile-link__label {{t('C_PROFILE_LINK')}}
      .link-actions
        .link-actions__link(@click="onClipboardCopyClick") {{userLink}}
        .link-actions__copy(v-tooltip:top="t('C_PROFILE_LINK_COPY')", @click="onClipboardCopyClick")
          CopyIcon
        .link-actions__view(v-if="!currentUser.hasStrongBan" v-tooltip:top="t('C_PROFILE_SELF')", @click="onLookProfileClick")
          EyeIcon

    ProfileSettings

    .user-profile__link-copied(:class="{visible: linkCopied}")
      .user-profile__link-copied--bg
      .user-profile__link-copied--text {{t('APP_STORY_URL_COPY_SUCCESS')}}
</template>
<script lang="ts" setup>
import LogoutIcon from '@/assets/images/icons/logout-size-24.svg'
import CopyIcon from '@/assets/images/icons/copy-size-18.svg'
import EyeIcon from '@/assets/images/icons/eye-size-18.svg'
import AvatarForm from './UserProfile/AvatarForm.vue'
import ProfileUserName from './UserProfile/ProfileUserName.vue'
import ProfileSettings from './UserProfile/ProfileSettings.vue'
import CoomeetChatInstance from '../../../common/classes/CoomeetChat/CoomeetChat.class'
import {usePopupOverlayStore, Popups} from '../../../stores/popupOverlay'
import {watchEffect, ref, onMounted} from 'vue'
import {useRouter} from 'vue-router'
import {useChatStore} from '../../../stores/chat'
import {useI18n} from 'vue-i18n'
import iframeMessenger, {IFrameCommand} from '../../../common/classes/IframeMessenger.class'
import Application from '../../../common/classes/Application.class'

const {t} = useI18n()
const popupsStore = usePopupOverlayStore()
const currentUser = CoomeetChatInstance.user
const router = useRouter()
const linkCopied = ref(false)

const userLink = ref(`https://coomeet.com/id${currentUser.value.id}`)

const onLogoutClick = () => {
  if (currentUser.value.needConfirmEmail) {
    popupsStore.showPopup(Popups.EmailConfirmAttention)
  } else {
    popupsStore.showPopup(Popups.Logout)
  }
}

const onClipboardCopyClick = () => {
  navigator.clipboard.writeText(userLink.value);
  iframeMessenger.sendMessage({
    cmd: IFrameCommand.ClipboardWrite,
    data: {
      text: userLink.value
    }
  })
  linkCopied.value = true

  setTimeout(() => {
    linkCopied.value = false
  }, 1000)
}

const onLookProfileClick = () => {
  router.push(`/dialog/${currentUser.value.id}`)
}

watchEffect(() => {
  if (currentUser.value.isGuest) {
    Application.referralUserId === currentUser.value.id ? router.push('/') : router.push(`/dialog/${Application.referralUserId}`)
  }
})
</script>
