<template lang="pug">
.ui-icon-input(:class="{error: !!slots.error, focus: focused}")
  input(
    v-if="props.autocomplete === 'off'",
    ref="tempInput",
    type="text",
    :tabindex="-1",
    style="position: fixed; top: -100px; left: 0px")
  input(
    v-if="props.type === 'text' || props.type === 'password' || props.type === 'email'"
    ref="input",
    :readonly="inputReadonly",
    :type="props.type",
    :value="modelValue",
    :maxlength="props.maxlength",
    :autocomplete="props.autocomplete",
    :name="props.name",
    :tabindex="props.tabindex",
    :disabled="props.disabled",
    @input="onInput",
    @focus="onInputFocus",
    @blur="onInputBlur",
    @keydown="onKeyDown",
    enterkeyhint="enter")
  input(
    v-else-if="props.type === 'int'"
    ref="input",
    :readonly="inputReadonly",
    :type="props.type",
    :value="modelValue",
    :maxlength="props.maxlength",
    :autocomplete="props.autocomplete",
    :name="props.name",
    :tabindex="props.tabindex",
    @input="onInput",
    @focus="onInputFocus",
    @blur="onInputBlur",
    @keydown="onKeyDown",
    v-only-number="'int'")
  input(
    v-else-if="props.type === 'float'"
    ref="input",
    :readonly="inputReadonly",
    :type="props.type",
    :value="modelValue",
    :maxlength="props.maxlength",
    :autocomplete="props.autocomplete",
    :name="props.name",
    :tabindex="props.tabindex",
    @input="onInput",
    @focus="onInputFocus",
    @blur="onInputBlur",
    @keydown="onKeyDown",
    v-only-number="'float'")

  .icon(v-if="slots.icon")
    slot(name="icon")
  .placeholder(:class="{top: modelValue !== '' || focused}")
    .placeholder__line
    .placeholder__label {{props.placeholder}}
  .error-text(:class="{visible: !!slots.error}")
    .error-text__line
    .error-text__content
      slot(name="error")
</template>
<script lang="ts" setup>
import { withDefaults, useSlots, ref, onMounted } from 'vue'

const props = withDefaults(defineProps<{
  type?: string,
  placeholder?: string,
  error?: string,
  modelValue: string,
  maxlength?: number,
  name?: string,
  autocomplete?: 'on' | 'off' | 'username' | 'current-password',
  tabindex?: number,
  disabled?: boolean
}>(), {
  type: 'text',
  placeholder: 'Введите текст',
  autocomplete: 'on',
  disabled: false
})

const emits = defineEmits(['update:modelValue', 'input', 'blur', 'keydown'])
const slots = useSlots()

const input = ref<HTMLInputElement | null>(null)
const tempInput = ref<HTMLInputElement | null>(null)
const focused = ref(false)
const inputReadonly = ref(props.autocomplete === 'off')

let fireEvents = !inputReadonly.value

const triggerFocus = () => {
  input.value!.focus()
}

const onInput = (e: InputEvent) => {
  if (fireEvents) {
    emits('update:modelValue', (e.target as HTMLInputElement).value)
    emits('input')
  }
}

const onInputFocus = () => {
  focused.value = true

  if (inputReadonly.value) {
    inputReadonly.value = false

    setTimeout(() => {
      tempInput.value!.focus()

      setTimeout(() => {
        input.value!.focus()
        fireEvents = true
      }, 0)
    }, 0)
  }
}

const onInputBlur = () => {
  if (fireEvents) {
    focused.value = false
    emits('blur')
  }
}

const onKeyDown = (e: KeyboardEvent) => {
  if (fireEvents) {
    emits('keydown', e)
  }
}

defineExpose({ triggerFocus })
</script>
