<template lang="pug">
.profile-username
  template(v-if="!editMode")
    .profile-username__text {{currentUser.username}}
    .profile-username__edit-button(@click="onEditUserNameClick")
      PenIcon
  template(v-else)
    .profile-username-form
      UiIconInput(
        ref="input",
        v-model="username",
        :placeholder="t('APP_ENTER_YOUR_NAME')",
        :maxlength="32",
        @keydown="onKeyDown",
        @input="onInput")
        template(v-slot:icon)
          UiSimpleButton.color-blue.size-36.desktop(@click="onSaveUserNameClick") {{t('A_SAVE')}}
          UiSimpleButton.color-blue.size-36.mobile(@click="onSaveUserNameClick")
            CheckIcon
        template(v-slot:error, v-if="error") {{error}}
</template>
<script lang="ts" setup>
import PenIcon from '@/assets/images/icons/pen-size-16.svg'
import CheckIcon from '@/assets/images/icons/check-size-16.svg'
import CoomeetChatInstance from '../../../../common/classes/CoomeetChat/CoomeetChat.class'
import UiIconInput from '../../../../components/ui/Inputs/UiIconInput.vue'
import UiSimpleButton from '../../../../components/ui/Buttons/UiSimpleButton.vue'
import {ref, toRaw, nextTick, onBeforeUnmount} from 'vue'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()

const mobileDevice = navigator.maxTouchPoints > 0
const currentUser = CoomeetChatInstance.user
const editMode = ref(false)
const username = ref(toRaw(currentUser.value.username))
const error = ref('')
const input = ref<{triggerFocus: () => void} | null>(null)

const onEditUserNameClick = () => {
  editMode.value = !editMode.value

  if (!mobileDevice) {
    nextTick(() => {
      input.value!.triggerFocus()
    })
  }
}

const onKeyDown = (e:KeyboardEvent) => {
  if (e.code === 'Enter' || e.key === 'Enter') {
    onSaveUserNameClick()
    e.preventDefault()
  }
}

const onInput = () => {
  if (error.value) error.value = ''
}

const onSaveUserNameClick = () => {
  const newUserName = username.value.trim()

  if (newUserName) {
    if (newUserName !== currentUser.value.username) {
      CoomeetChatInstance.changeUserName(newUserName)?.then(() => {
        error.value = ''
        editMode.value = false
      }).catch(({response}) => {
        if (response.data.code === 402) {
          error.value = newUserName.length < 4 ? t('ERROR_30') : 'Too long string'
        }
        if (response.data.code === 317) {
          error.value = t('APP_NAME_CANNOT_CONTAIN_EMOJI')
        } else {
          error.value = t(`APP_NAME_CANNOT_BE_EMPTY`)
        }
      })
    } else {
      editMode.value = false
    }
  } else {
    error.value = t(`APP_NAME_CANNOT_BE_EMPTY`)
  }
}

const onDocumentClick = (e: MouseEvent) => {
  if (editMode.value) {
    const target = e.target as HTMLElement
    if (!target.closest('.profile-username-form')) {
      editMode.value = false
      username.value = currentUser.value.username
    }
  }
}

document.addEventListener('mousedown', onDocumentClick)

onBeforeUnmount(() => {
  document.removeEventListener('mousedown', onDocumentClick)
})
</script>
