<template lang="pug">
.messages-list-header
  UiUserAvatar(v-if="currentDialog && !chatStore.videoChatOpened",
    :model="currentDialog",
    :size="32",
    :onlineIndicatorVisible="false",
    :showStory="showStory",
    :showPhotoByClick="true")

  .user-info(v-if="currentDialog")
    .user-name
      .user-name__text {{username}}
      .fav-button(
        v-if="!currentDialog.accountDeleted && !currentDialog.isSupport && currentDialog.isFriend"
        :class="{active: currentDialog?.favorite}",
        @click="onFavoriteButtonClick",)
    .user-info__state.online(v-if="currentDialog.isFriend", :class="{visible: currentDialog.online && !currentDialog.typing}") {{t('APP_ONLINE')}}
    .user-info__typing(:class="{visible: currentDialog.typing}") {{t('APP_TYPING')}}
    .user-info__last-seen(v-if="currentDialog?.lastSeen", :class="{visible: !currentDialog.online && !currentDialog.typing}") {{lastSeenDate}}

  .space-between

  ActionsList(v-if="!userBlocked")
</template>

<script lang="ts" setup>
import {computed} from 'vue'
import CoomeetChatInstance, {
  CoomeetChat,
  VideoDialogState
} from '../../../../common/classes/CoomeetChat/CoomeetChat.class'
import UiUserAvatar from '../../../../components/ui/UiUserAvatar.vue'
import ActionsList from './MessagesListHeader/ActionsList.vue'
import {DialogModelDelete} from '../../../../common/classes/CoomeetChat/models/DialogModel/interfaces'
import {useI18n} from 'vue-i18n'
import {useChatStore} from '../../../../stores/chat'

const {t, d} = useI18n()
const chatStore = useChatStore()

const currentDialog = CoomeetChatInstance.currentDialog

const username = computed(() => {
  if (currentDialog.value?.accountDeleted) return t('APP_ACCOUNT_DELETED')
  return currentDialog.value?.username
})

const lastSeenDate = computed(() => {
  if (currentDialog.value && currentDialog.value?.lastSeen) {
    const lastSeenTime = currentDialog.value.lastSeen * 1000
  
    const todayStartTime = new Date().setHours(0, 0, 0, 0)
  
    if (lastSeenTime >= todayStartTime) {
      return t('APP_LAST_SEEN_MALE', { date: t('S_TODAY'), time: d(new Date(lastSeenTime), 'time') })
    }
  
    const yesterdayStartTime = todayStartTime - (24 * 60 * 60 * 1000)
  
    if (lastSeenTime >= yesterdayStartTime) {
      return t('APP_LAST_SEEN_MALE', { date: t('S_YESTERDAY'), time: d(new Date(lastSeenTime), 'time') })
    }
    
    const nowYear = new Date().getFullYear()
    
    const lastSeenYear = new Date(lastSeenTime).getFullYear()
    
    if (lastSeenYear !== nowYear) {
      return t('APP_LAST_SEEN_MALE', { date: d(new Date(lastSeenTime), 'date'), time: d(new Date(lastSeenTime), 'time') })
    }
    
    return t('APP_LAST_SEEN_MALE', { date: d(new Date(lastSeenTime), 'day-month-long'), time: d(new Date(lastSeenTime), 'time') })
  }
  
  return t('S_TODAY')
})

const showStory = computed(() => {
  return CoomeetChatInstance.videoDialogState.value === VideoDialogState.Empty
})

const userBlocked = computed(() => {
  return currentDialog.value
    ? !currentDialog.value.accountDeleted && currentDialog.value.delete === DialogModelDelete.IamBlocked
    : false
})

const onFavoriteButtonClick = () => {
  CoomeetChatInstance.toggleFavoriteDialogStatus(currentDialog.value!)
}
</script>
