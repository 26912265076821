import ModelAbstract from './ModelAbstract.class'
import {
  UserModelAvatar,
  UserModelBan,
  UserModelBanStatus,
  UserModelBill,
  UserModelBillAccess,
  UserModelBlockCard,
  UserModelFemaleConfig,
  UserModelGender,
  UserModelGeo,
  UserModelMaleConfig, UserModelRole,
  UserModelSettings,
  UserModelStatusReg
} from './UserModel/interfaces'
import {SupportData} from './DialogModel/interfaces'

export default class UserModel extends ModelAbstract {
  public hash: string = ''
  public username: string = ''
  public avatar: UserModelAvatar | null = null
  public gender: UserModelGender | null = null
  public email: string = ''
  public ban: UserModelBan | null = null
  public statusReg: UserModelStatusReg = UserModelStatusReg.Guest
  public score: number = 0
  public hasContact: number = 0
  public ageRange: number = 0
  public consent: number = 0
  public geo: UserModelGeo | null = null
  public isConfirmEmail: number = 0
  public needConfirmEmail: number = 0
  public confirmEmailStatus: number = -1
  public subscribeConfirmEmaiStatus: number = -1
  public noEmailBox: number = 0
  public changeEmailStatus: number = 0
  public changeEmail: string = ''
  public changeConfirmEmail: string = ''
  public settings: UserModelSettings | null = null
  public storyLockExpirationTime: number = 0
  public proxySearchEnable: number = 0
  public isAvailableEmailConfirmationBonus: number = 0
  public screenshotCaptureInterval: number = 0

  public webPushAfterBlock: number = 0
  public webPushShow: number = 0
  public webPushSubscribe: number = 0
  public webPushViewMsg: number = 0
  public viewSettings: number = 0

  public config: (UserModelMaleConfig & UserModelFemaleConfig) | null = null

  public continueSearchAfterRating = true
  /** Разница после последнего измения баланса, после покупки или списания из админки **/
  public scoreDelta: number = 0

  // Male props
  public blockAccount: number = 0
  public scoreMessage: number = 0
  public oldTime: number = 0
  public isHasPayment: number = 0
  public limitUpdate: number = 0
  public timeLeft: number = 0
  public access: number = 0
  public accessExpired: number = 0
  public bill: UserModelBill | null = null
  public BlockCard: UserModelBlockCard | null = null
  public role: UserModelRole | null = null

  // Female props
  public exchange: number = 0
  public topWomen: any = {}
  public womenTarrif: any = {}
  public currentTarrif: any = {}

  public isGuest: boolean = true
  public typing: boolean = false

  public exitFromSearchOnCloseChat = false

  /**
   * Включено ли автопродление минут
   */
  public minutesAutoRefillEnabled = false
  public premiumSubscriptionOffer: UserModelBillAccess | null = null
  public autoPaymentError = false

  public supportDataList: SupportData[] = []

  public get hasAvatar() : boolean {
    return !!(this.avatar && (this.avatar.m || this.avatar.o || this.avatar.s))
  }

  public get hasPremiumOffers() : boolean {
    return this.bill?.Offers.Access ? true : false
  }

  public get needVerifyAge() : boolean {
    return this.ban?.Ban === 6
  }

  public get banned() : boolean {
    return !!this.ban && this.ban.AccountCheck !== 'AccountChecked'
  }

  public get hasStrongBan() : boolean {
    const strongBans = [
      UserModelBanStatus.Age,
      UserModelBanStatus.Rules,
      UserModelBanStatus.Lifetime,
      UserModelBanStatus.TenDays,
      UserModelBanStatus.Child
    ]
    return !!this.ban && strongBans.indexOf(this.ban?.Ban) >= 0
  }

  setData(data: any) {
    super.setData(data)

    this.hash = data.hash??''

    this.username = data.username as string
    this.avatar = data.avatar as UserModelAvatar
    this.gender = data.gender as UserModelGender
    this.email = data.email as string
    this.ban = data.ban as UserModelBan
    this.statusReg = data.statusReg as UserModelStatusReg
    this.score = (data.score??0) as number
    this.hasContact = data.hasContact as number
    this.ageRange = data.ageRange as number
    this.consent = data.consent as number
    this.geo = data.geo as UserModelGeo
    this.isConfirmEmail = data.isConfirmEmail as number
    this.needConfirmEmail = data.needConfirmEmail as number
    this.noEmailBox = data.noEmailBox as number
    this.changeEmailStatus = data.changeEmailStatus as number
    this.changeEmail = data.changeEmail as string
    this.settings = data.settings as UserModelSettings
    this.storyLockExpirationTime = data.storyLockExpirationTime as number
    this.proxySearchEnable = data.proxySearchEnable as number
    this.isAvailableEmailConfirmationBonus = data.isAvailableEmailConfirmationBonus as number
    this.screenshotCaptureInterval = data.config.screenshotCaptureInterval as number

    this.webPushAfterBlock = data.webPushAfterBlock
    this.webPushShow = data.webPushShow
    this.webPushSubscribe = data.webPushSubscribe
    this.webPushViewMsg = data.webPushViewMsg

    this.viewSettings = data.viewSettings??0

    this.config = data.config

    // Male props
    this.blockAccount = data.blockAccount as number
    this.scoreMessage = data.scoreMessage as number
    this.oldTime = data.oldTime as number
    this.isHasPayment = data.isHasPayment as number
    this.limitUpdate = data.limitUpdate as number
    this.timeLeft = (data.timeLeft??0) as number
    this.access = data.access as number
    this.accessExpired = data.accessExpired as number
    //this.bill = data.bill as UserModelBill
    this.BlockCard = data.BlockCard as UserModelBlockCard

    // Female props
    this.exchange = data.exchange as number
    this.topWomen = data.topWomen as Object
    this.womenTarrif = data.womenTarrif as Object
    this.currentTarrif = data.currentTarrif as Object

    this.isGuest = this.statusReg === UserModelStatusReg.Guest

    if (data.role) this.role = data.role

    this.updateBillProp(this._json.bill)
  }

  public updateBillProp(billData: any) : void {
    if (billData) {
      this.bill = JSON.parse(JSON.stringify(billData))
      this.updateOffers(billData.Offers)
    }
  }

  public updateOffers(data: any) : void {
    if (data.Access && data.Minutes) {
      this.minutesAutoRefillEnabled = false
      this.premiumSubscriptionOffer = null

      this.bill!.Offers.Access = null
      this.bill!.Offers.Minutes = null

      const minutes = []
      const access = []

      for (let id in data.Minutes) {
        minutes.push({
          id: parseInt(id),
          ...data.Minutes[id]
        })
      }

      this.minutesAutoRefillEnabled = minutes.filter((item) => item.IsAutoPurchaseEnabled).length > 0

      this.bill!.Offers.Minutes = minutes

      if (data.Access && Object.keys(data.Access).length > 0) {
        for (let id in data.Access) {
          access.push({
            id: parseInt(id),
            ...data.Access[id]
          })
        }

        const subscriptionOffers = access.filter((item) => item.IsSubscription)
        if (subscriptionOffers.length > 0) this.premiumSubscriptionOffer = subscriptionOffers[0]

        this.bill!.Offers.Access = access
      }
    }
  }
}
