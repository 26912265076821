import GiftModel from '../GiftModel.class'
import StickerModel from '../StickerModel.class'
import {WebSocketApiScopeData} from '../../WebSocketApi/WebSocketApi.class'
import {UserModelAvatar} from "../UserModel/interfaces";
import exp from "constants";

export interface DialogModelMessage {
  id : number,
  tempId?: number,
  created: number,
  creationTime: number,
  msg: string | DialogModelMessageImage | DialogModelMessageVideo,
  type: DialogModelMessageType,
  read: number,
  inbox: number,
  translate: string,
  hide: number,
  ball: number,
  viewText: string,
  gift?: GiftModel,
  sticker?: StickerModel
  scopeData?: WebSocketApiScopeData,
  markedForResend?: boolean
}

export interface DialogModelMessageImage {
  m: string
}

export interface DialogModelMessageVideo {
  mp4 : string,
  mp4ready: number,
  preview : string,
  previewready : number,
  webm: string,
  webmready : number
}

export interface DialogModelPromo {
  expired: number,
  cntMessages: number
}

export enum DialogModelModerationStatus {
  Empty = 0,
  Good = 1,
  Bad = 2
}

export enum DialogModelCallCancelReason {
  Canceled = 'Canceled',
  LowMinute = 'LowMinute',
  ContactNowIsOffline = 'ContactNowIsOffline',
  VideoCallUnavailable = 'VideoCallUnavailable'
}

export enum DialogModelDelete {
  NotDeleted = 0,
  MeDeleted = 1,
  IamDeleted = 2,
  MeBlocked = 3,
  IamBlocked = 4,
  NotDefined = 5
}

export enum DialogModelStatus {
  IamSendInvite = -1,
  SendForMeInvite = 0,
  ApprovedInvite = 1,
  HasNoInvite = 2
}

export type FriendStatus =
  | 'None' // Юзеры никак не связаны друг с другом
  | 'RequestReceived' // Прислал текущему юзеру запрос в друзья
  | 'RequestSent' // Текущий юзер отправил запрос в друзья
  | 'RequestDeclined' // Отклонил запрос в друзья от текущего юзера
  /**
   * "Полноценный" друг без ограничений в общении. Запрос в друзья был принят любой
   * из сторон, не удалял текущего юзера, не заблокирован текущим юзером
   */
  | 'ActiveFriend'
  /**
   * Всё ещё находится в списке, но помечен как удаливший текущего юзера из друзей.
   * Нельзя отправлять ему сообщения, премиум может отправить повторный запрос дружбы
   */
  | 'DeletedByFriend'
  /**
   * Помечен как заблокированный. Нельзя отправлять ему сообщения, его сообщения
   * не доходят, а он сам видит отправляемые сообщения как отправленные но не прочитанные
   */
  | 'BlockedFriend'

export enum DialogModelMessageType {
  Text = 0,
  Video = 1,
  Picture = 2,
  System = 3,
  NewFormatVideo = 4,
  Sticker = 6,
  Gift = 100
}

export interface DialogModelMessagesListOffset  {
  limit: number,
  skip: number
}

export interface SupportData {
  avatar: UserModelAvatar,
  id: number,
  contact: boolean,
  role: string,
  type: SupportType,
  username: string
}

export interface DialogModelBotStatus {
  decor: string,
  operator: number,
  questions: string[],
  commands: DialogModelBotStatusCommand[]
}

export interface DialogModelBotStatusCommand {
  text: string,
  key: number
}

export enum SupportType {
  General = 'general',
  Financial = 'financial',
  Moderation = 'moderation'
}

export interface DialogModelSystemInfoMessage {
  title: string,
  text: string
}
