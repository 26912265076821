<template lang="pug">
.banned-actions(v-show="props.deleted || props.blocked")
  template(v-if="props.deleted")
    .banned-actions__title {{t('C_FRIEND_INVITE_AGAIN')}}
    UiSimpleButton.size-46.color-green(@click="onAddToFriendsClick") {{t('A_FRIEND_ADD')}}
  template(v-else)
    UiSimpleButton.size-46.color-green(@click="onUnblockButtonClick") {{t('APP_UNLOCK')}}

  UiSimpleButton.size-46.color-white-2(@click="onDeleteDialogClick") {{t('A_DELETE')}}
</template>
<script lang="ts" setup>
import CoomeetChatInstance from '../../../../common/classes/CoomeetChat/CoomeetChat.class'
import DialogModel from '../../../../common/classes/CoomeetChat/models/DialogModel.class'
import {Popups, usePopupOverlayStore} from '../../../../stores/popupOverlay'
import UiSimpleButton from '../../../../components/ui/Buttons/UiSimpleButton.vue'
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const popupStore = usePopupOverlayStore()
const currentDialog = CoomeetChatInstance.currentDialog

const props = defineProps<{
  deleted: boolean,
  blocked: boolean
}>()

const onUnblockButtonClick = () => {
  if (currentDialog.value) {
    CoomeetChatInstance.blockUser(currentDialog.value as DialogModel, false)
  }
}

const onDeleteDialogClick = () => {
  if (currentDialog.value) {
    popupStore.showPopup(Popups.DeleteDialog)
  }
}

const onAddToFriendsClick = () => {
  if (CoomeetChatInstance.user.value.access === 0) {
    popupStore.showPopup(Popups.BuyPremium)
  } else {
    CoomeetChatInstance.invite(currentDialog.value as DialogModel)
  }
}
</script>
