import { defineStore } from 'pinia'
import { hideTooltip } from '../directives/tooltip'
import { ref } from 'vue'
import WindowEvent from '../common/events/window'
import ErrorLoggerInstance from "../common/classes/ErrorLogger.class";
import CoomeetChatInstance from '../common/classes/CoomeetChat/CoomeetChat.class'

export enum Popups {
  AccountDeleted = 'AccountDeleted',
  AnsweredTheCall = 'AnsweredTheCall',
  AutoPaymentEnabled = 'AutoPaymentEnabled',
  AutoPaymentError = 'AutoPaymentError',
  AutoPaymentNotAllowed = 'AutoPaymentNotAllowed',
  BlockNeedPremium = 'BlockNeedPremium',
  BlockUser = 'BlockUser',
  BuyMinutes = 'BuyMinutes',
  BuyPremium = 'BuyPremium',
  CameraDenied = 'CameraDenied',
  CardConfirmation = 'CardConfirmation',
  ChangeOfAccount = 'ChangeOfAccount',
  ClearCommunicationHistory = 'ClearCommunicationHistory',
  Complain = 'Complain',
  ConfirmAccountDelete = 'ConfirmAccountDelete',
  ConfirmEmail = 'ConfirmEmail',
  ConfirmEmailByLink = 'ConfirmEmailByLink',
  CreatePhoto = 'CreatePhoto',
  DeleteAvatar = 'DeleteAvatar',
  DeleteCard = 'DeleteCard',
  DeleteDialog = 'DeleteDialog',
  DisableAutoPayment = 'DisableAutoPayment',
  Disconnected = 'Disconnected',
  DoubleEnter = 'DoubleEnter',
  EditUser = 'EditUser',
  EmailConfirm = 'EmailConfirm',
  EmailConfirmAttention = 'EmailConfirmAttention',
  EmailConfirmForMinutes = 'EmailConfirmForMinutes',
  EnableAutoPayment = 'EnableAutoPayment',
  FriendRequest = 'FriendRequest',
  LoginRegistration = 'LoginRegistration',
  Logout = 'Logout',
  MessagesEnded = 'MessagesEnded',
  MessagesSettings = 'MessagesSettings',
  MinutesAdded = 'MinutesAdded',
  MinutesForRegistration = 'MinutesForRegistration',
  MinutesWithdrawed = 'MinutesWithdrawed',
  NewAppVersion = 'NewAppVersion',
  NewCardAttention = 'NewCardAttention',
  NoAccessForSearchBest = 'NoAccessForSearchBest',
  NoPremiumForSearchBest = 'NoPremiumForSearchBest',
  NotEnoughMinutes = 'NotEnoughMinutes',
  PaymentHistory = 'PaymentHistory',
  PaymentPremiumWasSuccessful = 'PaymentPremiumWasSuccessful',
  PaymentWasSuccessful = 'PaymentWasSuccessful',
  PersonalPremiumOffer = 'PersonalPremiumOffer',
  PersonalPremiumOfferCanceled = 'PersonalPremiumOfferCanceled',
  PersonalSubscriptionCancel = 'PersonalSubscriptionCancel',
  PersonalSubscriptionCardChanged = 'PersonalSubscriptionCardChanged',
  PersonalSubscriptionChangeCard = 'PersonalSubscriptionChangeCard',
  PersonalSubscriptionDetails = 'PersonalSubscriptionDetails',
  PersonalSubscriptionInfo = 'PersonalSubscriptionInfo',
  PhotoViewer = 'PhotoViewer',
  ProxyDetected = 'ProxyDetected',
  PushNotificationsBonus = 'PushNotificationsBonus',
  QuickSettings = 'QuickSettings',
  RecordVideo = 'RecordVideo',
  RegistrationWithGifts = 'RegistrationWithGifts',
  SendGift = 'SendGift',
  StoryNotFound = 'StoryNotFound',
  SystemReboot = 'SystemReboot',
  TermsOfService = 'TermsOfService',
  TimeIsOver = 'TimeIsOver',
  UploadPhoto = 'UploadPhoto',
  UserNotes = 'UserNotes',
  VideoChatClosed = 'VideoChatClosed',
  VideoChatClosedByPing = 'VideoChatClosedByPing',
  VideoChatRating = 'VideoChatRating',
  VideoViewer = 'VideoViewer',
  ConfirmChatClear = 'ConfirmChatClear',
  ConfirmAllChatClear = 'ConfirmAllChatClear',
  ConfirmSelectedChatsClear = 'ConfirmSelectedChatsClear',
  UploadError = 'UploadError',
  OpponentBanned = 'OpponentBanned',
  DeleteAccount = 'DeleteAccount',
  ComplainStory = 'ComplainStory',
  PaymentError = 'PaymentError',
  ConfirmVideoChatExit = 'ConfirmVideoChatExit',
  RestoreVideoChat = 'RestoreVideoChat',
  RestoreVideoChatFailed = 'RestoreVideoChatFailed',
  NoCameraAccess = 'NoCameraAccess',
  UserAccessDenied = 'UserAccessDenied'
}

export const canHidePopupOnBackgroundClick = [
  Popups.AccountDeleted,
  Popups.AnsweredTheCall,
  Popups.AutoPaymentEnabled,
  Popups.AutoPaymentError,
  Popups.AutoPaymentNotAllowed,
  Popups.BlockUser,
  Popups.BuyMinutes,
  Popups.BuyPremium,
  Popups.CameraDenied,
  Popups.ClearCommunicationHistory,
  Popups.Complain,
  Popups.ConfirmAccountDelete,
  Popups.ConfirmEmail,
  Popups.ConfirmEmailByLink,
  Popups.CreatePhoto,
  Popups.DeleteAvatar,
  Popups.DeleteCard,
  Popups.DeleteDialog,
  Popups.DisableAutoPayment,
  Popups.EditUser,
  Popups.EmailConfirm,
  Popups.EmailConfirmForMinutes,
  Popups.EnableAutoPayment,
  Popups.FriendRequest,
  Popups.Logout,
  Popups.MessagesEnded,
  Popups.MessagesSettings,
  Popups.MinutesAdded,
  Popups.MinutesForRegistration,
  Popups.MinutesWithdrawed,
  Popups.NoAccessForSearchBest,
  Popups.NoPremiumForSearchBest,
  Popups.NotEnoughMinutes,
  Popups.PaymentHistory,
  Popups.PaymentPremiumWasSuccessful,
  Popups.PaymentWasSuccessful,
  Popups.PersonalPremiumOffer,
  Popups.PersonalPremiumOfferCanceled,
  Popups.PersonalSubscriptionCancel,
  Popups.PersonalSubscriptionCardChanged,
  Popups.PersonalSubscriptionChangeCard,
  Popups.PersonalSubscriptionDetails,
  Popups.PersonalSubscriptionInfo,
  Popups.PhotoViewer,
  Popups.ProxyDetected,
  Popups.PushNotificationsBonus,
  Popups.QuickSettings,
  Popups.RegistrationWithGifts,
  Popups.StoryNotFound,
  Popups.UserNotes,
  Popups.VideoChatClosed,
  Popups.VideoViewer,
  Popups.UploadError,
  Popups.DeleteAccount,
  Popups.ComplainStory,
  Popups.PaymentError,
  Popups.ConfirmVideoChatExit,
  Popups.UserAccessDenied
]

export interface PopupItem {
  popup: Popups,
  data?: any | undefined
}

export let popupsStoreList : PopupItem[] = []

export const usePopupOverlayStore = defineStore({
  id: 'popupOverlay',
  state: () => ({
  }),
  actions: {
    showPopup(popupName: Popups, data?: any) : void {
      if (this.popupOpened(popupName)) {
        popupsStoreList = popupsStoreList.filter((popupItem) => popupItem.popup !== popupName)
      }

      hideTooltip()

      popupsStoreList.push({
        popup: popupName,
        data
      })

      window.dispatchEvent(new Event(WindowEvent.PopupsStateUpdated))
    },

    hidePopup(popupName?: Popups) {
      let removedPopupName = popupName
      if (popupName) {
        popupsStoreList = popupsStoreList.filter((popupItem) => popupItem.popup !== popupName)
      } else {
        const removedPopup = popupsStoreList.pop()
        removedPopupName = removedPopup?.popup
      }

      window.dispatchEvent(new Event(WindowEvent.PopupsStateUpdated))
    },

    popupOpened(popupName: Popups) : boolean {
      return popupsStoreList.filter((popupItem) => popupItem.popup === popupName).length > 0
    },

    hidePopupsOnDisconnect() : void {
      popupsStoreList = popupsStoreList
        .filter((popupItem) => [Popups.Disconnected, Popups.DoubleEnter].indexOf(popupItem.popup) >= 0)

      window.dispatchEvent(new Event(WindowEvent.PopupsStateUpdated))
    },

    hideTextChatPopups() : void {
      [
        Popups.UserNotes,
        Popups.EditUser,
        Popups.Complain,
        Popups.ConfirmChatClear,
        Popups.DeleteDialog,
        Popups.BlockUser,
        Popups.FriendRequest
      ].forEach((popupName) => this.hidePopup(popupName))
    },

    hideAllPopups() : void {
      popupsStoreList.forEach((popupItem) => this.hidePopup(popupItem.popup))
    }
  }
})
