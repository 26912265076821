import { createRouter, createWebHistory } from 'vue-router'
import MessagesList from '../components/CoomeetChat/ChatArea/MessagesList.vue'
import DialogNotSelected from '../components/CoomeetChat/ChatArea/DialogNotSelected.vue'
import Registration from '../components/CoomeetChat/ChatArea/Registration.vue'
import Support from '../components/CoomeetChat/ChatArea/Support.vue'
import UserProfile from '../components/CoomeetChat/ChatArea/UserProfile.vue'
import PushNotification from '../components/PushNotification.vue'
import Error404 from '../components/Error404.vue'

const routes = [
  {
    name: 'Index',
    path: '/',
    component: DialogNotSelected
  },
  {
    name: 'UserProfile',
    path: '/id:profileId',
    component: UserProfile
  },
  {
    name: 'Dialog',
    path: '/dialog/:id?',
    component: MessagesList
  },
  {
    name: 'Registration',
    path: '/registration',
    component: Registration
  },
  {
    name: 'Support',
    path: '/support',
    component: Support
  },
  {
    name: 'PushNotification',
    path: '/push-notification',
    component: PushNotification
  },
  {
    name: 'Story',
    path: '/story/:id',
    component: {}
  },
  {
    name: 'Error404',
    path: '/:pathMatch(.*)*',
    component: Error404
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
