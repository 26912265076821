<template lang="pug">
.camera-list(v-show="devicesList.length > 1 || mobileDevice")
  .camera-list__items(v-show="opened")
    .camera-list__items--item(v-for="(deviceItem, index) in devicesList",
      :class="{selected: deviceSelected(deviceItem)}",
      @click="onDeviceClick(deviceItem)")
      .camera-list__items--item__icon
        CameraIcon
      .camera-list__items--item__label {{deviceItem.label}}

  .camera-list__settings-button(@click="onSettingsButtonClick", v-if="!mobileDevice")
    .camera-list__settings-button--bg
    .camera-list__settings-button--icon
      SettingsIcon
  .camera-list__settings-button(@click="onSwitchCameraClick", v-else)
    .camera-list__settings-button--bg
    .camera-list__settings-button--icon
      CameraSwitchIcon
</template>
<script lang="ts" setup>
import SettingsIcon from '@/assets/images/icons/settings-size-22.svg'
import CameraSwitchIcon from '@/assets/images/icons/camera-switch-size-22.svg'
import CameraIcon from '@/assets/images/icons/camera-size-22.svg'
import {ref, onMounted, onBeforeUnmount} from 'vue'
import {useChatStore} from '../../../../../../stores/chat'
import isMobile from 'is-mobile'

export interface DeviceItem {
  label: string,
  deviceId: string
}

const props = defineProps<{
  localStream?: MediaStream
}>()

const emit = defineEmits(['selected', 'facingMode'])

const chatStore = useChatStore()

const devicesList = ref<DeviceItem[]>([])
const opened = ref(false)
const mobileDevice = isMobile({tablet: true, featureDetect: true})

const deviceSelected = (deviceItem: DeviceItem) => {
  if (props.localStream) {
    const videoTrack = props.localStream.getVideoTracks()[0]
    return videoTrack.getSettings().deviceId === deviceItem.deviceId
  }

  return false
}

const onSettingsButtonClick = () => {
  opened.value = !opened.value
}

const onDeviceClick = (deviceItem: DeviceItem) => {
  opened.value = false
  emit('selected', deviceItem)
}

const onSwitchCameraClick = () => {
  emit('facingMode', !chatStore.videoFacingMode)
}

const onDocumentClick = (e: MouseEvent) => {
  const target = e.target as HTMLElement
  if (target.closest('.camera-list__items') === null &&
      target.closest('.camera-list__settings-button') === null) {
    opened.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', onDocumentClick)
  navigator.mediaDevices.enumerateDevices().then((devices) => {
    devicesList.value = devices
        .filter((deviceItem) => deviceItem.kind === 'videoinput')
        .map((deviceItem) => {
          return {
            label: deviceItem.label,
            deviceId: deviceItem.deviceId
          }
        })
  })
})

onBeforeUnmount(() => {
  document.removeEventListener('click', onDocumentClick)
})
</script>
