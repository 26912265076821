const tooltipElement = document.createElement('div')
const tooltipTextElement = document.createElement('div')
const tooltipCornerElement = document.createElement('div')

tooltipElement.className = 'tooltip-directive-element'
tooltipTextElement.className = 'tooltip-directive-element__text'
tooltipCornerElement.className = 'tooltip-directive-element__corner'

tooltipElement.appendChild(tooltipCornerElement)
tooltipElement.appendChild(tooltipTextElement)

document.body.appendChild(tooltipElement)

let currentElement: HTMLElement | undefined = undefined

document.addEventListener('touchstart',() => {
  hideTooltip()
})

export const hideTooltip = () => {
  if (tooltipElement) {
    tooltipElement.classList.remove('visible')
  }
}

const onMouseEnter = (el: HTMLElement, binding: any) => {
  const classes = ['top', 'top-right', 'top-left', 'bottom', 'left', 'right']
  tooltipElement.classList.remove(...classes)

  const currentClass = binding.arg??'bottom'
  let haveTooltip = true

  tooltipElement.classList.add(currentClass)
  tooltipElement.classList.add('visible')

  if (!binding.value) {
    const tooltipContentEl = el.querySelector('.v-tooltip-text')

    if (tooltipContentEl) {
      tooltipTextElement.innerHTML = tooltipContentEl.innerHTML
    } else {
      haveTooltip = false
    }
  } else {
    tooltipTextElement.textContent = binding.value
  }

  if (haveTooltip) {
    tooltipElement.style.left = `0px`
    tooltipElement.style.top = `-1000px`

    setTimeout(() => {
      const tooltipTextRect = tooltipTextElement.getBoundingClientRect()
      const cornerRect = tooltipCornerElement.getBoundingClientRect()
      const elRect = el.getBoundingClientRect()

      switch(currentClass) {
        case 'top':
        case 'bottom': {
          let leftPosition = elRect.left + (elRect.width - tooltipTextRect.width) / 2
          const topPosition = currentClass === 'top'
            ? elRect.top - tooltipTextRect.height - 14
            : elRect.top + elRect.height + 14
          let cornerLeftPosition = 4 + (tooltipTextRect.width - cornerRect.width) / 2
          let xDelta = 0

          if (leftPosition < 16) {
            xDelta = 16 - leftPosition
          } else if (leftPosition + tooltipTextRect.width > window.innerWidth - 10) {
            xDelta = window.innerWidth - 10 - (leftPosition + tooltipTextRect.width)
          }

          leftPosition += xDelta
          cornerLeftPosition -= xDelta

          if (currentClass === 'top') {
            tooltipCornerElement.style.top = 'auto'
          } else if (currentClass === 'bottom') {
            tooltipCornerElement.style.top = '0px'
          }

          tooltipElement.style.left = `${leftPosition}px`
          tooltipElement.style.top = `${topPosition}px`
          tooltipCornerElement.style.left = `${cornerLeftPosition}px`

          break
        }
        case 'top-left':
        case 'top-right': {
          let leftPosition = currentClass === 'top-left'
            ? elRect.left - 15
            : elRect.left + elRect.width - tooltipTextRect.width + 15
          const topPosition = elRect.top - tooltipTextRect.height - 14
          let cornerLeftPosition = currentClass === 'top-left'
            ? 15
            : tooltipTextRect.width - cornerRect.width - 5

          tooltipElement.style.left = `${leftPosition}px`
          tooltipElement.style.top = `${topPosition}px`
          tooltipCornerElement.style.left = `${cornerLeftPosition}px`

          break
        }
        case 'left':
        case 'right': {
          let leftPosition = currentClass === 'right'
            ? elRect.left + elRect.width + 10
            : elRect.left - tooltipTextRect.width
          const topPosition = elRect.top + (elRect.height - tooltipTextRect.height) / 2

          tooltipElement.style.left = `${leftPosition}px`
          tooltipElement.style.top = `${topPosition}px`
          tooltipCornerElement.style.top = `${4 + (tooltipTextRect.height - cornerRect.height) / 2}px`

          if (currentClass === 'right') {
            tooltipCornerElement.style.left = '-5px'
          }

          break
        }
        default:
          throw new Error(`Incorrect tooltip class: ${currentClass}`)
      }
    }, 0)
  } else {
    hideTooltip()
  }
}

const onMouseLeave = () => {
  hideTooltip()
}

interface TooltipListener {
  el: HTMLElement,
  mouseenter: () => void,
  mouseleave: () => void,
}

let tooltipListeners : TooltipListener[] = []

const TooltipDirective = {
  mounted(el: HTMLElement, binding: any) {
    const mouseEnterListener = () => {
      onMouseEnter(el, binding)
      currentElement = el
    }

    const mouseLeaveListener = () => {
      onMouseLeave()
    }

    tooltipListeners.push({
      el,
      mouseenter: mouseEnterListener,
      mouseleave: mouseLeaveListener
    })

    el.addEventListener('mouseenter', mouseEnterListener)
    el.addEventListener('mouseleave', mouseLeaveListener)
  },
  unmounted(el: HTMLElement) {
    const list = tooltipListeners.filter((item) => item.el === el)

    if (list.length > 0) {
      const listener = list[0]
      el.removeEventListener('mouseenter', listener.mouseenter)
      el.removeEventListener('mouseleave', listener.mouseleave)
    }

    tooltipListeners = tooltipListeners.filter((item) => item.el !== el)

    if (currentElement === el) {
      currentElement = undefined
      hideTooltip()
    }
  },
  updated(el: HTMLElement, binding: any) {
    const list = tooltipListeners.filter((item) => item.el === el)

    if (list.length > 0) {
      const listener = list[0]
      el.removeEventListener('mouseenter', listener.mouseenter)
      el.removeEventListener('mouseleave', listener.mouseleave)
    }

    const mouseEnterListener = () => {
      onMouseEnter(el, binding)
      currentElement = el
    }

    const mouseLeaveListener = () => {
      onMouseLeave()
    }

    tooltipListeners.push({
      el,
      mouseenter: mouseEnterListener,
      mouseleave: mouseLeaveListener
    })

    el.addEventListener('mouseenter', mouseEnterListener)
    el.addEventListener('mouseleave', mouseLeaveListener)
  }
}

export default TooltipDirective
