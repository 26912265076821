import LocalizationInstance from '../../Localization.class'
import {ContactsListOffset, ContactsListRule, CoomeetChat, Environment} from '../CoomeetChat.class'
import ScopeUser from './scopes/ScopeUser.class'
import getScopeCommandId from './scopes/CommandIdGenerator'
import ScopeMessenger from './scopes/ScopeMessenger.class'
import ScopeUserCommands from './scopes/ScopeUser/commands'
import ScopeMessengerCommands from './scopes/ScopeMessenger/commands'
import DialogModel from '../models/DialogModel.class'
import {
  DialogModelMessage,
  DialogModelMessageImage,
  DialogModelMessageType,
  DialogModelMessageVideo
} from '../models/DialogModel/interfaces'
import GiftModel from '../models/GiftModel.class'
import StickerModel from '../models/StickerModel.class'
import Application from '../../Application.class'
import ScopeCommon from './scopes/ScopeCommon.class'
import ScopeVideo from './scopes/ScopeVideo.class'
import ScopeStory from './scopes/ScopeStory.class'
import StoryModel from '../models/StoryModel.class'
import ScopePayment from './scopes/ScopePayment.class'

import ScopeVideoCommands from './scopes/ScopeVideo/commands'
import ScopeCommonCommands from './scopes/ScopeCommon/commands'
import ScopePaymentCommands from './scopes/ScopePayment/commands'
import ScopeStoryCommands from './scopes/ScopeStory/commands'

import {UserModelBillCard} from '../models/UserModel/interfaces'
import pushNotification from '../../PushNotification.class'
import ScopeModerationCommands from './scopes/ScopeModeration/commands'
import ScopeModeration from './scopes/ScopeModeration.class'
import ErrorLoggerInstance from '../../ErrorLogger.class'
import iframeMessenger from '../../IframeMessenger.class'

export enum WebSocketApiEvents {
  Closed = 'Closed',
  Connected = 'Connected'
}

export enum WebSocketApiScopes {
  User = 'User',
  Messenger = 'Messenger',
  VideoChat = 'VideoChat',
  System = 'System',
  Common = 'Common',
  Video = 'Video',
  Story = 'Story',
  Payment = 'Payment',
  Moderation = 'Moderation'
}

export interface CommandPromiseArguments {
  response: WebSocketApiScopeData | null,
  command: WebSocketApiScopeData | null
}

export interface CommandResolver {
  (data : CommandPromiseArguments) : void
}

export interface WebSocketApiScopeData {
  event: {
    Scope?: WebSocketApiScopes,
    Cmd: string,
    Cid?: string,
    Rid?: string
  },
  data: any,
  resolver?: CommandResolver,
  rejecter?: CommandResolver,
  rejectTimeout?: NodeJS.Timeout | number,
  blobOptions?: any
}

interface PendingCommand {
  scopeData: WebSocketApiScopeData,
  connectionIndex: number
}

export interface WebSocketApiSignalSubscriber {
  signal: ScopeVideoCommands | ScopeUserCommands | ScopeStoryCommands | ScopeCommonCommands | ScopePaymentCommands | ScopeMessengerCommands | ScopeModerationCommands | 'Complete',
  callback: (scopeData?: WebSocketApiScopeData, command?:WebSocketApiScopeData) => void
}

// @ts-ignore
if(!window.WebSocket.modified) {
  // @ts-ignore
  window.WebSocket = class extends window.WebSocket {
    constructor(url: string | URL, protocols?: string | string[]) {
      // @ts-ignore
      super(url, protocols)
      // console.log("Create ws: ", this);
    }

    close() {
      // console.log("Ws close: ", this);
      if(super.close!=null) {
        super.close()
      } else {
        // @ts-ignore
        this.websocket.close()
      }
    }
  }
  // @ts-ignore
  window.WebSocket.modified = true
}

const ignoreAnswerCommands = [
  ScopeCommonCommands.FingerPrint,
  ScopeCommonCommands.Info,
  ScopeCommonCommands.Write,
  ScopeVideoCommands.UserAnswer,
  ScopeVideoCommands.UserOffer,
  ScopeVideoCommands.UserIce,
  ScopeVideoCommands.SearchStart,
  ScopeMessengerCommands.Invite,
  ScopeUserCommands.AccountDeleteReason,
  ScopeUserCommands.OneTap,
  ScopeVideoCommands.LoadedData
]

export default class WebSocketApi extends EventTarget {
  private _rejectResponseTimeout = 5000
  private _pingTimeout = 5000

  private _socket!: WebSocket
  private _webSocketURL!: string
  private _scopeCommon: ScopeCommon
  private _scopeUser: ScopeUser
  private _scopeMessenger: ScopeMessenger
  private _scopeVideo: ScopeVideo
  private _scopeStory: ScopeStory
  private _scopePayment: ScopePayment
  private _scopeModeration: ScopeModeration
  private _commandsPendingResponse: PendingCommand[] = []

  private _socketClosed = true
  private _pingTimeoutId: NodeJS.Timeout | null = null
  private _badPingsCount = 0
  private _lastBufferedAmount: number = 0

  private _connectionCounter = 0

  private _signalsSubscribers : WebSocketApiSignalSubscriber[] = []
  private _connectionStarted = false
  private _connectionDuration = 0
  private _connectionStartRequestTime = 0
  public connectionInfoWasSent = false

  private _someDataReceived = false
  private _someDataReceivedTimeout: NodeJS.Timeout | null = null

  public get connectionDuration() {
    return this._connectionDuration
  }

  constructor(private _env: Environment, private _coomeetChatInstance: CoomeetChat) {
    super()
    this._scopeUser = new ScopeUser(this._coomeetChatInstance, this)
    this._scopeMessenger = new ScopeMessenger(this._coomeetChatInstance, this)
    this._scopeCommon = new ScopeCommon(this._coomeetChatInstance, this)
    this._scopeVideo = new ScopeVideo(this._coomeetChatInstance, this)
    this._scopeStory = new ScopeStory(this._coomeetChatInstance, this)
    this._scopePayment = new ScopePayment(this._coomeetChatInstance, this)
    this._scopeModeration = new ScopeModeration(this._coomeetChatInstance, this)

    window.addEventListener('offline', this._onBrowserOffline)
    this._createSocket()
  }

  public testSignal(signal: ScopeVideoCommands | ScopeUserCommands | ScopeStoryCommands | ScopeCommonCommands | ScopePaymentCommands | ScopeModerationCommands, scopeData: any) {
    if (scopeData) {
      this._processScopeData(scopeData)
    } else {
      const subscribersList = this._signalsSubscribers.filter((item) => item.signal === signal)
      if (subscribersList.length > 0) subscribersList.forEach((item) => item.callback(scopeData))
    }
  }

  public setConnectionStartRequestTime(time: number) {
    this._connectionStartRequestTime = time
  }

  private async _createSocket() : Promise<void> {
    this._connectionCounter ++

    const webPushCode = await pushNotification.getPushCode()

    const hash = Application.hash
    const clientId = 'xcode'
    const lang = LocalizationInstance.locale.value
    const gender = this._coomeetChatInstance.user.value.gender ? this._coomeetChatInstance.user.value!.gender as number : 1
    const url = iframeMessenger.active ? iframeMessenger.parentWindowOrigin : window.location.origin
    const reffer = Application.referralUserId
    const partner = Application.partnerId
    const partnerChannel = Application.partnerChannel
    const restore = Application.restore

    this._webSocketURL = `${this._env.url}?token=${this._env.token}&token2=${this._env.token2}` +
      `&lang=${lang}` +
      `&${gender ? `&gender=${gender}` : ''}` +
      `&client_id=${clientId}` +
      `&client_ts=${Application.config.timestamp}` +
      `${hash ? `&hash=${hash}` : ''}` +
      `&url=${url}&version=new` +
      `&translate_lang=ru` +
      `${webPushCode ? `&webpush_code=${webPushCode}` : ''}` +
      `${reffer ? `&reffer=${reffer}` : ''}` +
      `${partner ? `&partner=${partner}` : ''}` +
      `${partnerChannel ? `&channel=${partnerChannel}` : ''}` +
      `${restore ? `&restore=${restore}` : ''}`

    if (this._socket) {
      this._socket.onmessage = null
      this._socket.onclose = null
      this._socket.onerror = null
      this._socket.onopen = null
    }

    this._socket = new WebSocket(this._webSocketURL)
    this._socket.binaryType = 'blob'
    this._socket.onmessage = this._onWebSocketMessage
    this._socket.onclose = this._onWebSocketClose
    this._socket.onerror = this._onWebSocketError
    this._socket.onopen = this._onWebSocketOpen

    this._connectionStarted = true
    this.connectionInfoWasSent = false
  }

  private _onWebSocketMessage = async (e: MessageEvent) : Promise<void> => {
    this._badPingsCount = 0
    this._someDataReceived = true

    if (this._pingTimeoutId) {
      clearTimeout(this._pingTimeoutId)
    }
    this._startPing()

    try {
      let stringedData = ''

      if (e.data instanceof Blob) {
        if (!e.data.text) {
          e.data.text = () =>
            new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onload = () => { resolve(reader.result as string) }
              reader.onerror = (error) => { reject(error) }
              reader.readAsText(e.data)
            })
        }
        stringedData = await (e.data as Blob).text()
      } else {
        stringedData = e.data
      }

      const scopeData = JSON.parse(stringedData) as WebSocketApiScopeData
      this._processScopeData(scopeData)
    } catch (e) {
      console.log('error on message parse from websocket', e)
    }
  }

  public sendCommand(command: any, data?: any, blobOptions: {type: string} | null = null) : Promise<CommandPromiseArguments> {
    this._startPing()

    if (ignoreAnswerCommands.indexOf(command) >= 0) {
      // if (Application.config.development) {
      //   console.log(`Command : ${command} will ignore answers from socket`)
      // }

      const apiData : WebSocketApiScopeData = {
        event: {
          Cmd: command,
        },
        data
      }

      try {
        const stringedData = JSON.stringify(apiData)

        if (!this._socketClosed) {
          if (blobOptions) {
            const blob = new Blob([stringedData], blobOptions)
            this._socket.send(blob)
          } else {
            this._socket.send(stringedData)
          }
        }
      } catch (e) {
        console.log('error on parse message', e, apiData)
      }

      return Promise.resolve<CommandPromiseArguments>({command: null, response: null})
    } else {
      return new Promise<CommandPromiseArguments>((resolve, reject) => {
        const commandId = getScopeCommandId()
        const apiData : WebSocketApiScopeData = {
          event: {
            Cid: commandId,
            Cmd: command,
          },
          data
        }

        try {
          const stringedData = JSON.stringify(apiData)

          if (!this._socketClosed) {
            if (blobOptions) {
              const blob = new Blob([stringedData], blobOptions)
              this._socket.send(blob)
            } else {
              this._socket.send(stringedData)
            }
          }

          this._fillScopeForData(apiData)
          apiData.resolver = resolve
          apiData.rejecter = reject
          apiData.blobOptions = blobOptions

          // apiData.rejectTimeout = setTimeout(() => {
          //   this._incrementBadPing()
          //   // apiData.rejecter?.({command: null, response: null})
          // }, this._rejectResponseTimeout)

          if ([ScopeCommonCommands.Write].indexOf(command) < 0) {
            this._commandsPendingResponse.push({
              scopeData: apiData,
              connectionIndex: this._connectionCounter
            })
          }

          if ([ScopeMessengerCommands.SendMessage, ScopeVideoCommands.ChatSendMessage].indexOf(command) >= 0) {
            const dialogId = data.type !== undefined ? data.id : null
            const dialog = dialogId ?
              this._coomeetChatInstance.dialogsList.getById(dialogId) :
              this._coomeetChatInstance.currentVideoDialog.value

            if (dialog) {
              const videoChatMessage = command === ScopeVideoCommands.ChatSendMessage

              const message = {
                id: -Date.now(),
                tempId: data.type !== undefined ? undefined : data.id as number,
                created: Date.now() / 1000,
                creationTime: Date.now(),
                msg: data.msg as string | DialogModelMessageImage | DialogModelMessageVideo,
                type: !videoChatMessage ? data.type as DialogModelMessageType : DialogModelMessageType.Text,
                read: !videoChatMessage ? 0 : 1,
                inbox: 0,
                translate: '',
                hide: 0,
                ball: 0,
                viewText: '',
                scopeData: !videoChatMessage ? apiData : undefined
              }

              if ([DialogModelMessageType.Video, DialogModelMessageType.NewFormatVideo].indexOf(data.type) >= 0) {
                message.msg = {
                  mp4ready: 1,
                  previewready: 1,
                  preview: `data:image/png;base64,${data.preview}`,
                  mp4: `data:${this._coomeetChatInstance.videoMimeType};base64,${data.byte}`,
                  webm: '',
                  webmready : 0
                } as DialogModelMessageVideo
              }

              !videoChatMessage ?
                dialog.addNotDeliveredMessage(message) :
                dialog.addMessage(message)
                dialog.setLastMessage(message)

              if (videoChatMessage && this._coomeetChatInstance.user.value.isGuest) {
                dialog.markVideoMessagesForResend()
              }
            }
          }
        } catch (e) {
          console.log('Error on send data to socket', e)
        }
      })
    }
  }

  private _processScopeData(scopeData: WebSocketApiScopeData) : void {
    if (scopeData.data?.unread !== undefined) {
      if (scopeData.event.Cmd === ScopeMessengerCommands.OnlineMessage) {
        const dialogId = scopeData.data.id
        const dialog = this._coomeetChatInstance.dialogsList.getById(dialogId)

        if (dialog?.isSupport) {
          if (this._coomeetChatInstance.currentDialog.value?.id !== dialogId) {
            this._coomeetChatInstance.unreadMessagesCount.value = scopeData.data.unread as number
          }
        } else {
          this._coomeetChatInstance.unreadMessagesCount.value = scopeData.data.unread as number
        }
      } else {
        this._coomeetChatInstance.unreadMessagesCount.value = scopeData.data.unread as number
      }
    }

    this._fillScopeForData(scopeData)
    if (scopeData.data.score !== undefined) {

      if (scopeData.event.Scope === WebSocketApiScopes.Payment && scopeData.data.score) {
        const user = this._coomeetChatInstance.user
        user.value.scoreDelta = scopeData.data.score - ((user.value.score??user.value.oldTime)??0)
      }

      this._coomeetChatInstance.user.value.oldTime = 0
      this._coomeetChatInstance.user.value.score = scopeData.data.score as number
    }

    if (scopeData.data.scoreMessage !== undefined) {
      this._coomeetChatInstance.user.value.scoreMessage = scopeData.data.scoreMessage as number
    }

    this._processResponse(scopeData)

    switch (scopeData.event.Scope) {
      case WebSocketApiScopes.User:
        this._scopeUser.processData(scopeData)
        break
      case WebSocketApiScopes.Messenger:
        this._scopeMessenger.processData(scopeData)
        break
      case WebSocketApiScopes.VideoChat:
        this._scopeVideo.processData(scopeData)
        break
      case WebSocketApiScopes.Video:
        this._scopeVideo.processData(scopeData)
        break
      case WebSocketApiScopes.Story:
        this._scopeStory.processData(scopeData)
        break
      case WebSocketApiScopes.Payment:
        this._scopePayment.processData(scopeData)
        break
      case WebSocketApiScopes.System:
        break
      case WebSocketApiScopes.Common:
        this._scopeCommon.processData(scopeData)
        break
      case WebSocketApiScopes.Moderation:
        this._scopeModeration.processData(scopeData)
        break
    }
  }

  private _processResponse(response: WebSocketApiScopeData) : void {
    if (response.event.Cid) {
      let pendingCommandInProgress : PendingCommand | null = null
      let foundedScopeData: WebSocketApiScopeData | undefined = undefined

      this._commandsPendingResponse = this._commandsPendingResponse.filter((pendingCommandItem) => {
        const scopeData = pendingCommandItem.scopeData

        if (scopeData.event.Cid === response.event.Cid) {

          if (scopeData.resolver && scopeData.rejecter) {
            if (response.event.Cmd === 'ErrorRequest') {
              // Ошибка "Request In Progress", возвращаем команду обратно в список ожидающих ответа
              if (response.data.code as number === 602) {
                pendingCommandInProgress  = pendingCommandItem
              } else {
                scopeData.rejecter({response, command: scopeData})
              }
            } else {
              scopeData.resolver({
                response,
                command: scopeData
              })
            }

            if (scopeData.rejectTimeout) clearTimeout(scopeData.rejectTimeout)
          }

          foundedScopeData = scopeData

          return false
        }

        return true
      })

      const subscribersList = this._signalsSubscribers.filter((item) => item.signal === response.event.Cmd)
      if (subscribersList.length > 0) subscribersList.forEach((item) => item.callback(response, foundedScopeData))

      if (pendingCommandInProgress) {
        this._commandsPendingResponse.push(pendingCommandInProgress)
      }
    } else {
      const subscribersList = this._signalsSubscribers.filter((item) => item.signal === response.event.Cmd)
      if (subscribersList.length > 0) subscribersList.forEach((item) => item.callback(response))
    }
  }

  private _startPing() : void {
    if (this._pingTimeoutId) {
      clearTimeout(this._pingTimeoutId)
      this._pingTimeoutId = null
    }

    if (!this._pingTimeoutId) {
      this._pingTimeoutId = setTimeout(() => {
        if (!this._socketClosed) {

          /** если кол-во байтов хранящихся в буффере сокета не постояно
           * значит что-то отправляется
           * сбрасываем кол-во плохих пингов **/
          if (this._lastBufferedAmount !== this._socket.bufferedAmount) {
            this._badPingsCount = 0
          } else {
            this._incrementBadPing()
          }

          this._lastBufferedAmount = this._socket.bufferedAmount

          this.sendCommand('Ping', null, {type: 'text/plain'})
            .finally(() => {
              this._startPing()
            })
        }
      }, this._pingTimeout)
    }
  }

  private _incrementBadPing() : void {
    this._badPingsCount ++

    if (this._badPingsCount >= 3) {
      this._badPingsCount = 0
      // console.log('Connection closed by timeout')
      // this.closeSocket()
    }
  }

  private _onBrowserOffline = () : void => {
    this.closeSocket()
  }

  private _onWebSocketOpen = (e: Event) => {
    this._connectionStarted = false
    this._socketClosed = false
    this._connectionDuration = Math.round(performance.now() - this._connectionStartRequestTime)

    this._someDataReceivedTimeout = setTimeout(() => {
      if (!this._someDataReceived) {
        ErrorLoggerInstance.error('WebSocket empty')
      }
    }, 4000)

    this.dispatchEvent(new Event(WebSocketApiEvents.Connected))
  }

  private _onWebSocketClose = (e: CloseEvent) : void => {
    if (this._connectionStarted) {
      ErrorLoggerInstance.error('failed to connect to socket', e.reason)
    }
    this.closeSocket()
  }

  private _onWebSocketError = (e: Event) : void => {
    ErrorLoggerInstance.error('webscoket error')
    console.log('webscoket error', e)
    this.dispatchEvent(new Event(WebSocketApiEvents.Closed))
  }

  private _fillScopeForData(scopeData: WebSocketApiScopeData) : void{
    if (!scopeData.event.Scope) {
      const userScopeCommands = Object.keys(ScopeUserCommands)
      const messengerScopeCommands = Object.keys(ScopeMessengerCommands)
      const commonScopeCommands = Object.keys(ScopeCommonCommands)
      const videoScopeCommands = Object.keys(ScopeVideoCommands)
      const storyScopeCommands = Object.keys(ScopeStoryCommands)
      const paymentScopeCommands = Object.keys(ScopePaymentCommands)
      const moderationScopeCommands = Object.keys(ScopeModerationCommands)

      switch (true) {
        case userScopeCommands.indexOf(scopeData.event.Cmd) >= 0:
          scopeData.event.Scope = WebSocketApiScopes.User
          break
        case messengerScopeCommands.indexOf(scopeData.event.Cmd) >= 0:
          scopeData.event.Scope = WebSocketApiScopes.Messenger
          break
        case commonScopeCommands.indexOf(scopeData.event.Cmd) >= 0:
          scopeData.event.Scope = WebSocketApiScopes.Common
          break
        case videoScopeCommands.indexOf(scopeData.event.Cmd) >= 0:
          scopeData.event.Scope = WebSocketApiScopes.Video
          break
        case storyScopeCommands.indexOf(scopeData.event.Cmd) >= 0:
          scopeData.event.Scope = WebSocketApiScopes.Story
          break
        case paymentScopeCommands.indexOf(scopeData.event.Cmd) >= 0:
          scopeData.event.Scope = WebSocketApiScopes.Payment
          break
        case moderationScopeCommands.indexOf(scopeData.event.Cmd) >= 0:
          scopeData.event.Scope = WebSocketApiScopes.Moderation
          break
      }
    }
  }

  public addSignalSubscriber(signalSubscriber: WebSocketApiSignalSubscriber) : WebSocketApiSignalSubscriber {
    this._signalsSubscribers.push(signalSubscriber)
    return signalSubscriber
  }

  public removeSignalSubscriber(signalSubscriber: WebSocketApiSignalSubscriber) : void {
    this._signalsSubscribers = this._signalsSubscribers.filter((item) => item !== signalSubscriber)
  }

  public closeSocket() : void {
    if (!this._socketClosed) {
      this._socket.close()
      this._socketClosed = true

      if (this._someDataReceivedTimeout) clearTimeout(this._someDataReceivedTimeout)

      this.dispatchEvent(new Event(WebSocketApiEvents.Closed))
    }
  }

  /** Scope Moderation **/

  public moderationGood(dialog?: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeModeration.moderationGood(dialog)
  }

  private _sendPendingCommand(socketData: WebSocketApiScopeData) : void {
    this._startPing()

    const commandId = getScopeCommandId()
    const apiData : WebSocketApiScopeData = {
      event: {
        Cid: socketData.event.Cid,
        Cmd: socketData.event.Cmd
      },
      data: socketData.data
    }

    try {
      if (!this._socketClosed) {
        const stringedData = JSON.stringify(apiData)
        if (socketData.blobOptions) {
          const blob = new Blob([stringedData], socketData.blobOptions)
          this._socket.send(blob)
        } else {
          this._socket.send(stringedData)
        }
      }
    } catch (e) {
      console.log('Error on send data to socket', e)
    }
  }

  public resendPendingCommands() : void {
    this._commandsPendingResponse = this._commandsPendingResponse
      .filter((commandItem) => commandItem.scopeData.event.Cmd !== 'Ping')

    this._commandsPendingResponse
      .filter((commandItem) => commandItem.connectionIndex !== this._connectionCounter)
      .forEach((commandItem) => this._sendPendingCommand(commandItem.scopeData))
  }

  public clearPendingCommands() : void {
    this._commandsPendingResponse.forEach((commandItem) =>
      commandItem.scopeData.rejecter?.({command: null, response: null})
    )
    this._commandsPendingResponse = []
  }

  public clearPendingCommandsByScope(scope: WebSocketApiScopes) : void {
    const tmpList: PendingCommand[] = []

    this._commandsPendingResponse.forEach((commandItem) => {
        if (commandItem.scopeData.event.Scope === scope) {
          commandItem.scopeData.rejecter?.({command: null, response: null})
        } else {
          tmpList.push(commandItem);
        }
      }
    )
    this._commandsPendingResponse = tmpList;
  }

  public setEnvironment(env: Environment) : void {
    this._env = env
    this._createSocket()
  }

  /** Scope story **/

  public getNextStory() : Promise<CommandPromiseArguments> {
    return this._scopeStory.getNextStory()
  }

  public getAuthorStory(womanId: number) : Promise<CommandPromiseArguments> {
    return this._scopeStory.getAuthorStory(womanId)
  }

  public updateStoryLike(story: StoryModel, status: boolean) : Promise<CommandPromiseArguments> {
    return this._scopeStory.updateStoryLike(story, status)
  }

  public storyAbuse(story: StoryModel, type: number) : Promise<CommandPromiseArguments> {
    return this._scopeStory.storyAbuse(story, type)
  }

  /** Scope video **/

  public cancelWaitRestore() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.cancelWaitRestore()
  }

  public restoreChat() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.restoreChat()
  }

  public cancelRestore() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.cancelRestore()
  }

  public routeChange(toScope: string) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.routeChange(toScope)
  }

  public loadedData() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.loadedData()
  }

  public videoCall(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.videoCall(dialog)
  }

  public videoCallCancel(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.videoCallCancel(dialog)
  }

  public videoCallAccept(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.videoCallAccept(dialog)
  }

  public videoCallRequest(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.videoCallRequest(dialog)
  }

  public videoCallDenied(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.videoCallDenied(dialog)
  }

  public userOffer(description: RTCSessionDescriptionInit) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.userOffer(description)
  }

  public userAnswer(description: RTCSessionDescriptionInit) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.userAnswer(description)
  }

  public userIce(ice: RTCIceCandidate) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.userIce(ice)
  }

  public stopChat() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.stopChat()
  }

  public videoChatSendMessage(message: string) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.sendTextMessage(message)
  }

  public chatGift(dialog: DialogModel, gift: GiftModel) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.chatGift(dialog, gift.id.toString())
  }

  public sendVideoVote(dialog: DialogModel, score: number) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.sendVote(dialog, score)
  }

  public sendVideoComment(dialog: DialogModel, type: number, text: string) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.sendComment(dialog, type, text)
  }

  public searchSetting(anyContent: boolean, filter: number) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.searchSetting(anyContent, filter)
  }

  public searchFilterOnlyBest(enabled: boolean) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.searchFilterOnlyBest(enabled)
  }

  public faceDetect(base64: string) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.faceDetect(base64)
  }

  public searchNext() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.searchNext()
  }

  public searchStop() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.searchStop()
  }

  public historyDeleteUser(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeVideo.historyDeleteUser(dialog)
  }

  public historyClear() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.historyClear()
  }

  public history() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.history()
  }

  /**
   * @deprecated
   */
  public searchStart() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.searchStart()
  }

  public videoSearchRequest() : Promise<CommandPromiseArguments> {
    return this._scopeVideo.videoSearchRequest()
  }

  /** Scope common **/

  public info() : void {
    this._scopeCommon.info()
  }

  public write(dialog: DialogModel, typing: boolean) : Promise<CommandPromiseArguments> {
    return this._scopeCommon.write(dialog, typing)
  }

  public viewSettings() : Promise<CommandPromiseArguments> {
    return this._scopeCommon.viewSettings()
  }

  public fingerPrint(token: string) : void {
    this._scopeCommon.fingerPrint(token)
  }

  /** Scope user **/

  public login({email, password} : {email: string, password: string}) : Promise<CommandPromiseArguments> {
    return this._scopeUser.login({email, password})
  }

  public oneTap(idToken: string) : void {
    this._scopeUser.oneTap(idToken)
  }

  public abuse(dialog: DialogModel & StoryModel, type: number) : Promise<CommandPromiseArguments> {
    return this._scopeUser.abuse(dialog, type)
  }

  public blockUser(dialog: DialogModel, block: boolean) : Promise<CommandPromiseArguments> {
    return this._scopeUser.blockUser(dialog, block)
  }

  public registration(email: string, password: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.registration({
      email,
      password
    })
  }

  public registrationComplete({agerange, username} : {agerange: number, username: string}) : Promise<CommandPromiseArguments> {
    return this._scopeUser.registrationComplete({
      agerange,
      username
    })
  }

  public restore(email: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.restore(email)
  }

  public acceptTerms() : Promise<CommandPromiseArguments> {
    return this._scopeUser.acceptTerms()
  }

  public changeUsername(name: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.changeUsername(name)
  }

  public logout() : Promise<CommandPromiseArguments> {
    return this._scopeUser.logout()
  }

  public makeAvatar(base64: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.makeAvatar(base64)
  }

  public removeAvatar() : Promise<CommandPromiseArguments> {
    return this._scopeUser.removeAvatar()
  }

  public paymentHistory() : Promise<CommandPromiseArguments> {
    return this._scopeUser.paymentHistory()
  }

  public changePersonal(email: string, agerange: number) : Promise<CommandPromiseArguments> {
    return this._scopeUser.changePersonal(email, agerange)
  }

  public changePassword(password: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.changePassword(password)
  }

  public accountDelete() : Promise<CommandPromiseArguments> {
    return this._scopeUser.accountDelete()
  }

  public accountDeleteConfirm(code: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.accountDeleteConfirm(code)
  }

  public accountDeleteReason(msg: string, code: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.accountDeleteReason(msg, code)
  }

  public cancelChangeEmail() : Promise<CommandPromiseArguments> {
    return this._scopeUser.cancelChangeEmail()
  }

  public mailConfirm(email: string, source: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.mailConfirm(email, source)
  }

  public confirmCode(email: string, code: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.confirmCode(email, code)
  }

  public changeNotification({
    NoticeUserMessage,
    NoticeSystemNew,
    NoticeGifts,
    NoticePartnerNews,
    NoticeUserInvite,
    NoticeBonusMinutesAddition
  } : {
    NoticeUserMessage?: number,
    NoticeSystemNew?: number,
    NoticeGifts?: number,
    NoticePartnerNews?: number,
    NoticeUserInvite?: number,
    NoticeBonusMinutesAddition?: number
  }) : Promise<CommandPromiseArguments> {
    return this._scopeUser.changeNotification({
      NoticeUserMessage,
      NoticeSystemNew,
      NoticeGifts,
      NoticePartnerNews,
      NoticeUserInvite,
      NoticeBonusMinutesAddition
    })
  }

  public webPushViewMsg(enabled: boolean) : Promise<CommandPromiseArguments> {
    return this._scopeUser.webPushViewMsg(enabled)
  }

  public webPushShow(enabled: boolean) : Promise<CommandPromiseArguments> {
    return this._scopeUser.webPushShow(enabled)
  }

  public changeTranslationSettings(enabled: boolean, lang: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.changeTranslationSettings(enabled, lang)
  }

  public subscribe(code: string, domain: string, webPushBonus: boolean) : Promise<CommandPromiseArguments> {
    return this._scopeUser.subscribe(code, domain, webPushBonus)
  }

  public unSubscribe(code: string, domain: string, webPushBonus: boolean) : Promise<CommandPromiseArguments> {
    return this._scopeUser.unSubscribe(code, domain, webPushBonus)
  }

  public webPushAfterBlock(enabled: boolean) : Promise<CommandPromiseArguments> {
    return this._scopeUser.webPushAfterBlock(enabled)
  }

  public accountVerification(byte: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.accountVerification(byte)
  }

  public changeLang(lang: string) : Promise<CommandPromiseArguments> {
    return this._scopeUser.changeLang(lang)
  }

  public moderationBad(dialog?: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeModeration.moderationBad(dialog)
  }

  /** Scope Messenger **/

  public supportAlert(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.supportAlert(dialog)
  }

  public getContactsList(offset: ContactsListOffset, rule: ContactsListRule) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.getContactsList(offset, rule)
  }

  public userSearch(name: string) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.userSearch(name)
  }

  public getDialogMessages(dialogModel: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.getDialogMessages(dialogModel)
  }

  public sendTextMessage(message: string, dialog: DialogModel, commandId?:number) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.sendTextMessage(message, dialog, commandId)
  }

  public sendPhotoMessage(base64: string, dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.sendPhotoMessage(base64, dialog)
  }

  public sendVideoMessage({videoBase64, previewBase64} : {videoBase64: string, previewBase64: string}, dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.sendVideoMessage({videoBase64, previewBase64}, dialog)
  }

  public sendGift(gift: GiftModel, dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.gift(dialog, gift.id.toString())
  }

  public afterGift(gift: GiftModel, dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.afterGift(dialog, gift.id.toString())
  }

  public sendSticker(sticker: StickerModel, dialog: DialogModel) : void {
    this._scopeMessenger.sendSticker(sticker, dialog)
  }

  public clearDialogs(dialogs: DialogModel[]) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.clearMulty(dialogs)
  }

  public clearDialog(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.clear(dialog)
  }

  public readDialogs(dialogs: DialogModel[]) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.markDialogsAsRead(dialogs)
  }

  public markDialogAsRead(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.markDialogAsRead(dialog)
  }

  public readFull() : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.readFull()
  }

  public clearFull() : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.clearFull()
  }

  public markMessageAsRead(dialog: DialogModel, message: DialogModelMessage) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.markMessageAsRead(dialog, message)
  }

  public addDialogToFavorites(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.addDialogToFavorites(dialog)
  }

  public removeDialogFromFavorites(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.removeDialogFromFavorites(dialog)
  }

  public contactRename(dialog: DialogModel, name: string) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.contactRename(dialog, name)
  }

  public removeUser(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.removeUser(dialog)
  }

  public comment(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.comment(dialog)
  }

  public commentSave(dialog: DialogModel, text: string) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.commentSave(dialog, text)
  }

  public inviteAccept(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.inviteAccept(dialog)
  }

  public inviteDecline(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.inviteDecline(dialog)
  }

  public inviteCancel(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.inviteCancel(dialog)
  }

  public invite(dialog: DialogModel) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.invite(dialog)
  }

  public userInfo(dialogId: number) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.userInfo(dialogId)
  }

  public getContact(dialogId: number) : Promise<CommandPromiseArguments> {
    return this._scopeMessenger.getContact(dialogId)
  }

  /** Scope payment **/

  public checkout(billing: string, offer: number, card: string) : Promise<CommandPromiseArguments> {
    return this._scopePayment.checkout(billing, offer, card)
  }

  public autoRefillEnable(offer: number, card: string) : Promise<CommandPromiseArguments> {
    return this._scopePayment.autoRefillEnable(offer, card)
  }

  public autoRefillDisable() : Promise<CommandPromiseArguments> {
    return this._scopePayment.autoRefillDisable()
  }

  public userCardRemove(card: UserModelBillCard) : Promise<CommandPromiseArguments> {
    return this._scopePayment.userCardRemove(card)
  }

  public userCardConfirm(captureId: number, amount: string) : Promise<CommandPromiseArguments> {
    return this._scopePayment.userCardConfirm(captureId, amount)
  }

  public autoSubscriptionChangeCard(card: UserModelBillCard) : Promise<CommandPromiseArguments> {
    return this._scopePayment.autoSubscriptionChangeCard(card)
  }

  public autoSubscriptionDisable() : Promise<CommandPromiseArguments> {
    return this._scopePayment.autoSubscriptionDisable()
  }

  public updateOffers() : Promise<CommandPromiseArguments> {
    return this._scopePayment.updateOffers()
  }
}
