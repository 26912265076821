import {ref, Ref, shallowRef, ShallowRef} from 'vue'
import HttpApiInstance, {HttpApi} from './HttpApi/HttpApi.class'
import WebSocketApi, {
  CommandPromiseArguments,
  WebSocketApiEvents, WebSocketApiScopes,
  WebSocketApiSignalSubscriber
} from './WebSocketApi/WebSocketApi.class'
import UserModel from './models/UserModel.class'
import ModelsList from './models/ModelsList.class'
import DialogModel from './models/DialogModel.class'
import GiftModel from './models/GiftModel.class'
import StickerModel from './models/StickerModel.class'
import Application from './../Application.class'
import {DialogModelDelete, DialogModelMessage, DialogModelStatus} from './models/DialogModel/interfaces'
import StoryModel from "./models/StoryModel.class";
import {UserModelBillCard} from "./models/UserModel/interfaces";
import MediaStreamRequester, {DevicesPermissionState} from './WebRtc/MediaStreamRequester.class'
import ErrorLoggerInstance from '../ErrorLogger.class'

export enum VideoDialogState {
  Empty = 'Empty',
  Searching = 'Searching',
  CanceledCall = 'CanceledCall',
  Connecting = 'Connecting',
  Connected = 'Connected',
  Closed = 'Closed',
  ClosedByPing = 'ClosedByPing',
  Vote = 'Vote',
  TimeOver = 'TimeOver',
  VideoCallAccept = 'VideoCallAccept',
  WaitRestore = 'WaitRestore', // просто ждет восстановления связи с опонентом
  WaitRestoreFailed = 'WaitRestoreFailed'
}

export enum VideoChatMode {
  Call = 'Call',
  Search = 'Search'
}

export enum ConnectionState {
  Connecting = 'Connecting',
  Connected = 'Connected',
  Disconnected = 'Disconnected'
}

export interface Environment {
  url: string,
  url2: string,
  token: string,
  token2: string
}

export enum ContactsListRule {
  Last = 'last',
  Favorite = 'favorite',
  Unread = 'unread',
  All = 'all',
  Online = 'online',
  Search = 'search',
  Blocked = 'block'
}

export interface ContactsListOffset {
  limit: number,
  skip: number
}

export class CoomeetChat {
  private static _instance?: CoomeetChat
  private _environment : Environment
  private _webSocketApi: WebSocketApi | null = null
  private _httApi!: HttpApi
  private _reconnectTimeout: NodeJS.Timeout | null = null

  public useCache = ref(true)

  public readonly cardConfirmationPopupInputCounts = ref(0)
  public readonly sendingMediaInProgress = ref(false)

  public readonly localMediaStream: ShallowRef<MediaStream | null> = shallowRef<MediaStream | null>(null)
  public readonly mediaStreamClosed = ref(true)
  public readonly videoChatMode = ref<VideoChatMode>(VideoChatMode.Search)
  public readonly videoDialogState = ref<VideoDialogState>(VideoDialogState.Empty)
  public readonly videoChatSearchQueue = ref<number | null>(null)

  public readonly connectionState = ref<ConnectionState | null>(null)
  public readonly initialContactsReceived = ref(false)

  public readonly user = ref(new UserModel())
  public readonly totalContactsCount: Ref<number> = ref(0)
  public readonly unreadMessagesCount: Ref<number> = ref(0)
  public readonly incomingCall: Ref<DialogModel | null> = ref(null)
  public readonly outgoingCall: Ref<DialogModel | null> = ref(null)
  public readonly currentDialog: Ref<DialogModel | null> = ref(null)
  public readonly currentVideoDialog: Ref<DialogModel | null> = ref(null)
  public readonly lastVideoDialog: Ref<DialogModel | null> = ref(null)
  public readonly videoDialogForRestoreAfterDisconnect: Ref<DialogModel | null> = ref(null)

  public readonly dialogsList: ModelsList<DialogModel> = new ModelsList<DialogModel>()
  public readonly lastContactsListRule = ref<ContactsListRule>(ContactsListRule.Last)
  public readonly currentContactsListRule = ref<ContactsListRule>(ContactsListRule.Last)
  public readonly dialogsHistory: ModelsList<DialogModel> = new ModelsList<DialogModel>()
  public readonly contactsLoading = ref(false)

  public readonly storiesList: Ref<StoryModel[]> = ref([])
  public readonly storiesListIndex: Ref<number> = ref(0)

  public dialogsListByRule!: Map<ContactsListRule, ModelsList<DialogModel>>
  private _contactsListOffsets!: Map<ContactsListRule, ContactsListOffset>
  private _contactsListLoaded!: Map<ContactsListRule, boolean>
  private _allContactsReceived = false

  private _dialogSearchName: string = ''

  public readonly videoMimeType : string

  private _lastScreenshotSentTime: number | null = 0

  /** Запросы к камере **/
  private _mediaStreamRequester: MediaStreamRequester = new MediaStreamRequester()

  public get devicesPermissionState() : Ref<DevicesPermissionState> {
    return this._mediaStreamRequester.permissionsState
  }

  public get lastScreenshotSentTime(): number | null {
    return this._lastScreenshotSentTime
  }

  constructor() {
    this._environment = {
      url: '',
      url2: '',
      token: '',
      token2: ''
    }

    try {
      this.videoMimeType = MediaRecorder.isTypeSupported('video/mp4') ? 'video/mp4' : 'video/webm;codecs=vp8,opus'
    } catch (e) {
      this.videoMimeType = 'video/mp4'
      console.log('Error on MediaRecorder API, Setting up video/mp4', e)
    }
  }

  public init() : void {
    this._httApi = HttpApiInstance
    this._httApi.setApiURL(Application.config.api.url)
    this._createContactsListProperties()

    this.connect()
  }

  public static GetInstance() : CoomeetChat {
    if (!this._instance) this._instance = new CoomeetChat()
    return this._instance
  }

  public getContactsListOffset(listRule: ContactsListRule) {
    return this._contactsListOffsets.get(listRule)
  }

  public setSearchDialogsName(name: string) : void {
    this._dialogSearchName = name
  }

  public async requestMediaStream(constraints: MediaStreamConstraints) : Promise<MediaStream> {
    this.mediaStreamClosed.value = false
    return this._mediaStreamRequester.requestMediaStream(constraints)
  }

  public closeLocalMediaStream() : void {
    this.mediaStreamClosed.value = true

    if (this.localMediaStream.value) {
      this.localMediaStream.value!.getTracks().forEach((trackItem) => trackItem.stop())
      this._mediaStreamRequester.clearStream()
      this.localMediaStream.value = null
    }
  }

  private _onSocketApiClosed = (e: Event) : void => {
    this.connectionState.value = ConnectionState.Disconnected

    this.dialogsList.modelsList.value.forEach((dialogItem) => {
      dialogItem.resetInitialState()
    })

    if (!this.initialContactsReceived.value) setTimeout(() => {
      this._getEnvironment()
    }, 3000)
  }

  private async _getEnvironment() : Promise<void> {
    this.connectionState.value = ConnectionState.Connecting
    this._clearReconnectTimeout()
    try {
      const startConnectionTime = performance.now()
      const env = Application.config.development ? 'test' : null

      const data = await this._httApi.apiServerGet(env)
      this._environment.url = data.url
      this._environment.url2 = data.url2
      this._environment.token = data.token
      this._environment.token2 = data.token2

      this._createWebSocketApi(startConnectionTime)
    } catch (e) {
      ErrorLoggerInstance.error('Error on get env')
      console.log('Error on get env', e)
      //this.connectionState.value = ConnectionState.Disconnected
      this.connectionState.value = ConnectionState.Disconnected

      this._reconnectTimeout = setTimeout(() => {
        this.connect()
      }, 5000)
    }
  }

  private _clearReconnectTimeout() : void {
    if (this._reconnectTimeout) {
      clearTimeout(this._reconnectTimeout)
      this._reconnectTimeout = null
    }
  }

  private _createWebSocketApi(startConnectionTime: number) : void {
    if (!this._webSocketApi) {
      this._webSocketApi = new WebSocketApi(this._environment, this)
      this._webSocketApi.addEventListener(WebSocketApiEvents.Closed, this._onSocketApiClosed)
    } else {
      this._webSocketApi.setEnvironment(this._environment)
    }

    this._webSocketApi.setConnectionStartRequestTime(startConnectionTime)
  }

  private _createContactsListProperties() : void {
    this._contactsListOffsets = new Map<ContactsListRule, ContactsListOffset>()
    this._contactsListLoaded = new Map<ContactsListRule, boolean>()
    this.dialogsListByRule = new Map<ContactsListRule, ModelsList<DialogModel>>()

    for (let ruleKey of Object.keys(ContactsListRule)) {
      const rule = ((ContactsListRule as {[i: string]: ContactsListRule})[ruleKey]) as ContactsListRule

      this._contactsListOffsets.set(rule, {
        limit: 30,
        skip: rule === ContactsListRule.Last ? 31 : 0
      })
      this._contactsListLoaded.set(rule, false)
      this.dialogsListByRule.set(rule, new ModelsList<DialogModel>())
    }
  }

  public clearSearchListOffsets() : void {
    this._contactsListOffsets.get(ContactsListRule.Search)!.skip = 0
    this._contactsListLoaded.set(ContactsListRule.Search, false)
  }

  public subscribeSocketSignal(subscriber: WebSocketApiSignalSubscriber) : WebSocketApiSignalSubscriber | null {
    return this._webSocketApi?.addSignalSubscriber(subscriber)??null
  }

  public unsubscribeSocketSignal(subscriber: WebSocketApiSignalSubscriber) : void {
    this._webSocketApi?.removeSignalSubscriber(subscriber)
  }

  public connect() : void {
    this._webSocketApi?.clearPendingCommandsByScope(WebSocketApiScopes.Video)
    this.clearVideoChatState()
    this._getEnvironment()
  }

  public setCurrentDialog(dialogModel: DialogModel | null) : void {
    if (this.currentDialog.value?.id !== dialogModel?.id) {
      if (this.currentDialog.value) this.currentDialog.value!.notSentGift = null
      this.currentDialog.value = dialogModel

      if (dialogModel) {
        dialogModel.hidden = false
        this.dialogsList.addModel(dialogModel)
        this.dialogsListByRule.get(ContactsListRule.Last)?.addModel(dialogModel)
        if (dialogModel.newMessage > 0 && (this.user.value.access || dialogModel.status === DialogModelStatus.ApprovedInvite)) {
          this.unreadMessagesCount.value --
          this.markDialogAsRead(dialogModel)
        }
      }

      if (dialogModel
        && !dialogModel.notInContacts
        && dialogModel.status === DialogModelStatus.ApprovedInvite
      ) {
        if (!dialogModel.initialDataReceived) {
          if (this._webSocketApi) {
            dialogModel.initialDataReceived = true
            Promise.all([
              this._webSocketApi.getDialogMessages(dialogModel),
              dialogModel.isSupport ? Promise.resolve() : this._webSocketApi.comment(dialogModel)
            ])?.then(() => {
            }).catch((e) => {
              console.log(e)
            })
          }
        }
        // else {
        //   if (dialogModel.newMessage > 0) {
        //     this._webSocketApi?.markDialogAsRead(dialogModel)
        //   }
        // }
      }
    }
  }

  public setCurrentVideoDialog(dialogModel: DialogModel | null) : void {
    if (this.currentVideoDialog.value) {
      this.currentVideoDialog.value!.isVideoCall = false
      this.currentVideoDialog.value!.notSentGift = null
      this.currentVideoDialog.value!.callCancelled = false
      this.currentVideoDialog.value!.closeWebrtcConnection()
    }

    this.currentVideoDialog.value = dialogModel

    if (dialogModel) {
      // dialogModel.videoChatStartTime = Date.now()
      this.lastVideoDialog.value = dialogModel
    }

    if (dialogModel
      && !dialogModel.notInContacts
      && dialogModel.status === DialogModelStatus.ApprovedInvite
    ) {

      if (!dialogModel.initialDataReceived) {
        if (this._webSocketApi) {
          Promise.all([
            this._webSocketApi.getDialogMessages(dialogModel),
            dialogModel.isSupport ? Promise.resolve() : this._webSocketApi.comment(dialogModel)
          ])?.finally(() => {
            dialogModel.initialDataReceived = true
          })
        }
      }
    }
  }

  public removeDialogFromAllLists(dialog: DialogModel) : void {
    for (let ruleKey of Object.keys(ContactsListRule)) {
      const rule = ((ContactsListRule as { [i: string]: ContactsListRule })[ruleKey]) as ContactsListRule
      const list = this.dialogsListByRule.get(rule)
      if (list) list.removeModel(dialog)
    }

    if (dialog.isSupport) {
      this.dialogsList.removeModel(dialog)
    } else {
      dialog.hidden = true
      dialog.lastMessage = null
      dialog.status = null
    }
  }

  public removeAllDialogsFromAllLists() : void {
    for (let ruleKey of Object.keys(ContactsListRule)) {
      const rule = ((ContactsListRule as { [i: string]: ContactsListRule })[ruleKey]) as ContactsListRule
      const list = this.dialogsListByRule.get(rule)

      this._contactsListLoaded.set(rule, false)
      this._contactsListOffsets.set(rule, {
        limit: 30,
        skip: rule === ContactsListRule.Last ? 31 : 0
      })

      if (list) list.clear()
    }

    this.dialogsList.clear()
  }

  public clearVideoChatState() : void {
    this.setCurrentVideoDialog(null)
    this.videoDialogState.value = VideoDialogState.Empty
  }

  public resetVideoChatStateForSearchNext() : void {
    // this.closeLocalMediaStream()
    this.videoDialogState.value = VideoDialogState.Searching
  }

  /****** команды в сокет *******/

  public info() : void {
    this._webSocketApi?.info()
  }

  public registration({email, password} : {email : string, password : string}) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.registration(email, password)
  }

  public registrationComplete({agerange, username} : {agerange: number, username: string}) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.registrationComplete({agerange, username})
  }

  public login({email, password} : {email: string, password: string}) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.login({
      email: email,
      password: password
    })
  }

  public oneTap(idToken: string) : void {
    this._webSocketApi?.oneTap(idToken)
  }

  public acceptTerms() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.acceptTerms()
  }

  public restore(email: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.restore(email)
  }

  public changeUserName(name: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.changeUsername(name)
  }

  public logout() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.logout()
  }

  public makeAvatar(base64: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.makeAvatar(base64)
  }

  public removeAvatar() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.removeAvatar()
  }

  public accountVerification(byte: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.accountVerification(byte)
  }

  public sendMessage(text: string, dialog: DialogModel, commandId?:number) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.sendTextMessage(text, dialog, commandId)
  }

  public sendMessageToVideoChat(text: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.videoChatSendMessage(text)
  }

  public getDialogMessages(dialogModel: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    if (
      !dialogModel.allMessagesLoaded &&
      (
        (
          dialogModel.delete === DialogModelDelete.MeDeleted &&
          dialogModel.status !== DialogModelStatus.IamSendInvite
        ) ||
        dialogModel.status === DialogModelStatus.ApprovedInvite
      )
    ) {
      return this._webSocketApi?.getDialogMessages(dialogModel)
    }

    return undefined
  }

  public sendPhotoMessage(base64: string) :Promise<CommandPromiseArguments> | undefined {
    if (this.currentDialog.value) {
      return this._webSocketApi?.sendPhotoMessage(base64, this.currentDialog.value!)
    }

    return undefined
  }

  public sendVideoMessage({videoBase64, previewBase64} : {videoBase64: string, previewBase64: string}) : Promise<CommandPromiseArguments> | undefined {
    if (this.currentDialog.value) {
      return this._webSocketApi?.sendVideoMessage({videoBase64, previewBase64}, this.currentDialog.value!)
    }
  }

  public markMessageAsReaded(dialog: DialogModel, message: DialogModelMessage) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.markMessageAsRead(dialog, message)
  }

  public markDialogAsRead(dialog: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.markDialogAsRead(dialog)
  }

  public readFull() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.readFull()
  }

  public clearFull() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.clearFull()
  }

  public getContactsList(rule: ContactsListRule | null = null) : void {
    if (!this.contactsLoading.value) {
      if (this._dialogSearchName) {
        const searchContactsLoaded = this._contactsListLoaded.get(ContactsListRule.Search)
        if (this._dialogSearchName.trim().length >= 3 && !searchContactsLoaded) {
          this.contactsLoading.value = true
          this._webSocketApi?.userSearch(this._dialogSearchName).then(({response}) => {
            if (response) {
              const searchOffset = this._contactsListOffsets.get(ContactsListRule.Search)!
              this.contactsLoading.value = false
              this._contactsListLoaded.set(ContactsListRule.Search, response.data.further === 0)
              searchOffset.skip += searchOffset.limit
            }
          }).finally(() => this.contactsLoading.value = false)
        }
      } else {
        if (rule === null) rule = this.currentContactsListRule.value

        this.currentContactsListRule.value = rule

        const contactsListOffset: ContactsListOffset = this._contactsListOffsets.get(rule)!
        const allContactsInListLoaded: boolean = this._contactsListLoaded.get(rule)!

        if (!allContactsInListLoaded) {
          this.contactsLoading.value = true

          this._webSocketApi?.getContactsList(contactsListOffset, rule).then(({response}) => {
            if (response) {
              this.contactsLoading.value = false
              // this._contactsListLoaded.set(rule as ContactsListRule, Object.keys(response.data.contacts).length < contactsListOffset.limit)
              this._contactsListLoaded.set(rule as ContactsListRule, contactsListOffset.skip + contactsListOffset.limit >= response.data.total)
              contactsListOffset.skip += contactsListOffset.limit
            }
          }).finally(() => this.contactsLoading.value = false)
        }
      }
    }
  }

  public sendGift(gift: GiftModel, dialog?: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    if (this.currentDialog.value || dialog) {
      return this._webSocketApi?.sendGift(gift, dialog || this.currentDialog.value!)
    } else {
      return undefined
    }
  }

  public afterGift(gift: GiftModel, dialog?: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    if (this.currentDialog.value || dialog) {
      return this._webSocketApi?.afterGift(gift, dialog || this.currentDialog.value!)
    } else {
      return undefined
    }
  }

  public sendGiftToVideoChat(dialog: DialogModel, gift: GiftModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.chatGift(dialog, gift)
  }

  public sendSticker(sticker: StickerModel) : void {
    if (this.currentDialog.value) {
      this._webSocketApi?.sendSticker(sticker, this.currentDialog.value!)
    }
  }

  public async clearDialogs(dialogs: DialogModel[]) : Promise<CommandPromiseArguments | undefined> {
    return this._webSocketApi?.clearDialogs(dialogs)
  }

  public clearDialog(dialog: DialogModel) : void {
    this._webSocketApi?.clearDialog(dialog)
  }

  public async readDialogs(dialogs: DialogModel[]) : Promise<CommandPromiseArguments | undefined> {
    return this._webSocketApi?.readDialogs(dialogs)
  }

  public addDialogToFavorites(dialog: DialogModel) : void {
    this._webSocketApi?.addDialogToFavorites(dialog)
  }

  public removeDialogToFavorites(dialog: DialogModel) : void {
    this._webSocketApi?.removeDialogFromFavorites(dialog)
  }

  public toggleFavoriteDialogStatus(dialog: DialogModel) : void {
    dialog.favorite ? this.removeDialogToFavorites(dialog) : this.addDialogToFavorites(dialog)
  }

  public contactRename(dialog: DialogModel, name: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.contactRename(dialog, name)
  }

  public abuse(dialog: DialogModel & StoryModel, type: number) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.abuse(dialog, type)
  }

  public blockUser(dialog: DialogModel, block: boolean) : void {
    if (dialog.newMessage > 0) {
      this._webSocketApi?.markDialogAsRead(dialog).then(() => {
        this._webSocketApi?.blockUser(dialog, block)
      })
    } else {
      this._webSocketApi?.blockUser(dialog, block)
    }
  }

  public removeUser(dialog: DialogModel) : void {
    this._webSocketApi?.removeUser(dialog)
  }

  public async getComment(dialog: DialogModel) : Promise<string> {
    if (this._webSocketApi) {
      const {response} = await this._webSocketApi.comment(dialog)
      return response ? response.data.comment : ''
    }
    return ''
  }

  public commentSave(dialog: DialogModel, text: string) : void {
    this._webSocketApi?.commentSave(dialog, text)
  }

  public write(typing: boolean, dialog: DialogModel) : void {
    this._webSocketApi?.write(dialog, typing)
  }

  public inviteAccept(dialog: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.inviteAccept(dialog)
  }

  public inviteDecline(dialog: DialogModel) : void {
    this._webSocketApi?.inviteDecline(dialog)
  }

  public inviteCancel(dialog: DialogModel) : void {
    this._webSocketApi?.inviteCancel(dialog)
  }

  public invite(dialog: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.invite(dialog)
  }

  public async requestDialogById(dialogId: number) : Promise<DialogModel | null> {
    const data = await this._webSocketApi?.getContact(dialogId)
    if (data) {
      const {response} = data
      if (response) {
        const data = {
          ID: dialogId,
          ...response.data[dialogId]
        }

        const dialog = new DialogModel()
        dialog.setData(data)
        return dialog
      } else {
        return null
      }
    } else {
      return null
    }
  }

  /**
   * @deprecated
   * @param dialog
   */
  public videoCall(dialog:DialogModel) : void {
    this._webSocketApi?.videoCall(dialog)
  }

  public videoCallCancel(dialog: DialogModel) : void {
    this._webSocketApi?.videoCallCancel(dialog)
  }

  public videoCallAccept(dialog: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.videoCallAccept(dialog)
  }

  public videoCallRequest(dialog: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.videoCallRequest(dialog)
  }

  public videoCallDenied(dialog: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.videoCallDenied(dialog)
  }

  public userOffer(description: RTCSessionDescriptionInit) : void {
    this._webSocketApi?.userOffer(description)
  }

  public userAnswer(description: RTCSessionDescriptionInit) : void {
    this._webSocketApi?.userAnswer(description)
  }

  public userIce(ice: RTCIceCandidate) : void {
    this._webSocketApi?.userIce(ice)
  }

  public stopChat() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.stopChat()
  }

  public sendVideoVote(dialog: DialogModel, score: number) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.sendVideoVote(dialog, score)
  }

  public sendVideoComment(dialog: DialogModel, type: number, text: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.sendVideoComment(dialog, type, text)
  }

  public searchSetting(anyContent : boolean, filter : number) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.searchSetting(anyContent, filter)
  }

  public searchFilterOnlyBest(enabled: boolean) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.searchFilterOnlyBest(enabled)
  }

  /**
   * @deprecated
   */
  public searchStart() : Promise<CommandPromiseArguments> | undefined {
    this.videoChatSearchQueue.value = null
    return this._webSocketApi?.searchStart()
  }

  public videoSearchRequest() : Promise<CommandPromiseArguments> | undefined {
    this.videoChatSearchQueue.value = null
    return this._webSocketApi?.videoSearchRequest()
  }

  public searchNext() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.searchNext()
  }

  public searchStop() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.searchStop()
  }

  public faceDetect(base64: string) : Promise<CommandPromiseArguments> | undefined {
    this._lastScreenshotSentTime = Date.now()

    return this._webSocketApi?.faceDetect(base64)
  }

  public historyDeleteUser(dialog: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.historyDeleteUser(dialog)
  }

  public routeChange(toScope: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.routeChange(toScope)
  }

  public historyClear() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.historyClear()
  }

  public history() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.history()
  }

  public markHistoryAsViewed(story: StoryModel) : void {
    this.dialogsList.modelsList.value
      .filter((dialogItem) => dialogItem.id === story.womanId)
      .forEach((dialogItem) => dialogItem.isStoryViewed = 1)

    this.dialogsHistory.modelsList.value.filter((dialogItem) => dialogItem.id === story.womanId)
      .forEach((dialogItem) => dialogItem.isStoryViewed = 1)
  }

  public getNextStory() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.getNextStory()
  }

  public getAuthorStory(womanId: number) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.getAuthorStory(womanId)
  }

  public updateStoryLike(story: StoryModel, status: boolean) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.updateStoryLike(story, status)
  }

  public storyAbuse(story: StoryModel, type: number) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.storyAbuse(story, type)
  }

  public paymentHistory() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.paymentHistory()
  }

  public changePersonal(email: string, ageRange: number) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.changePersonal(email, ageRange)
  }

  public changePassword(password: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.changePassword(password)
  }

  public accountDelete() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.accountDelete()
  }

  public accountDeleteConfirm(code: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.accountDeleteConfirm(code)
  }

  public accountDeleteReason(msg: string, code: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.accountDeleteReason(msg, code)
  }

  public cancelChangeEmail() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.cancelChangeEmail()
  }

  public changeNotification({
    NoticeUserMessage,
    NoticeSystemNew,
    NoticeGifts,
    NoticePartnerNews,
    NoticeUserInvite,
    NoticeBonusMinutesAddition
  } : {
    NoticeUserMessage?: number,
    NoticeSystemNew?: number,
    NoticeGifts?: number,
    NoticePartnerNews?: number,
    NoticeUserInvite?: number,
    NoticeBonusMinutesAddition?: number
  }) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.changeNotification({
      NoticeUserMessage,
      NoticeSystemNew,
      NoticeGifts,
      NoticePartnerNews,
      NoticeUserInvite,
      NoticeBonusMinutesAddition
    })
  }

  public webPushViewMsg(enabled: boolean) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.webPushViewMsg(enabled)
  }

  public webPushShow(enabled: boolean) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.webPushShow(enabled)
  }

  public changeTranslationSettings(enabled: boolean, lang: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.changeTranslationSettings(enabled, lang)
  }

  public checkout(billing: string, offer: number, card: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.checkout(billing, offer, card)
  }

  public autoRefillEnable(offer: number, card: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.autoRefillEnable(offer, card)
  }

  public autoRefillDisable() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.autoRefillDisable()
  }

  public userCardRemove(card: UserModelBillCard) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.userCardRemove(card)
  }

  public userCardConfirm(captureId: number, amount: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.userCardConfirm(captureId, amount)
  }

  public autoSubscriptionChangeCard(card: UserModelBillCard) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.autoSubscriptionChangeCard(card)
  }

  public autoSubscriptionDisable() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.autoSubscriptionDisable()
  }

  public subscribe(code: string, domain: string, webPushBonus: boolean) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.subscribe(code, domain, webPushBonus)
  }

  public unSubscribe(code: string, domain: string, webPushBonus: boolean) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.unSubscribe(code, domain, webPushBonus)
  }

  public webPushAfterBlock(enabled: boolean) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.webPushAfterBlock(enabled)
  }

  public mailConfirm(email: string, source: string = 'A') : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.mailConfirm(email, source)
  }

  public confirmCode(email: string, code: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.confirmCode(email, code)
  }

  public changeLang(lang: string) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.changeLang(lang)
  }

  public loadedData() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.loadedData()
  }

  public viewSettings() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.viewSettings()
  }

  public supportAlert(dialog: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.supportAlert(dialog)
  }

  public moderationGood(dialog?: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.moderationGood(dialog)
  }

  public moderationBad(dialog?: DialogModel) : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.moderationBad(dialog)
  }

  public fingerPrint(token: string) : void {
    this._webSocketApi?.fingerPrint(token)
  }

  public updateOffers() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.updateOffers()
  }

  public cancelWaitRestore() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.cancelWaitRestore()
  }

  public cancelRestore() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.cancelRestore()
  }

  public restoreChat() : Promise<CommandPromiseArguments> | undefined {
    return this._webSocketApi?.restoreChat()
  }

  /****/

  public testSignal(signal: any, scopeData: any) {
    this._webSocketApi?.testSignal(signal, scopeData)
  }

  public dispose() : void {
    CoomeetChat._instance = undefined

    if (this._webSocketApi) {
      this._webSocketApi.removeEventListener(WebSocketApiEvents.Closed, this._onSocketApiClosed)
      this._webSocketApi = null
    }
  }

}

export default CoomeetChat.GetInstance()
