<template lang="pug">
.gifts-list
  teleport(to="body")
    .messages-list-header-gifts-list(:class="{visible}")
      .gift-item(v-for="(giftItem, index) in giftsList", @click="onGiftItemClick(giftItem)")
        .gift-item__image(:style="{backgroundImage: `url('${giftItem.image}')`}")
        .gift-item__title {{t(giftItem.title)}}
        .gift-item__cost {{t('C_GIFT_PRICE')}}
        .gift-item__cost-value {{t('APP_PL_MINUTE_', {n: Math.floor(giftItem.minutes / 60)})}}
</template>
<script lang="ts" setup>
import {onMounted, onUnmounted, ref} from 'vue'
import GiftModel, {GiftsListModels} from '../../../../../../common/classes/CoomeetChat/models/GiftModel.class'
import CoomeetChatInstance from '../../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import {Popups, usePopupOverlayStore} from '../../../../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'
import ScopePaymentCommands
  from "../../../../../../common/classes/CoomeetChat/WebSocketApi/scopes/ScopePayment/commands";

const {t} = useI18n()
const popupsStore = usePopupOverlayStore()

const user = CoomeetChatInstance.user
const visible = ref(false)
const giftsList = ref<GiftModel[]>(GiftsListModels)

const show = () => {
  visible.value = true
}

const toggleVisibility = () => {
  visible.value = !visible.value
}

defineExpose({
  show,
  toggleVisibility
})

const onDocumentClick = (e:MouseEvent) => {
  if (e.target && !(e.target as HTMLElement).closest('.messages-list-header-gifts-list') &&
      !(e.target as HTMLElement).closest('.messages-header-gift-button')) {
    visible.value = false;
  }
}

const onGiftItemClick = (giftModel: GiftModel) => {
  if ((user.value.score / 60 + 1) >= giftModel.minutes / 60) {
    CoomeetChatInstance.sendGift(giftModel)
  } else {
    if (user.value.access == 0) {
      popupsStore.showPopup(Popups.BuyPremium)
    } else {
      CoomeetChatInstance.currentDialog.value!.notSentGift = giftModel
      popupsStore.showPopup(Popups.BuyMinutes)
    }
  }
  visible.value = false
}

onMounted(() => {
  document.addEventListener('click', onDocumentClick)
})

onUnmounted(() => {
  document.removeEventListener('click', onDocumentClick)
})

</script>
