<template lang="pug">
.invite-request
  .invite-request-form
    .invite-request-form__date {{lastMessageTime}}
    .invite-request-form__avatar
      UiUserAvatar(:model="dialog",
        :size="192",
        :showStory="showStory",
        :showPhotoByClick="true")
      .gift-image(v-if="gift")
        img(:src="gift.image")
    .invite-request-form__username {{dialog.username}}

    template(v-if="dialog.delete")
      .invite-request-form__label.canceled {{t('APP_REJECTED_FEMALE_FRIEND_REQUEST')}}
      .invite-request-form__actions
        UiSimpleButton.color-white-2.size-46(@click="onDeleteClick") {{t('C_FRIEND_DELETE')}}
    template(v-else)
      .invite-request-form__label {{t('C_FRIEND_INVITE_SENDED')}}
      .invite-request-form__actions
        UiSimpleButton.color-blue.size-46.gift(@click="onSendGiftClick", :class="{disabled: giftSended}")
          .icon
            GiftIcon
          .label {{giftSended ? t('APP_GIFT_SENDED') : t('APP_DRAW_ATTENTION')}}
        UiSimpleButton.color-white-2.size-46(@click="onCancelClick") {{t('C_FRIEND_INVITE_CANCEL')}}

    GiftsList(ref="giftsList", @gift:send="onGiftSend")
  .accept-invite__top-icon(@click="onComplainClick")
    LampIcon
</template>
<script lang="ts" setup>
import {computed, ref, watchEffect} from 'vue'
import UiUserAvatar from '../../../../../components/ui/UiUserAvatar.vue'
import UiSimpleButton from '../../../../../components/ui/Buttons/UiSimpleButton.vue'
import LampIcon from '../../../../../assets/images/icons/lamp-size-28.svg'
import GiftIcon from '../../../../../assets/images/icons/gift-size-22.svg'
import CoomeetChatInstance, {VideoDialogState} from '../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import DialogModel from '../../../../../common/classes/CoomeetChat/models/DialogModel.class'
import GiftsList from './InviteRequest/GiftsList.vue'
import moment from 'moment'
import {useRouter} from 'vue-router'
import {Popups, usePopupOverlayStore} from '../../../../../stores/popupOverlay'
import {useI18n} from 'vue-i18n'
import GiftModel from '../../../../../common/classes/CoomeetChat/models/GiftModel.class'

const popupsStore = usePopupOverlayStore()
const router = useRouter()

const {t, d} = useI18n()

const dialog = CoomeetChatInstance.currentDialog
const user = CoomeetChatInstance.user
const giftsList = ref<null | InstanceType<typeof GiftsList>>(null)
const showStory = computed(() => CoomeetChatInstance.videoDialogState.value === VideoDialogState.Empty)
const gift = computed(() => dialog.value!.giftBeforeApprove ? GiftModel.CreateGiftById(dialog.value!.giftBeforeApprove) : null)
const giftSended = computed(() => !!gift.value)

const onDeleteClick = () => {
  if (user.value.access === 0) {
    popupsStore.showPopup(Popups.BuyPremium)
  } else {
    CoomeetChatInstance.inviteCancel(dialog.value as DialogModel)
    
    router.replace('/dialog').then(() => {
      CoomeetChatInstance.setCurrentDialog(null)
    })
  }
}

const onCancelClick = () => {
  if (user.value.access === 0) {
    popupsStore.showPopup(Popups.BuyPremium)
  } else {
    CoomeetChatInstance.inviteCancel(dialog.value as DialogModel)
    
    router.replace('/dialog').then(() => {
      CoomeetChatInstance.setCurrentDialog(null)
    })
  }
}

const onSendGiftClick = () => {
  const {toggleVisibility} = giftsList.value!
  toggleVisibility()
}

const onGiftSend = (gift: GiftModel) => {
  dialog.value!.giftBeforeApprove = gift.id
}

const onComplainClick = () => {
  popupsStore.showPopup(Popups.Complain, dialog.value)
}

const lastMessageTime = computed(() => {
  if (dialog.value && dialog.value!.lastMessage) {
    const nowDate = d(new Date(), 'date-with-weekday')
    const dialogDate = d(new Date(dialog.value!.lastMessage.created * 1000), 'date-with-weekday')
    const nowYear = new Date().getFullYear()
    const dialogYear = new Date(dialog.value!.lastMessage.created * 1000).getFullYear()

    if (nowDate === dialogDate) {
      return t('S_TODAY')
    } else if (nowYear === dialogYear) {
      return d(new Date(dialog.value!.lastMessage.created * 1000), 'date-with-weekday-without-year')
    } else {
      return d(new Date(dialog.value!.lastMessage.created * 1000), 'date-with-weekday')
    }
  }

  return ''
})

</script>
