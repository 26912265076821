import exp from "constants";
import browserStorage from './CoomeetChat/BrowserStorage.class'

export enum IFrameCommand {
  Favicon = 'Favicon',
  ParseUrl = 'ParseUrl',
  Origin = 'Origin',
  Ready = 'Ready',
  ClipboardWrite = 'ClipboardWrite',
  StorageData = 'StorageData',
  InputFocused = 'InputFocused',
  InputBlured = 'InputBlured',
  VideoChatInputFocused = 'VideoChatInputFocused',
  VideoChatInputBlured = 'VideoChatInputBlured',
  SwitchApp = 'SwitchApp',
  LocaleChange = 'LocaleChange',
  OpenOldVersion = 'OpenOldVersion',
  ReloadPage = 'ReloadPage'
}

export const MessengerEvent = {
  ParseUrl: 'ParseUrl',
  StorageData: 'StorageData',
  LocaleChange: 'LocaleChange'
}

export interface IframeMessage {
  cmd: IFrameCommand,
  data: any
}

export class IframeMessengerEvent extends Event {
  constructor(type : string, public data?: any ) {
    super(type)
  }
}

class IframeMessenger extends EventTarget {
  private _parentWindow: Window | null = null
  private _origin: string = ''

  public get active () : boolean {
    return !!this._parentWindow
  }

  public get parentWindowOrigin() : string {
    return this._origin
  }

  public get isRusvideodatingOrigin() : boolean {
    return this._origin.indexOf('rusvideodating.ru') >= 0
  }

  constructor() {
    super()
    if (window.parent !== window) {
      const params = new URLSearchParams(window.location.search)
      this._parentWindow = window.parent

      window.addEventListener('message', this._onParentWindowMessage)
    }
  }


  public sendMessage(message: IframeMessage) : void {
    if (this._parentWindow) {
      this._parentWindow.postMessage(message, '*')
    }
  }

  public sendReady() : void {
    this.sendMessage({
      cmd: IFrameCommand.Ready,
      data: {
        storageKeys: browserStorage.getStorageKeys()
      }
    })
  }

  private _onParentWindowMessage = (e: MessageEvent) : void => {
    const message = e.data as IframeMessage

    switch (message.cmd) {
      case IFrameCommand.Origin:
        this._origin = message.data as string
        break
      case IFrameCommand.ParseUrl:
          this.dispatchEvent(new IframeMessengerEvent(MessengerEvent.ParseUrl, message.data))
        break
      case IFrameCommand.StorageData:
        this.dispatchEvent(new IframeMessengerEvent(MessengerEvent.StorageData, message.data))
        break

      case IFrameCommand.LocaleChange:
        this.dispatchEvent(new IframeMessengerEvent(MessengerEvent.LocaleChange, message.data))
        break
    }
  }
}

const iframeMessenger = new IframeMessenger()

export default iframeMessenger
