<template lang="pug">
.gifts-list
  .invite-request-gifts-list(:class="{visible}", ref="giftsTeleportEl")
    .gift-item(v-for="(giftItem, index) in giftsList", @click="onGiftItemClick(giftItem)")
      .gift-item__image(:style="{backgroundImage: `url('${giftItem.image}')`}")
      .gift-item__title {{t(giftItem.title)}}
      .gift-item__cost {{t('APP_PRICE')}}
      .gift-item__cost-value {{Math.floor(giftItem.minutes / 60)}} {{t('APP_MINUTES_SHORT')}}
</template>
<script lang="ts" setup>
import {ref, watchEffect, nextTick, onMounted, onUnmounted} from 'vue'
import GiftItem from '../../../MessagesList/MessageForm/MessageInput/StickersAndGifts/StickersAndGiftsContent/GiftItem.vue'
import GiftModel, {GiftsListModels} from '../../../../../../common/classes/CoomeetChat/models/GiftModel.class'
import CoomeetChatInstance from '../../../../../../common/classes/CoomeetChat/CoomeetChat.class'
import ScopePaymentCommands
  from '../../../../../../common/classes/CoomeetChat/WebSocketApi/scopes/ScopePayment/commands'
import {Popups, usePopupOverlayStore} from '../../../../../../stores/popupOverlay'
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const popupsStore = usePopupOverlayStore()
const visible = ref(false)
const giftsTeleportEl = ref<null | HTMLDivElement>(null)
const giftsList = ref<GiftModel[]>(GiftsListModels)
const emit = defineEmits(['gift:send'])
const user = CoomeetChatInstance.user

const show = () => {
  visible.value = true
}

const toggleVisibility = () => {
  visible.value = !visible.value
}

defineExpose({
  show,
  toggleVisibility
})

const onDocumentClick = (e:MouseEvent) => {
  if (e.target && !(e.target as HTMLElement).closest('.invite-request-gifts-list') &&
      !(e.target as HTMLElement).closest('.invite-request-form__actions .ui-simple-button.gift')) {
    visible.value = false
  }
}

const onGiftItemClick = (giftModel: GiftModel) => {
  if (user.value.access === 0) {
    popupsStore.showPopup(Popups.BuyPremium)
  } else {
    CoomeetChatInstance.sendGift(giftModel)?.then(({response}) => {
      if (response?.event.Cmd === ScopePaymentCommands.LowMinute) {
        CoomeetChatInstance.currentDialog.value!.notSentGift = giftModel
        popupsStore.showPopup(Popups.BuyMinutes)
      } else {
        emit('gift:send', giftModel)
      }
    })
  }

  visible.value = false
}

onMounted(() => {
  document.addEventListener('click', onDocumentClick)
})

onUnmounted(() => {
  document.removeEventListener('click', onDocumentClick)
})

</script>
